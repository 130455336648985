import React, { useContext } from 'react';
import { Text } from '../../../../components/content_display/text';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { Icon } from '../../../../components/buttons/icon';
import { faArrowLeft, faBan, faCheckCircle, faEye, faPaperPlane, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../../components/buttons/button';
import { Select } from '../../../../components/inputs_and_selections/select';
import { AdminContext } from '../../admin_layout';
import { SendEstimateModalBlock } from './send_estimate_modal_block';
import { SendDeclinedModalBlock } from './send_declined_modal_block';
import { estimateAndLeadToCalculatedEstimate } from '../../../../code/models/calculated_estimate';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
export const EnquiryManagerHeader = ({ lead, setLead, setEstimates, estimate, leadSaved, basePath }) => {
    var _a, _b;
    const navigate = useNavigate();
    const adminContext = useContext(AdminContext);
    const companyPublicInfo = useRouteLoaderData('company_root');
    const [isEstimateModalVisible, setIsEstimateModalVisible] = React.useState(false);
    const [declineModalVisible, setDeclineModalVisible] = React.useState(false);
    const STATUS_ITEMS = [
        { key: 'New', value: 'Estimate Required', colour: 'YELLOW' },
        { key: 'Quoted', value: 'Estimate Sent', colour: 'BLUE' },
        { key: 'Converted', value: 'Estimate Accepted', colour: 'GREEN' },
        { key: 'Rejected', value: 'Estimate Rejected', colour: 'RED' },
        { key: 'Declined', value: 'Enquiry Declined', colour: 'RED' },
        { key: 'SurveyBooked', value: 'Survey Booked', colour: 'BLUE' },
        { key: 'Archived', value: 'Archived', colour: 'LIGHT' }
    ];
    const statusColour = (_a = STATUS_ITEMS.find(x => x.key === lead.status)) === null || _a === void 0 ? void 0 : _a.colour;
    const calculatedEstimate = estimateAndLeadToCalculatedEstimate(estimate, lead);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex md:items-center flex-col md:flex-row gap-4 px-6 pt-3" },
            React.createElement("div", { className: 'flex items-center gap-4' },
                React.createElement(Icon, { className: 'w-6 h-6', onClick: () => navigate(`/${companyPublicInfo.subdomain}/admin/enquiries`), icon: faArrowLeft }),
                React.createElement("div", null,
                    React.createElement("div", { className: 'flex items-center gap-2' },
                        React.createElement("h1", { className: "text-2xl font-semibold text-bold" }, (_b = lead.customer) === null || _b === void 0 ? void 0 : _b.name),
                        leadSaved ? React.createElement(Badge, { color: 'GREEN', text: 'Saved', icon: faCheckCircle }) : React.createElement(Badge, { color: "YELLOW", icon: faRefresh, text: "Saving..." })),
                    React.createElement(Text, { className: 'tracking-tight' },
                        lead.property.address,
                        ", ",
                        lead.property.postcode))),
            React.createElement("div", { className: 'grid grid-cols-2 md:flex md:ml-auto gap-4' },
                React.createElement(Button, { colour: "LIGHT", iconLeft: faEye, onClick: () => navigate(`${basePath}/preview`) }, "Preview"),
                React.createElement(Button, { colour: "LIGHT", iconLeft: faBan, onClick: () => setDeclineModalVisible(true) }, "Decline"),
                React.createElement(Button, { colour: "DARK", iconLeft: faPaperPlane, onClick: () => setIsEstimateModalVisible(true), disabled: !estimate.heatPump }, "Send"),
                React.createElement(Select, { colour: statusColour, options: STATUS_ITEMS, selectedKey: lead.status, setSelectedKey: async (e) => {
                        setLead({ ...lead, status: e });
                    } }))),
        React.createElement(SendEstimateModalBlock, { setEstimates: setEstimates, adminContext: adminContext, isVisible: isEstimateModalVisible, setIsVisible: setIsEstimateModalVisible, calculatedEstimate: calculatedEstimate, lead: lead, setLead: setLead }),
        React.createElement(SendDeclinedModalBlock, { adminContext: adminContext, isVisible: declineModalVisible, setIsVisible: setDeclineModalVisible, lead: lead, setLead: setLead })));
};
