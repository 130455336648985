import { faCamera, faPlus, faTimes, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { fileToBase64 } from '../../code/helpers';
import { Modal } from '../containers/modal';
import { noop } from 'lodash';
import { Icon } from '../buttons/icon';
export const MAX_IMAGE_WIDTH = 600;
export const MAX_IMAGE_HEIGHT = 600;
export const PhotoScroll = ({ images, deleteImage, addImage, doNotScale, companyUUID, viewMode }) => {
    const [currentImageId, setCurrentImageId] = useState(undefined);
    // sometimes we got inconsistent data, so we need to filter out undefined images
    const cleanedImages = images.filter(x => x !== undefined);
    const currentImage = cleanedImages.find(x => x.uuid === currentImageId);
    const randomUUID = crypto.randomUUID();
    const handleFileSelect = addImage ? async (e) => {
        var _a, _b, _c, _d;
        // if doNotScale is true, we don't need to scale the image
        if (doNotScale) {
            const newFile = (_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
            const base64 = await fileToBase64(newFile);
            const newFileWrapper = newFile && { uuid: crypto.randomUUID(), file_base64: base64, created_at: new Date().getTime(), updated_at: new Date().getTime(), server_updated_at: 0, deleted_at: undefined, company_uuid: companyUUID };
            newFileWrapper && addImage(newFileWrapper);
            return;
        }
        // otherwise, we need to scale the image
        const selectedFile = (_d = (_c = e.target) === null || _c === void 0 ? void 0 : _c.files) === null || _d === void 0 ? void 0 : _d[0];
        if (!selectedFile)
            return;
        // change image size
        const image = new Image();
        image.src = URL.createObjectURL(selectedFile);
        // Ensure image is loaded before drawing
        image.onload = () => {
            const canvas = document.createElement('canvas');
            let width = image.width;
            let height = image.height;
            // Maintain aspect ratio and scale if necessary
            if (width > MAX_IMAGE_WIDTH || height > MAX_IMAGE_HEIGHT) {
                const aspectRatio = width / height;
                if (width > height) {
                    width = MAX_IMAGE_WIDTH;
                    height = MAX_IMAGE_WIDTH / aspectRatio;
                }
                else {
                    height = MAX_IMAGE_HEIGHT;
                    width = MAX_IMAGE_HEIGHT * aspectRatio;
                }
            }
            canvas.width = width;
            canvas.height = height;
            const context = canvas.getContext('2d');
            if (!context)
                return;
            context.drawImage(image, 0, 0, width, height);
            const base64 = canvas.toDataURL();
            const newFileWrapper = {
                uuid: crypto.randomUUID(),
                file_base64: base64,
                file_url: undefined,
                created_at: new Date().getTime(),
                updated_at: new Date().getTime(),
                server_updated_at: 0,
                deleted_at: undefined,
                company_uuid: companyUUID
            };
            addImage(newFileWrapper);
        };
    } : noop;
    const isCompactMode = viewMode === 'COMPACT' && cleanedImages.length === 0;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex w-full overflow-auto gap-2' },
            addImage && React.createElement(React.Fragment, null,
                React.createElement("label", { htmlFor: `dropzone-file-${randomUUID}` },
                    isCompactMode &&
                        React.createElement("div", { className: "px-4 py-3 rounded-md border border-gray-300 flex gap-2 items-center cursor-pointer" },
                            React.createElement(FontAwesomeIcon, { className: 'text-sm', icon: faPlus }),
                            React.createElement("div", { className: "text-sm font-bold" }, "Add photos")),
                    !isCompactMode &&
                        React.createElement("div", { className: 'flex flex-col gap-2 w-32 h-32 bg-gray-100 items-center justify-center cursor-pointer text-gray-600 rounded-lg' },
                            React.createElement(FontAwesomeIcon, { className: 'text-lg', icon: faCamera }),
                            React.createElement("div", null, "Add photo"))),
                React.createElement("input", { id: `dropzone-file-${randomUUID}`, className: 'hidden', type: "file", accept: 'image/*', onChange: handleFileSelect })),
            cleanedImages.reverse().map(x => React.createElement(ImageWithFallback, { key: x.uuid, fileWrapper: x, deleteImage: deleteImage, setCurrentImageId: setCurrentImageId }))),
        currentImage &&
            React.createElement(Modal, { visible: true, setVisible: () => setCurrentImageId(undefined), title: '' },
                React.createElement("img", { className: 'rounded-lg w-full', src: currentImage.file_base64 || `${process.env.S3_BUCKET_URL}/${currentImage.file_url}` })));
};
const ImageWithFallback = ({ deleteImage, fileWrapper, setCurrentImageId }) => {
    const [fallback, setFallback] = useState(false);
    return React.createElement("div", { key: fileWrapper.uuid, className: 'relative flex-shrink-0' },
        fallback && React.createElement("div", { className: 'rounded-lg h-32 flex flex-col gap-1 items-center justify-center bg-gray-200 p-4' },
            React.createElement(Icon, { icon: faWifi, className: 'mb-2' }),
            React.createElement("div", { className: 'text-sm font-bold text-gray-900' }, "Image not available"),
            React.createElement("div", { className: 'text-sm text-gray-600' },
                "Images will reappear ",
                React.createElement("br", null),
                "when you are online")),
        deleteImage && !fallback && React.createElement(FontAwesomeIcon, { onClick: () => deleteImage(fileWrapper.uuid), className: 'text-gray-600 cursor-pointer absolute top-0 right-0 z-10 p-2', icon: faTimes }),
        !fallback && React.createElement("img", { className: 'rounded-lg h-32 cursor-pointer', onError: () => setFallback(true), onClick: () => setCurrentImageId(fileWrapper.uuid), src: fileWrapper.file_base64 || `${process.env.S3_BUCKET_URL}/${fileWrapper.file_url}` }));
};
export const resizeBase64Image = async (base64Str, maxWidth = MAX_IMAGE_WIDTH, maxHeight = MAX_IMAGE_HEIGHT) => {
    return await new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
            let { width, height } = image;
            // Calculate new dimensions while maintaining aspect ratio
            if (width > maxWidth || height > maxHeight) {
                const aspectRatio = width / height;
                if (width > height) {
                    width = maxWidth;
                    height = maxWidth / aspectRatio;
                }
                else {
                    height = maxHeight;
                    width = maxHeight * aspectRatio;
                }
            }
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);
            const resizedBase64 = canvas.toDataURL('image/png');
            resolve(resizedBase64);
        };
        image.onerror = (error) => reject(error);
        image.src = base64Str;
    });
};
