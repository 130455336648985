import React from 'react';
import { ClickableCard } from '../../../components/content_display/card';
import { sum } from 'lodash';
import { ProgressChart } from '../../../components/indicators_and_messaging/progress_chart';
import { TableLite } from '../../../components/content_display/table_lite';
import { getConductionHeatLossAllElements, getVentilationHeatLoss, getFloorAreaM2, getRoomTemp, combineSortConductionHeatLosses, combineHeatLossesForProgressChart, getRoomWatts } from '../../../code/models/heat_loss';
import { getEmitterOutputRow } from '../../../code/models/radiator';
import { FlowTempSlider } from '../design/pages/emitter_design_page';
import { getEmitterOutputVsDemandText } from './code/utils';
const conductionHeatLossColumns = [
    { key: 'elementName', name: '' },
    { key: 'otherSideTempC', name: 'Other side temp', render: (row) => React.createElement("div", null,
            row.otherSideTempC,
            " \u00B0C") },
    { key: 'uValueWPerM2K', name: 'U-value (W/m²K)', render: (row) => React.createElement("div", null, row.uValueWPerM2K.toFixed(2)) },
    { key: 'areaM2', name: 'Area', render: (row) => React.createElement("div", null,
            row.areaM2.toFixed(1),
            " m\u00B2") },
    { key: 'watts', name: 'Heat loss', render: (row) => React.createElement("div", null,
            row.watts.toFixed(0),
            " W") }
];
const ventilationHeatLossColumns = [
    { key: 'elementName', name: '' },
    { key: 'heatRecoveryPercentage', name: 'Heat recovery', render: (row) => React.createElement("div", null,
            row.heatRecoveryPercentage * 100,
            " %") },
    { key: 'ACH', name: 'ACH', render: (row) => React.createElement("div", null, row.ACH) },
    { key: 'volumeM3', name: 'Volume', render: (row) => React.createElement("div", null,
            row.volumeM3.toFixed(1),
            " m\u00B3") },
    { key: 'watts', name: 'Heat loss', render: (row) => React.createElement("div", null,
            row.watts.toFixed(0),
            " W") }
];
const emitterColumns = [
    { key: 'typeName', name: '' },
    { key: 'size', name: 'Size', render: (row) => React.createElement("div", null, row.size) },
    { key: 'velocityMPerS', name: 'Velocity', render: (row) => React.createElement("div", null,
            row.velocityMPerS ? row.velocityMPerS.toFixed(2) + ' m/s' : '',
            " ") },
    { key: 'watts', name: 'Output', render: (row) => React.createElement("div", null,
            row.watts,
            " W") }
];
export const RoomHeatLossPage = ({ room, roomsThisFloor, designTempC, groundTempC, flowTemp, setFlowTemp, minFlowTemp, maxFlowTemp, deltaTFlowReturnC, stepSize, survey, design }) => {
    const otherRoomsInGroup = roomsThisFloor.filter(x => x.room_group_uuid !== undefined && x.room_group_uuid === room.room_group_uuid && x.uuid !== room.uuid);
    const roomTempC = getRoomTemp(room, survey);
    const conductionHeatLossRows = getConductionHeatLossAllElements(room, roomsThisFloor, designTempC, groundTempC, stepSize, survey);
    const ventilationHeatLoss = getVentilationHeatLoss(room, designTempC, stepSize, survey);
    const heatLossTotalW = sum(conductionHeatLossRows.map(x => x.watts)) + ventilationHeatLoss.watts;
    const heatLossOtherRoomsW = sum(otherRoomsInGroup.map(x => getRoomWatts(x, roomsThisFloor, designTempC, groundTempC, stepSize, survey)));
    const heatLossTotalRoomGroupW = heatLossTotalW + heatLossOtherRoomsW;
    const heatLossTotalWRounded = Math.round(heatLossTotalW);
    const floorAreaM2 = getFloorAreaM2(room.walls, stepSize);
    const wattsPerM2 = (heatLossTotalW / floorAreaM2).toFixed(0);
    const heatLossForChart = combineHeatLossesForProgressChart(conductionHeatLossRows, [ventilationHeatLoss]);
    const conductionHeatLossRowsCombinedForTable = combineSortConductionHeatLosses(conductionHeatLossRows);
    const emitterRowsThisRoom = room.radiators
        .map(x => getEmitterOutputRow(x, room, survey, design, designTempC, groundTempC));
    const emitterRowsOtherRooms = otherRoomsInGroup.flatMap(r => r.radiators
        .map(x => getEmitterOutputRow(x, room, survey, design, designTempC, groundTempC)));
    const emitterRows = [...emitterRowsThisRoom, ...emitterRowsOtherRooms];
    const emitterTotalW = sum(emitterRows.map(x => x.watts));
    return React.createElement("div", { className: "p-5 bg-white flex-col gap-6 flex flex-grow overflow-y-auto" },
        React.createElement("div", { className: "flex-col gap-4 flex" },
            React.createElement("div", { className: "gap-2 flex" },
                React.createElement("div", { className: 'gap-1 rounded-md flex flex-col bg-gray-50 flex-grow p-2' },
                    React.createElement("div", { className: "text-gray-900 text-xs font-bold" }, "Room temp"),
                    React.createElement("div", { className: "text-gray-600 text-base" },
                        roomTempC,
                        " \u00B0C")),
                React.createElement("div", { className: 'gap-1 rounded-md flex flex-col bg-gray-50 flex-grow p-2' },
                    React.createElement("div", { className: "text-gray-900 text-xs font-bold" }, "W/m\u00B2"),
                    React.createElement("div", { className: "text-gray-600 text-base" }, wattsPerM2)),
                React.createElement("div", { className: 'gap-1 rounded-md flex flex-col bg-gray-50  flex-grow p-2' },
                    React.createElement("div", { className: "text-gray-900 text-xs font-bold" }, "ACH"),
                    React.createElement("div", { className: "text-gray-600 text-base" }, ventilationHeatLoss.ACH))),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement("div", { className: 'flex flex-col gap-2' },
                    React.createElement("div", { className: 'flex justify-between text-lg' },
                        React.createElement("div", { className: 'text-gray-900 font-bold' }, "Heat loss"),
                        React.createElement("div", null,
                            heatLossTotalWRounded,
                            " W")),
                    React.createElement(ProgressChart, { total: heatLossTotalW, items: heatLossForChart }))),
            React.createElement("div", { className: "gap-4 flex flex-col" },
                React.createElement(TableLite, { columns: conductionHeatLossColumns, rows: conductionHeatLossRowsCombinedForTable }),
                React.createElement(TableLite, { columns: ventilationHeatLossColumns, rows: [ventilationHeatLoss] }),
                React.createElement("div", { className: 'flex justify-between text-gray-900 font-bold px-2' },
                    React.createElement("div", null, "Total"),
                    React.createElement("div", null,
                        heatLossTotalWRounded,
                        " W")),
                survey.intermittent_heating && React.createElement("div", { className: 'text-xs text-gray-500 italic font-light' }, `* Intermittent heating correction factor of ${survey.intermittent_heating_oversize_factor_percentage}% applied`))),
        emitterRows.length > 0 && React.createElement("div", { className: 'flex flex-col gap-1' },
            React.createElement("div", { className: 'text-gray-900 font-bold text-lg' }, "Heat output"),
            otherRoomsInGroup.length > 0 && React.createElement("div", { className: 'text-sm' },
                "This room is sharing its emitters with ",
                otherRoomsInGroup.map(x => x.name).join(', '))),
        (emitterRows.length > 0) && React.createElement("div", { className: 'flex-col gap-2 flex' },
            React.createElement(ClickableCard, { border: false, variant: 'GREY' },
                React.createElement("div", { className: 'flex flex-col gap-4 justify-between flex-grow' },
                    React.createElement(FlowTempSlider, { flowTemp: design.flow_temp, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp })))),
        (emitterRows.length > 0) && React.createElement("div", { className: "flex-col gap-4 flex" },
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement("div", { className: 'flex flex-col gap-2' },
                    React.createElement("div", { className: 'flex justify-between text-lg' },
                        React.createElement("div", { className: 'text-gray-900 font-bold' }, "Emitters"),
                        React.createElement("div", null, getEmitterOutputVsDemandText(emitterTotalW, heatLossTotalRoomGroupW))),
                    React.createElement(ProgressChart, { total: heatLossTotalRoomGroupW, items: emitterRows.map(x => ({ value: x.watts, name: x.typeName })), colorVariant: 'EMITTER' }))),
            React.createElement(TableLite, { columns: emitterColumns, rows: emitterRows }),
            React.createElement("div", { className: 'flex flex-col gap-1' },
                React.createElement("div", { className: 'flex justify-between text-gray-900 font-bold px-2' },
                    React.createElement("div", null, "Total"),
                    React.createElement("div", null,
                        sum(emitterRows.map(x => x.watts)),
                        " W")))));
};
