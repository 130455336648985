import React, { useContext, useState } from 'react';
import { logEvent } from '../../../code/log_event';
import { updateCompany } from '../../../code/models/company';
import { deleteHeatPump, insertHeatPump, updateHeatPump } from '../../../code/models/heat_pump';
import { deleteHotWaterCylinders, insertHotWaterCylinders, updateHotWaterCylinders } from '../../../code/models/hot_water_cylinder';
import { Table } from '../../../components/content_display/table';
import { AdminContext } from '../admin_layout';
import { deleteCompanyLineItem, insertCompanyLineItem, updateCompanyLineItem } from '../../../code/models/company_line_item';
import { TabGroup } from '../../../components/content_display/tab';
import { HeatPumpTab } from './heat_pump_tab';
import { Bars3Icon } from '@heroicons/react/16/solid';
import { OfflinePage } from '../offline_page';
import { validateIsNumber } from '../../../code/validators';
import { useRouteLoaderData } from 'react-router-dom';
import { USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SIMPLE } from '../../../code/models/user';
import { RequireRole } from '../../../require_role';
export const CostsPage = () => {
    var _a, _b, _c, _d, _e, _f;
    const adminContext = useContext(AdminContext);
    const companyPublicInfo = useRouteLoaderData('company_root');
    const [tab, setTab] = useState('HEAT_PUMPS');
    const brandRanges = adminContext.data.brandRanges ? adminContext.data.brandRanges.filter(x => !x.deleted_at) : [];
    if (adminContext.isLoading || !adminContext.data.company)
        return;
    const companyUUID = adminContext.data.company.public_info.uuid;
    const companySubdomain = adminContext.data.company.public_info.subdomain;
    const hotWaterColumns = [
        { key: 'name', name: 'Name' },
        { key: 'price', name: 'Price (£)', validator: (row) => !validateIsNumber(row.price).message },
        { key: 'litres', name: 'Capacity (l)', validator: (row) => !validateIsNumber(row.litres).message }
    ];
    const labourColumns = [
        { key: 'name', name: 'Name' },
        { key: 'price', name: 'Value', validator: (row) => !validateIsNumber(row.price).message }
    ];
    const surveyColumns = [
        { key: 'price', name: 'Price (£ ex. VAT)', validator: (row) => !validateIsNumber(row.price).message }
    ];
    const updateOrInsertHeatPumpRow = async (heatPump, updateLocal = true) => {
        heatPump.price = parseFloat(heatPump.price.toString().replace(',', ''));
        heatPump.parts = parseFloat(heatPump.parts.toString().replace(',', ''));
        heatPump.kwatts = parseFloat(heatPump.kwatts.toString().replace(',', ''));
        if (adminContext.data.heatPumps.some(x => x.uuid === heatPump.uuid)) {
            logEvent({ name: 'Heat Pump Modified', properties: {} }, companySubdomain);
            await updateHeatPump(heatPump, companyUUID);
            if (updateLocal)
                adminContext.setHeatPumps(adminContext.data.heatPumps.map(x => x.uuid === heatPump.uuid ? heatPump : x));
        }
        else {
            logEvent({ name: 'Heat Pump Added', properties: {} }, companySubdomain);
            await insertHeatPump(heatPump, companyUUID);
            if (updateLocal)
                adminContext.setHeatPumps([...adminContext.data.heatPumps, heatPump]);
        }
    };
    const addHeatPumps = async (heatPumps) => {
        await Promise.all(heatPumps.map(async (x) => await updateOrInsertHeatPumpRow(x, false)));
        const newHeatPumps = heatPumps.filter(x => { var _a; return !((_a = adminContext.data.heatPumps) === null || _a === void 0 ? void 0 : _a.some(y => y.uuid === x.uuid)); });
        const updatedHeatPumps = adminContext.data.heatPumps.map(x => x);
        adminContext.setHeatPumps([...updatedHeatPumps.map(x => {
                const heatPump = heatPumps.find(y => y.uuid === x.uuid);
                return heatPump || x;
            }), ...newHeatPumps]);
    };
    const deleteHeatPumpRow = async (heatPumpUUID) => {
        logEvent({ name: 'Heat Pump Deleted', properties: {} }, companySubdomain);
        await deleteHeatPump(heatPumpUUID, companyUUID);
        adminContext.setHeatPumps(adminContext.data.heatPumps.map(x => {
            // artificially delete the HP in the state variable by setting deleted_at
            // in reality the deleted_at is set by the server, but we don't need a precise time here
            return x.uuid === heatPumpUUID ? { ...x, deleted_at: new Date() } : x;
        }));
    };
    const updateOrInsertHotWaterCylinderRow = async (hotWaterCylinder) => {
        var _a;
        hotWaterCylinder.price = parseFloat(hotWaterCylinder.price.toString().replace(',', ''));
        hotWaterCylinder.litres = parseFloat(hotWaterCylinder.litres.toString().replace(',', ''));
        if ((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.hotWaterCylinders.some(x => x.uuid === hotWaterCylinder.uuid)) {
            logEvent({ name: 'Cylinder Modified', properties: {} }, companySubdomain);
            await updateHotWaterCylinders(hotWaterCylinder, companyUUID);
            adminContext.setHotWaterCylinders(adminContext.data.hotWaterCylinders.map(x => x.uuid === hotWaterCylinder.uuid ? hotWaterCylinder : x));
        }
        else {
            logEvent({ name: 'Cylinder Added', properties: {} }, companySubdomain);
            const id = await insertHotWaterCylinders(hotWaterCylinder, companyUUID);
            adminContext.setHotWaterCylinders([...adminContext.data.hotWaterCylinders, { ...hotWaterCylinder, uuid: id }]);
        }
    };
    const deleteHotWaterCylinderRow = async (hotWaterCylinderId) => {
        logEvent({ name: 'Cylinder Deleted', properties: {} }, companySubdomain);
        await deleteHotWaterCylinders(hotWaterCylinderId, companyUUID);
        adminContext.setHotWaterCylinders(adminContext.data.hotWaterCylinders.map(x => {
            // artificially delete the HWC in the state variable by setting deleted_at
            // in reality the deleted_at is set by the server, but we don't need a precise time here
            return x.uuid === hotWaterCylinderId ? { ...x, deleted_at: new Date() } : x;
        }));
    };
    const additionalCostsColumn = [
        { key: 'name', name: 'Name' },
        { key: 'value', name: 'Price', validator: (row) => !validateIsNumber(row.value).message }
    ];
    const updateOrInsertAdditionalCostsRow = async (lineItem) => {
        var _a, _b, _c;
        const lineItems = (_c = (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.public_info.company_line_items) !== null && _c !== void 0 ? _c : [];
        if (lineItems.some(x => x.uuid === lineItem.uuid)) {
            await updateCompanyLineItem(lineItem, companyUUID);
            adminContext.setCompany({
                ...adminContext.data.company,
                public_info: {
                    ...adminContext.data.company.public_info,
                    company_line_items: lineItems.map(x => x.uuid === lineItem.uuid ? lineItem : x)
                }
            });
        }
        else {
            const newLineItem = { uuid: crypto.randomUUID(), name: lineItem.name, value: Number(lineItem.value), company_uuid: companyUUID };
            await insertCompanyLineItem(newLineItem, companyUUID);
            adminContext.setCompany({
                ...adminContext.data.company,
                public_info: {
                    ...adminContext.data.company.public_info,
                    company_line_items: [...lineItems, newLineItem]
                }
            });
        }
    };
    const deleteAdditionalCostsRow = async (companyLineItemUUID) => {
        await deleteCompanyLineItem(companyLineItemUUID, companyUUID);
        adminContext.setCompany({
            ...adminContext.data.company,
            public_info: {
                ...adminContext.data.company.public_info,
                company_line_items: adminContext.data.company.public_info.company_line_items.filter(x => x.uuid !== companyLineItemUUID)
            }
        });
    };
    if (adminContext.isOffline) {
        return React.createElement(OfflinePage, { backURL: `/${companyPublicInfo.subdomain}/admin/surveys/all` });
    }
    return (React.createElement(RequireRole, { roles: [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS] },
        React.createElement("div", null,
            React.createElement("div", { className: 'px-4 py-4 flex gap-x-4 items-center' },
                React.createElement(Bars3Icon, { "data-cy": 'nav_open', className: "lg:hidden h-10 w-10 cursor-pointer", onClick: () => { adminContext.showSidebar(); } }),
                adminContext.data.company.public_info.logo && React.createElement("img", { src: adminContext.data.company.public_info.logo, className: "max-h-12", alt: "Installer logo" })),
            React.createElement("div", { className: 'p-8 flex flex-col gap-8' },
                React.createElement("div", { className: 'text-3xl font-bold' }, "Costs & Inventory"),
                adminContext.data.company && React.createElement(React.Fragment, null,
                    React.createElement(TabGroup, { items: [
                            { name: 'Heat pumps', onClick: () => setTab('HEAT_PUMPS'), variant: tab === 'HEAT_PUMPS' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Hot water cylinders', onClick: () => setTab('HOT_WATER_CYLINDERS'), variant: tab === 'HOT_WATER_CYLINDERS' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Labour', onClick: () => setTab('LABOUR'), variant: tab === 'LABOUR' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Survey', onClick: () => setTab('SURVEY'), variant: tab === 'SURVEY' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Additional costs', onClick: () => setTab('ADDITIONAL_COSTS'), variant: tab === 'ADDITIONAL_COSTS' ? 'ACTIVE' : 'DEFAULT' }
                        ] }),
                    tab === 'HEAT_PUMPS' && React.createElement(HeatPumpTab, { brandRanges: brandRanges, updateOrInsertHeatPumpRow: updateOrInsertHeatPumpRow, deleteHeatPumpRow: deleteHeatPumpRow, 
                        // filter HPs to show on the page
                        heatPumps: (adminContext.data.heatPumps || []).filter(x => !x.deleted_at), addHeatPumps: addHeatPumps, company: adminContext.data.company, setCompany: adminContext.setCompany }),
                    tab === 'HOT_WATER_CYLINDERS' && React.createElement(Table
                    // filter HWCs to show on the page
                    , { 
                        // filter HWCs to show on the page
                        rows: (adminContext.data.hotWaterCylinders || []).filter(x => !x.deleted_at), columns: hotWaterColumns, addNew: true, editable: true, onDeleteRow: async (id) => { await deleteHotWaterCylinderRow(id); }, onSaveRow: async (row) => { await updateOrInsertHotWaterCylinderRow(row); }, editableColumnWidth: 200 }),
                    tab === 'LABOUR' && React.createElement(Table, { columns: labourColumns, rows: [
                            { uuid: 'install_day_rate', name: 'Day Rate (£)', price: (_b = (_a = adminContext.data.company) === null || _a === void 0 ? void 0 : _a.install_day_rate) !== null && _b !== void 0 ? _b : 0 },
                            { uuid: 'install_days', name: 'No. of Days', price: (_d = (_c = adminContext.data.company) === null || _c === void 0 ? void 0 : _c.install_days) !== null && _d !== void 0 ? _d : 0 }
                        ], editable: true, onSaveRow: async (row) => {
                            logEvent({ name: 'Labour Modified', properties: {} }, companySubdomain);
                            const newCompany = { ...adminContext.data.company, [row.uuid]: parseInt(row.price.toString().replace(',', '')) };
                            await updateCompany(newCompany);
                            adminContext.setCompany(newCompany);
                        } }),
                    tab === 'SURVEY' && React.createElement(Table, { columns: surveyColumns, rows: [
                            { uuid: 'survey_cost', name: 'Survey/Design Cost', price: (_e = adminContext.data.company) === null || _e === void 0 ? void 0 : _e.survey_cost }
                        ], editable: true, onSaveRow: async (row) => {
                            logEvent({ name: 'Survey Costs Modified', properties: {} }, companySubdomain);
                            const newCompany = { ...adminContext.data.company, [row.uuid]: parseInt(row.price.toString().replace(',', '')) };
                            await updateCompany(newCompany);
                            adminContext.setCompany(newCompany);
                        } }),
                    tab === 'ADDITIONAL_COSTS' && React.createElement(Table, { onDeleteRow: async (uuid) => { await deleteAdditionalCostsRow(uuid); }, onSaveRow: async (row) => { await updateOrInsertAdditionalCostsRow(row); }, rows: (_f = adminContext.data.company.public_info.company_line_items) !== null && _f !== void 0 ? _f : [], columns: additionalCostsColumn, editable: true, addNew: true }))))));
};
