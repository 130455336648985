import React, { useCallback, useContext, useEffect, useState } from 'react';
import TabbedDashboardLayout from '../../../layouts/tabbed_dashboard_layout';
import { InstallerAdminDashboard } from './components/tabs/dashboard';
import { EnquiryManagerHeader } from './components/header';
import { useParams, useRouteLoaderData } from 'react-router-dom';
import { AdminContext } from '../admin_layout';
import { getLead, patchLead } from '../../../code/models/lead';
import { getCalculatedEstimates } from '../../../code/models/calculated_estimate';
import { ensureLeadHasEPCRecommendations, ensureLeadHasLocation3D } from '../../../code/models/property';
import { hasEnquriesAccess } from '../../../code/models/user';
import { OfflinePage } from '../offline_page';
import { UnauthorizedPage } from '../unauthorized_page';
import { calculateEstimate } from '../../../code/calculate_estimate';
import { CostEstimateEditor } from './components/tabs/cost_estimate_editor';
import { Loader } from '../../../components/indicators_and_messaging/loader';
import { EnquiryData } from './components/tabs/enquiry_data';
import { EstimatePreview } from './components/tabs/estimate_preview';
import { EnquiryHistory } from './components/tabs/enquiry_history';
import { useDebounceCallback } from 'usehooks-ts';
export const InstallerAdminPage = () => {
    var _a, _b, _c, _d, _e;
    const { leadUUID, tab } = useParams();
    const adminContext = useContext(AdminContext);
    const companyPublicInfo = useRouteLoaderData('company_root');
    const [leadSaved, setLeadSaved] = useState(false);
    const debouncePatchLead = useDebounceCallback(async (lead, companyUUID) => {
        try {
            await patchLead(lead.uuid, lead, companyUUID);
            setLeadSaved(true);
        }
        catch (e) {
            console.error(e);
        }
    }, 1000);
    const [localLead, setLocalLead] = useState();
    const lead = localLead;
    const setLead = useCallback((lead) => {
        setLeadSaved(false);
        setLocalLead(lead);
        debouncePatchLead(lead, companyPublicInfo.uuid);
    }, []);
    const [estimates, setEstimates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const loadData = async () => {
        var _a;
        if (!leadUUID)
            return;
        const lead = await ensureLeadHasEPCRecommendations((await getLead(leadUUID, companyPublicInfo.uuid)), companyPublicInfo.uuid, true);
        if (!lead) {
            setIsLoading(false);
            return;
        }
        const newLead = await ensureLeadHasLocation3D(lead, companyPublicInfo.uuid);
        setLead(newLead);
        const estimates = (_a = await getCalculatedEstimates(leadUUID, companyPublicInfo.uuid)) !== null && _a !== void 0 ? _a : [];
        setEstimates(estimates);
        setIsLoading(false);
    };
    useEffect(() => {
        if (adminContext.isOffline)
            return;
        if (adminContext.data.company === undefined)
            return;
        if (!hasEnquriesAccess(adminContext.data.company, adminContext.data.user))
            return;
        loadData();
    }, [adminContext.isLoading]);
    if (adminContext.isOffline) {
        return React.createElement(OfflinePage, { backURL: `/${companyPublicInfo.subdomain}/admin/surveys/all` });
    }
    if (adminContext.isLoading || isLoading) {
        return (React.createElement("div", { className: 'flex justify-center items-center h-full' },
            React.createElement(Loader, null)));
    }
    if (!adminContext.isLoading && !isLoading && !hasEnquriesAccess(adminContext.data.company, adminContext.data.user)) {
        return React.createElement(UnauthorizedPage, null);
    }
    if (!lead) {
        return React.createElement(UnauthorizedPage, { customHeader: 'Enquiry not found', customMessage: 'This enquiry may have been deleted or does not exist.' });
    }
    const estimate = calculateEstimate(lead, (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.heatPumps) !== null && _b !== void 0 ? _b : [], (_d = (_c = adminContext.data) === null || _c === void 0 ? void 0 : _c.hotWaterCylinders) !== null && _d !== void 0 ? _d : [], (_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.company);
    const basePath = `/${companyPublicInfo.subdomain}/admin/quotes/${leadUUID}`;
    return (React.createElement(TabbedDashboardLayout, { basePath: `/${companyPublicInfo.subdomain}/admin/quotes/${leadUUID}`, selectedTabId: tab, header: React.createElement(EnquiryManagerHeader, { lead: lead, setLead: setLead, estimates: estimates, setEstimates: setEstimates, estimate: estimate, leadSaved: leadSaved, basePath: basePath }), tabs: [
            {
                id: 'dashboard',
                label: 'Dashboard',
                content: React.createElement(InstallerAdminDashboard, { lead: lead, setLead: setLead, estimate: estimate })
            },
            {
                id: 'enquiry-data',
                label: 'Enquiry data',
                content: React.createElement(EnquiryData, { lead: lead, setLead: setLead, estimate: estimate })
            },
            {
                id: 'history',
                label: 'History',
                content: React.createElement(EnquiryHistory, { lead: lead, estimates: estimates, subdomain: companyPublicInfo.subdomain, reloadTrigger: loadData })
            },
            {
                id: 'cost-estimate',
                label: 'Edit cost estimate',
                content: React.createElement(CostEstimateEditor, { estimate: estimate, lead: lead, setLead: setLead }),
                hidden: true
            },
            {
                id: 'preview',
                label: 'Preview',
                content: React.createElement(EstimatePreview, { estimate: estimate, lead: lead })
            }
        ] }));
};
