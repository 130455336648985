import React, { useContext, useState } from 'react';
import { Section } from '../section';
import { Heading } from '../../../../../components/content_display/heading';
import { ClickableCard } from '../../../../../components/content_display/card';
import { SummaryTable } from '../../../../../components/content_display/summary_table';
import { Modal } from '../../../../../components/containers/modal';
import { orderBy, uniq } from 'lodash';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp } from '../../../../../code/models/range_heat_pump';
import { ListItem } from '../../../../../components/content_display/list_item';
import { RadioGroup } from '../../../../../components/inputs_and_selections/radio';
import { AdminContext } from '../../../admin_layout';
import { Badge } from '../../../../../components/indicators_and_messaging/badge';
import { FlowTempSlider } from '../../../../heat_loss/design/pages/emitter_design_page';
import { Alert } from '../../../../../components/indicators_and_messaging/alert';
const SelectHeatPumpModal = ({ visible, setVisible, designTempC, flowTempC, totalHeatLossKW, lead, setLead, adminContext }) => {
    var _a, _b;
    const [rangeFilter, setRangeFilter] = useState('All');
    const ranges = ['All', ...uniq((_a = adminContext.data.heatPumps) === null || _a === void 0 ? void 0 : _a.map(x => { var _a, _b; return (_b = (_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range) === null || _b === void 0 ? void 0 : _b.name; })).filter(x => !!x)];
    const filteredHeatPumps = orderBy((_b = adminContext.data.heatPumps) === null || _b === void 0 ? void 0 : _b.filter(x => (!x.deleted_at)).filter(x => { var _a, _b; return rangeFilter === 'All' || ((_b = (_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range) === null || _b === void 0 ? void 0 : _b.name) === rangeFilter; }).map(x => ({
        uuid: x.uuid,
        name: x.name,
        capacity: getHeatPumpCapacityAtOutsideTempAndFlowTemp(x.range_heat_pump, designTempC, flowTempC).capacityKw
    })), x => x.capacity);
    return (React.createElement(Modal, { title: 'Select a heat pump', visible: visible, setVisible: setVisible },
        React.createElement("div", { className: 'flex flex-col w-full gap-5' },
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: "text-gray-500 text-xs font-semibold uppercase" }, "Range"),
                React.createElement("div", { className: "flex-col gap-2 flex overflow-x-auto" },
                    React.createElement(RadioGroup, { items: ranges.map(x => ({
                            name: x,
                            onClick: () => setRangeFilter(x),
                            variant: rangeFilter === x ? 'ACTIVE' : 'DEFAULT'
                        })) }))),
            React.createElement("div", { className: "bg-white rounded-md flex-col flex divide-y divide-gray-200 " }, filteredHeatPumps.map(x => React.createElement(ListItem, { onClick: async () => {
                    setLead({ ...lead, heat_pump_uuid: x.uuid });
                    setVisible(false);
                }, key: x.uuid, primaryText: x.name, rightBadge: totalHeatLossKW
                    ? React.createElement(Badge, { color: x.capacity >= totalHeatLossKW ? 'GREEN' : 'RED', text: x.capacity.toFixed(1) + ' kW' })
                    : React.createElement(Badge, { color: 'LIGHT', text: x.capacity.toFixed(1) + ' kW' }) }))))));
};
const SelectHotWaterCylinderModal = ({ visible, setVisible, lead, setLead, adminContext }) => {
    var _a;
    return (React.createElement(Modal, { title: 'Select a hot water cylinder', visible: visible, setVisible: setVisible },
        React.createElement("div", { className: "bg-white rounded-md flex-col flex divide-y divide-light " }, (_a = adminContext.data.hotWaterCylinders) === null || _a === void 0 ? void 0 : _a.filter(x => (!x.deleted_at)).map(x => React.createElement(ListItem, { onClick: async () => {
                setLead({ ...lead, hot_water_cylinder_uuid: x.uuid });
                setVisible(false);
            }, key: x.uuid, primaryText: x.name, rightBadge: React.createElement(Badge, { color: 'LIGHT', text: `${Math.round(x.litres)} litres` }) })))));
};
export const SystemDesign = ({ lead, setLead, estimate }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
    const adminContext = useContext(AdminContext);
    const [selectHeatPumpModalVisible, setSelectHeatPumpModalVisible] = useState(false);
    const [selectHotWaterCylinderModalVisible, setSelectHotWaterCylinderModalVisible] = useState(false);
    const DEFAULT_FLOW_TEMP = 45;
    const selectedHeatPumpCapacity = getHeatPumpCapacityAtOutsideTempAndFlowTemp((_a = estimate.heatPump) === null || _a === void 0 ? void 0 : _a.range_heat_pump, estimate.designTempC, (_b = lead.flow_temperature_c) !== null && _b !== void 0 ? _b : DEFAULT_FLOW_TEMP);
    const totalHeatLossKW = estimate.totalWatts / 1000;
    const heatPumpRows = [
        { key: 'Sound power level', value: `${(_c = estimate.heatPump) === null || _c === void 0 ? void 0 : _c.range_heat_pump.sound_power_max_dba} dB(A)` },
        { key: 'Refrigerant', value: (_e = (_d = estimate.heatPump) === null || _d === void 0 ? void 0 : _d.range_heat_pump) === null || _e === void 0 ? void 0 : _e.refrigerant },
        { key: 'Volume', value: React.createElement(React.Fragment, null,
                ((((_h = (_g = (_f = estimate.heatPump) === null || _f === void 0 ? void 0 : _f.range_heat_pump) === null || _g === void 0 ? void 0 : _g.width_mm) !== null && _h !== void 0 ? _h : 0) * ((_l = (_k = (_j = estimate.heatPump) === null || _j === void 0 ? void 0 : _j.range_heat_pump) === null || _k === void 0 ? void 0 : _k.height_mm) !== null && _l !== void 0 ? _l : 0) * ((_p = (_o = (_m = estimate.heatPump) === null || _m === void 0 ? void 0 : _m.range_heat_pump) === null || _o === void 0 ? void 0 : _o.depth_mm) !== null && _p !== void 0 ? _p : 0)) / Math.pow(10, 9)).toFixed(2),
                " m\u00B3") },
        { key: 'Dimensions (WxHxD)', value: `${(_r = (_q = estimate.heatPump) === null || _q === void 0 ? void 0 : _q.range_heat_pump) === null || _r === void 0 ? void 0 : _r.width_mm}mm x ${(_t = (_s = estimate.heatPump) === null || _s === void 0 ? void 0 : _s.range_heat_pump) === null || _t === void 0 ? void 0 : _t.height_mm}mm x ${(_v = (_u = estimate.heatPump) === null || _u === void 0 ? void 0 : _u.range_heat_pump) === null || _v === void 0 ? void 0 : _v.depth_mm}mm` },
        { key: `SCOP at ${(_w = lead.flow_temperature_c) !== null && _w !== void 0 ? _w : DEFAULT_FLOW_TEMP}°C`, value: `${Math.floor(estimate.sCOP * 100)}%` },
        {
            key: `Capacity at ${(_x = lead.flow_temperature_c) !== null && _x !== void 0 ? _x : DEFAULT_FLOW_TEMP}°C (${estimate.designTempC} °C)`,
            value: totalHeatLossKW
                ? React.createElement(Badge, { color: selectedHeatPumpCapacity.capacityKw >= totalHeatLossKW ? 'GREEN' : 'RED', text: selectedHeatPumpCapacity.capacityKw.toFixed(1) + ' kW' })
                : React.createElement(Badge, { color: 'LIGHT', text: selectedHeatPumpCapacity.capacityKw.toFixed(1) + ' kW' })
        }
    ];
    const setFlowTemp = (flowTemp) => {
        setLead({ ...lead, flow_temperature_c: flowTemp });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Section, { title: 'System design' },
            React.createElement("div", { className: 'space-y-3' },
                React.createElement(Heading, { size: 'lg' }, "Heat pump model"),
                !estimate.heatPump && React.createElement(ClickableCard, { variant: 'PLACEHOLDER', onClick: () => setSelectHeatPumpModalVisible(true) }, "Please select a heat pump"),
                estimate.heatPump &&
                    React.createElement(ClickableCard, { variant: "WHITE", title: estimate.heatPump.name, onClick: () => setSelectHeatPumpModalVisible(true), showIcon: true },
                        React.createElement(SummaryTable, { rows: heatPumpRows }),
                        estimate.heatPump.deleted_at && React.createElement(Alert, { type: 'DANGER' }, "This heat pump has been deleted from your inventory. You won't be able to select it again if you change to a different one."))),
            React.createElement("div", { className: 'space-y-3' },
                React.createElement(Heading, { size: 'lg' }, "Cylinder model"),
                !estimate.hotWaterCylinder && React.createElement(ClickableCard, { variant: 'PLACEHOLDER', onClick: () => setSelectHotWaterCylinderModalVisible(true) }, "Please select a hot water cylinder"),
                estimate.hotWaterCylinder &&
                    React.createElement(ClickableCard, { variant: "WHITE", title: estimate.hotWaterCylinder.name, onClick: () => setSelectHotWaterCylinderModalVisible(true), showIcon: true },
                        React.createElement(SummaryTable, { rows: [
                                { key: 'Capacity', value: `${Math.round(estimate.hotWaterCylinder.litres || 0)} litres` }
                            ] }),
                        ((_y = estimate.hotWaterCylinder) === null || _y === void 0 ? void 0 : _y.deleted_at) && React.createElement(Alert, { type: 'DANGER' }, "This hot water cylinder has been deleted from your inventory. You won't be able to select it again if you change to a different one."))),
            React.createElement(FlowTempSlider, { flowTemp: (_z = lead.flow_temperature_c) !== null && _z !== void 0 ? _z : DEFAULT_FLOW_TEMP, setFlowTemp: setFlowTemp })),
        selectHeatPumpModalVisible && React.createElement(SelectHeatPumpModal, { visible: selectHeatPumpModalVisible, setVisible: setSelectHeatPumpModalVisible, designTempC: estimate.designTempC, flowTempC: (_0 = lead.flow_temperature_c) !== null && _0 !== void 0 ? _0 : DEFAULT_FLOW_TEMP, totalHeatLossKW: totalHeatLossKW, lead: lead, setLead: setLead, adminContext: adminContext }),
        selectHotWaterCylinderModalVisible && React.createElement(SelectHotWaterCylinderModal, { visible: selectHotWaterCylinderModalVisible, setVisible: setSelectHotWaterCylinderModalVisible, lead: lead, setLead: setLead, adminContext: adminContext })));
};
