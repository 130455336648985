import { getLayersName, getSimpleName } from '../../../code/models/material';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { TabGroup } from '../../../components/content_display/tab';
import { Input } from '../../../components/inputs_and_selections/input';
import { EditMaterialLayerBlock } from './edit_material_layer_block';
import { Card } from '../../../components/content_display/card';
import { Button } from '../../../components/buttons/button';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { BottomSheetHeader } from '../../../components/containers/bottom_sheet_header';
export const CustomMaterialPopup = ({ material, setMaterial, materialsLayers, onSave, setVisible, onBack, setHeader }) => {
    var _a;
    const [editingLayer, setEditingLayer] = React.useState(undefined);
    const [nameOverride, setNameOverride] = useState();
    if (!material)
        return;
    const materialsSurfacesWithLayers = (materialsLayers === null || materialsLayers === void 0 ? void 0 : materialsLayers.map(x => x.applicable_to).filter((value, index, self) => self.indexOf(value) === index)) || [];
    const availableLayers = (materialsLayers === null || materialsLayers === void 0 ? void 0 : materialsLayers.filter(x => x.applicable_to === material.applicable_to)) || [];
    // on the type change, reset the name and UValue, but keep layers
    useEffect(() => {
        setHeader(React.createElement(BottomSheetHeader, { title: 'Add custom material', goBack: onBack }, materialsSurfacesWithLayers.includes(material.applicable_to) && React.createElement("div", null,
            React.createElement(TabGroup, { items: [
                    {
                        name: 'Enter Uvalue',
                        onClick: () => {
                            setMaterial(prev => ({ ...prev, type: 'custom_simple' }));
                        },
                        variant: material.type === 'custom_simple' ? 'ACTIVE' : 'DEFAULT'
                    },
                    {
                        name: 'Calculate Uvalue',
                        onClick: () => {
                            setMaterial(prev => ({ ...prev, type: 'custom_layered' }));
                        },
                        variant: material.type === 'custom_layered' ? 'ACTIVE' : 'DEFAULT'
                    }
                ] }))));
    }, [material.type]);
    const handleSaveEditingLayer = () => {
        if (!editingLayer) {
            throw new Error('Editing layer is not set');
        }
        // Add the new layer to the material if UUID is undefined, otherwise update.
        if (editingLayer.uuid === undefined) {
            editingLayer.uuid = crypto.randomUUID();
            setMaterial(prev => ({
                ...prev,
                layers: [...prev.layers, editingLayer]
            }));
        }
        else {
            setMaterial(prev => ({
                ...prev,
                layers: prev.layers.map(layer => layer.uuid === editingLayer.uuid ? editingLayer : layer)
            }));
        }
        setEditingLayer(undefined);
    };
    // do some calculations on layers change
    useEffect(() => {
        // skip if the material is not a custom layered material
        if (material.type !== 'custom_layered') {
            return;
        }
        // update the U value of the material
        let rValueM2KPerW = 0;
        let uValueWPerM2K = 0;
        material.layers.forEach(layer => {
            // is material has constant R value, add it to the R value
            // applicable to Cavities
            if (layer.material_layer.extra_data.const_r_value) {
                rValueM2KPerW += layer.material_layer.extra_data.const_r_value;
            }
            else {
                rValueM2KPerW += layer.layer_thickness_mm / (1000 * layer.material_layer.extra_data.conductivity_w_per_m_k);
            }
        });
        // we should add 2 items here: the thermal resistance of the outside surface and of the inside surface:
        const rOutsideSurfaceM2KW = 0.06; // Table 3.1 CIBSE domestic heating design guide
        const rInsideSurfaceM2KW = 0.12; // Table 3.1 CIBSE domestic heating design guide
        const rValueOverallM2KW = rOutsideSurfaceM2KW + rInsideSurfaceM2KW + rValueM2KPerW;
        // set to zero in case of division by zero
        if (rValueM2KPerW === 0) {
            uValueWPerM2K = 0;
        }
        else {
            uValueWPerM2K = 1 / rValueOverallM2KW;
        }
        // round uValue to 2 decimal places and convert back to number
        setMaterial(prev => ({ ...prev, extra_data: { u_value: Number(uValueWPerM2K.toFixed(2)) } }));
    }, [material.layers, material.type]);
    const handleDeleteLayer = (layer) => {
        setMaterial(prev => ({
            ...prev,
            layers: material.layers.filter(l => l.uuid !== layer.uuid)
        }));
    };
    const handleAddNewLayer = () => {
        // create a new layer object
        const newLayer = {
            uuid: undefined,
            material_layer: availableLayers.find(l => l.name.toLowerCase().startsWith('air')) || availableLayers[0],
            layer_thickness_mm: 0,
            layer_order: material.layers.length
        };
        // set the new layer to be edited
        setEditingLayer(newLayer);
    };
    const handleUValueChange = (value) => {
        setNewMaterialUValue(value);
    };
    const nameValidator = (e) => !e || e.length === 0 ? { value: undefined, message: 'Input the new material name' } : { value: e, message: '' };
    const uValueValidator = (e) => isNaN(Number(e)) || !e || Number(e) < 0 ? { value: undefined, message: 'Not a valid number.' } : { value: Number(e), message: '' };
    const setNewMaterialUValue = (value) => {
        setMaterial(prev => ({ ...prev, extra_data: { u_value: Number(value) } }));
    };
    const generateLayerDescription = (layer) => {
        if (layer.material_layer.extra_data.const_r_value) {
            return layer.layer_thickness_mm + ' mm • ' + layer.material_layer.extra_data.const_r_value.toFixed(2) + ' m²K/W';
        }
        else {
            return layer.layer_thickness_mm + ' mm • ' + layer.material_layer.extra_data.conductivity_w_per_m_k.toFixed(2) + ' W/mK';
        }
    };
    return React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: "self-stretch my-5 px-5 bg-white flex-col justify-start gap-4 flex" },
            material.type === 'custom_simple' && React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'flex flex-col gap-2 self-stretch' },
                    React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Name"),
                    React.createElement(Input, { className: 'w-full', placeholder: 'Enter the material name', validator: nameValidator, value: nameOverride !== null && nameOverride !== void 0 ? nameOverride : getSimpleName(material.applicable_to, (_a = material.extra_data) === null || _a === void 0 ? void 0 : _a.u_value), setValue: setNameOverride, postfix: nameOverride ? React.createElement(FontAwesomeIcon, { icon: faCircleXmark, className: 'h-3 w-3 text-gray-400', onClick: () => {
                                setNameOverride(undefined);
                            } }) : undefined })),
                React.createElement("div", { className: 'flex flex-col gap-2 self-stretch' },
                    React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "UValue"),
                    React.createElement(Input, { postfix: React.createElement("span", { className: 'text-gray-500' }, "W/m\u00B2K"), className: 'w-full', validator: uValueValidator, size: 'MD', type: "number", step: 0.1, value: material.extra_data.u_value.toString(), setValue: handleUValueChange }))),
            material.type === 'custom_layered' && React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'flex flex-col gap-2 self-stretch' },
                    React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Name"),
                    React.createElement(Input, { className: 'w-full', placeholder: 'Enter the material name', validator: nameValidator, value: nameOverride !== null && nameOverride !== void 0 ? nameOverride : getLayersName(material.layers), setValue: setNameOverride, postfix: nameOverride ? React.createElement(FontAwesomeIcon, { icon: faCircleXmark, className: 'h-3 w-3 text-gray-400', onClick: () => {
                                setNameOverride(undefined);
                            } }) : undefined })),
                React.createElement("div", { className: 'flex flex-col gap-2 self-stretch' },
                    React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "UValue"),
                    React.createElement("div", { className: "w-full text-gray-600 text-base" },
                        material.extra_data.u_value.toString(),
                        " W/m\u00B2K")),
                material.layers.sort((a, b) => a.layer_order - b.layer_order).map(layer => (
                // show the editing layer block if the layer is being edited
                editingLayer && layer.uuid === (editingLayer === null || editingLayer === void 0 ? void 0 : editingLayer.uuid) ? (React.createElement(EditMaterialLayerBlock, { key: layer.uuid, layer: editingLayer, setLayer: setEditingLayer, materialLayers: availableLayers, onSave: handleSaveEditingLayer, onCancel: () => {
                        setEditingLayer(undefined);
                    } })) : (
                // show the layer block
                React.createElement(Card, { key: layer.uuid, color: 'LIGHT', className: 'w-full flex flex-row justify-between' },
                    React.createElement("div", { className: 'flex flex-col justify-start items-start' },
                        React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, layer.material_layer.name),
                        React.createElement("div", { className: "text-gray-500 text-xs" }, generateLayerDescription(layer))),
                    React.createElement("div", { className: 'flex flex-row justify-start items-center gap-4' },
                        React.createElement(Button, { colour: 'LIGHT', onClick: () => setEditingLayer(layer) }, "Edit"),
                        React.createElement(FontAwesomeIcon, { className: 'h-5 w-5 text-gray-400', icon: faTrashCan, onClick: () => handleDeleteLayer(layer) })))))),
                editingLayer && (editingLayer === null || editingLayer === void 0 ? void 0 : editingLayer.uuid) === undefined && (React.createElement(EditMaterialLayerBlock, { layer: editingLayer, setLayer: setEditingLayer, materialLayers: availableLayers, onSave: handleSaveEditingLayer, onCancel: () => {
                        setEditingLayer(undefined);
                    } })),
                React.createElement(Button, { colour: 'LIGHT', onClick: handleAddNewLayer },
                    React.createElement(FontAwesomeIcon, { icon: faPlus, className: 'mr-2' }),
                    "Add layer"))),
        React.createElement("div", { className: 'flex p-4' },
            React.createElement(Button, { block: true, colour: "DARK", size: 'MD', disabled: material.name.length === 0 ||
                    (material.type === 'custom_layered' && material.layers.length === 0) ||
                    (material.type === 'custom_simple' && material.extra_data.u_value === 0), onClick: () => {
                    var _a;
                    onSave({
                        ...material,
                        created_at: new Date().getTime(),
                        updated_at: new Date().getTime(),
                        name: nameOverride !== null && nameOverride !== void 0 ? nameOverride : (material.type === 'custom_layered'
                            ? getLayersName(material.layers)
                            : getSimpleName(material.applicable_to, (_a = material.extra_data) === null || _a === void 0 ? void 0 : _a.u_value))
                    });
                    setVisible(false);
                } }, "Apply")));
};
