import React, { useContext, useState } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { Button } from '../../../components/buttons/button';
import { ArrowLeftIcon, ChevronRightIcon } from '@heroicons/react/16/solid';
import { useRouteLoaderData } from 'react-router-dom';
import { logEvent } from '../../../code/log_event';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group';
import { StepHeader } from '../components/steps_header';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { BUILT_FORM_TYPES } from '../../../code/models/built_form';
export const BuiltFormStep = () => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    const companyPublicInfo = useRouteLoaderData('company_root');
    const [selectedOption, setSelectedOption] = useState((survey.lead.property).builtForm);
    const handleNavigatePreviousPage = () => {
        dispatch({
            type: SurveyActionType.NavigatePreviousPage
        });
    };
    const handleNavigateNextPage = () => {
        logEvent({ name: 'Built form step completed', properties: {} }, companyPublicInfo.subdomain);
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                builtForm: selectedOption
            }
        });
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Button, { colour: 'LIGHT', onClick: handleNavigatePreviousPage, block: false, className: "self-start" },
            React.createElement("div", { className: "flex items-center gap-x-2" },
                React.createElement(ArrowLeftIcon, { className: "h-4 w-4" }),
                React.createElement("div", null, "Back"))),
        React.createElement(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: "What best describes your home?" }),
        React.createElement(SurveyOptionGroup, { options: BUILT_FORM_TYPES.map(x => ({ icon: x.icon, key: x.uuid, label: x.name })), onSelect: (key) => { setSelectedOption(key); }, selectedOptionKey: selectedOption }),
        React.createElement(Button, { size: 'LG', disabled: !selectedOption, onClick: handleNavigateNextPage },
            React.createElement("div", { className: "flex items-center gap-x-2" },
                React.createElement("div", null, "Continue"),
                React.createElement(ChevronRightIcon, { className: "h-6 w-6" }))));
};
