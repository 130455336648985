import { updateCompanySettings } from '../../../code/models/company';
import React, { useRef } from 'react';
import { Section } from '../../../components/containers/section';
import { Button } from '../../../components/buttons/button';
import { faArrowUpFromBracket, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { resizeBase64Image } from '../../../components/inputs_and_selections/photo_scroll';
export const BrandLogoBlock = ({ company, setCompany }) => {
    const fileInputRef = useRef(null);
    const handleRemoveLogo = () => {
        const newCompany = {
            ...company,
            public_info: {
                ...company.public_info,
                logo: ''
            }
        };
        setCompany(newCompany);
        updateCompanySettings(newCompany);
    };
    const handleUpdateLogo = async (imageSrc) => {
        const newCompany = {
            ...company,
            public_info: {
                ...company.public_info,
                logo: imageSrc
            }
        };
        await updateCompanySettings(newCompany);
        setCompany(newCompany);
    };
    return React.createElement(Section, { border: true, title: "Company logo" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            (company.public_info.logo) && React.createElement("div", { className: 'flex flex-wrap gap-4 justify-between' },
                React.createElement("img", { src: company.public_info.logo, className: "max-h-12", alt: "Installer logo" }),
                React.createElement("div", { className: "justify-start items-center gap-3 flex" },
                    React.createElement(Button, { colour: 'LIGHT', iconLeft: faArrowUpFromBracket, onClick: () => { var _a; return (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click(); } }, "Change"),
                    React.createElement(Button, { colour: 'LIGHT', iconLeft: faTrashAlt, onClick: handleRemoveLogo }, "Remove"))),
            !company.public_info.logo && React.createElement("div", { className: "h-56 px-6 py-12 bg-gray-50 rounded-lg border-gray-400" },
                React.createElement("div", { className: 'flex flex-col gap-4 items-center justify-center' },
                    React.createElement("div", { className: 'font-bold' }, "No logo"),
                    React.createElement("div", { className: 'text-gray-500' }, "Maximum file size 5MB"),
                    React.createElement(Button, { iconLeft: faArrowUpFromBracket, onClick: () => { var _a; return (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click(); } }, "Upload logo"))),
            React.createElement("input", { ref: fileInputRef, id: "company-logo", className: 'hidden', type: "file", accept: 'image/*', capture: "environment", onChange: (e) => {
                    var _a, _b;
                    const newFile = (_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
                    if (!newFile)
                        return;
                    // Max file size is 5MB
                    if (newFile.size > 5 * 1024 * 1024) {
                        return;
                    }
                    const reader = new FileReader();
                    reader.onload = async (e) => {
                        var _a;
                        const resized = await resizeBase64Image((_a = e.target) === null || _a === void 0 ? void 0 : _a.result);
                        handleUpdateLogo(resized);
                    };
                    reader.readAsDataURL(newFile);
                } })));
};
