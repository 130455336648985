import React, { useEffect, useState } from 'react';
import { formatMaterialUValue, getMaterialUValue, isMaterialAgeBandIntersectsSurveyAgeBand, MATERIAL_ELEMENT_NAMES } from '../../../code/models/material';
import { TabGroup } from '../../../components/content_display/tab';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { RadioGroup } from '../../../components/inputs_and_selections/radio';
import { Button } from '../../../components/buttons/button';
import { MaterialItemBlock } from './material_item_block';
import { BottomSheetHeader } from '../../../components/containers/bottom_sheet_header';
// constant value for the special case when all values are selected
const specialValueForAll = 'All';
const specialValueForNone = 'None';
const pillValueMmSuffix = (value) => {
    if (value && value.toString().toLowerCase() === specialValueForNone.toLowerCase()) {
        return '';
    }
    return 'mm';
};
const uiFiltersRetrofit = {
    'external-wall': [
        { uiName: 'Construction', attributeName: 'category' },
        { uiName: 'Insulation Type', attributeName: 'insulation_type' },
        { uiName: 'External Finish', attributeName: 'exterior_finish' }
    ],
    'party-wall': [],
    'internal-wall': [
        { uiName: 'Construction', attributeName: 'construction' },
        { uiName: 'Thickness', attributeName: 'thickness_mm', suffix: pillValueMmSuffix, sortAsNumbers: true }
    ],
    'ground-floor': [
        { uiName: 'Construction', attributeName: 'construction' },
        { uiName: 'Insulation Thickness', attributeName: 'insulation_thickness_mm', suffix: pillValueMmSuffix, sortAsNumbers: true }
    ],
    'intermediate-floor-and-ceiling': [
        //   TODO: figure out how to not show exposed floors as an option in the default materials interface
        { uiName: 'Construction', attributeName: 'type' },
        { uiName: 'Insulation Thickness', attributeName: 'insulation_thickness_mm', suffix: pillValueMmSuffix, sortAsNumbers: true }
    ],
    roof: [
        { uiName: 'Construction', attributeName: 'construction' },
        { uiName: 'Insulation Thickness', attributeName: 'insulation_thickness_mm', suffix: pillValueMmSuffix, sortAsNumbers: true }
    ],
    door: [
        { uiName: 'Type', attributeName: 'type' }
    ],
    window: [
        { uiName: 'Glazing type', attributeName: 'glazing_type' },
        { uiName: 'Frame material', attributeName: 'frame_materials' }
    ]
};
const uiFiltersNewBuild = [
    { uiName: 'Age band', attributeName: 'age_band', sortAsNumberRanges: true },
    { uiName: 'Type', attributeName: 'new_build_or_extension' }
];
export const MaterialsSelector = ({ groupedProps, setGroupedProps, setTempCustomMaterial, setMaterialsCallback, setPage, setHeader, onBack, companyUUID }) => {
    var _a;
    const buildTypeFilter = groupedProps.selectedMaterial.build_type;
    const materials = ((_a = groupedProps.materials) === null || _a === void 0 ? void 0 : _a.filter(material => material.applicable_to === groupedProps.surfaceType && !material.deleted_at)) || [];
    const [selectedUIFilters, setSelectedUIFilters] = useState([]);
    useEffect(() => {
        setSelectedUIFilters(buildTypeFilter === 'retrofit'
            ? (uiFiltersRetrofit[groupedProps.surfaceType] || []).map(filter => ({ attributeName: filter.attributeName, value: specialValueForAll }))
            : uiFiltersNewBuild.map(filter => ({ attributeName: filter.attributeName, value: specialValueForAll })));
    }, [buildTypeFilter]);
    const filteredByBuildType = materials.filter(m => { var _a; return ((_a = m.build_type) !== null && _a !== void 0 ? _a : undefined) === buildTypeFilter; }) || [];
    const filteredByUIFilters = selectedUIFilters.length === 0
        ? filteredByBuildType
        : filteredByBuildType.filter(material => {
            // filter by all selected filters
            return selectedUIFilters
                // check if all filters are present in the material
                .every(filter => {
                if (filter.value === specialValueForAll) {
                    return true;
                }
                return material.extra_data[filter.attributeName] === filter.value;
            });
        });
    const sortedMaterials = filteredByUIFilters.sort((a, b) => {
        const uValueA = getMaterialUValue(a);
        const uValueB = getMaterialUValue(b);
        if (uValueA && uValueB) {
            // if some of the values are strings, compare them as strings
            if (typeof uValueA === 'string' || typeof uValueB === 'string' ||
                (typeof uValueA === 'number' && typeof uValueB === 'string') ||
                (typeof uValueA === 'string' && typeof uValueB === 'number')) {
                return uValueA.toString().localeCompare(uValueB.toString());
            }
            // if both values are numbers, compare them as numbers
            if (typeof uValueA === 'number' && typeof uValueB === 'number') {
                return uValueA - uValueB;
            }
        }
        return a.name.localeCompare(b.name);
    });
    useEffect(() => {
        if (setHeader) {
            setHeader(React.createElement(BottomSheetHeader, { title: 'Choose a material', goBack: onBack }));
        }
    }, []);
    if (!groupedProps) {
        return;
    }
    const applyFilter = (index, attributeName, value) => {
        const newFilters = selectedUIFilters;
        newFilters[index] = { attributeName, value };
        setSelectedUIFilters([...newFilters]);
    };
    const isFilterSelected = (uiFilterIndex, attributeName, value) => {
        // if there is no filter selected for the index, it means that all values are selected
        if (selectedUIFilters[uiFilterIndex] === undefined) {
            // return true if the compared value is specialValueForAll
            return value === specialValueForAll;
        }
        // check if the filter is selected
        return selectedUIFilters.some(filter => filter.attributeName === attributeName && filter.value === value);
    };
    const getFilterValues = (filter) => {
        let materialsList = materials.filter(m => m.build_type === buildTypeFilter) || [];
        // apply all filters
        selectedUIFilters.forEach((filter, index) => {
            // for the first filter, do not apply it
            if (filter.value === specialValueForAll) {
                return;
            }
            materialsList = materialsList.filter(material => material.extra_data[filter.attributeName] === filter.value);
        });
        // now materialsList contains only materials that satisfy all selected filters
        // get all unique values of the attribute
        const availableValues = materialsList.map(material => material.extra_data[filter.attributeName]);
        // keep only unique values
        const uniqueValues = Array.from(new Set(availableValues));
        // sort as numbers if needed
        if (filter.sortAsNumbers) {
            // lookup for None values
            const noneIndex = uniqueValues.indexOf(specialValueForNone);
            if (noneIndex !== -1) {
                // remove none from the list
                uniqueValues.splice(noneIndex, 1);
            }
            // sort as numbers
            uniqueValues.sort((a, b) => Number(a) - Number(b));
            // add None back to the list
            if (noneIndex !== -1) {
                // add None as a first element
                uniqueValues.unshift(specialValueForNone);
            }
        }
        // sort as number ranges if needed
        if (filter.sortAsNumberRanges) {
            uniqueValues.sort((a, b) => {
                // split the values to ranges
                const rangeA = a.split('-').map(x => Number(x));
                const rangeB = b.split('-').map(x => Number(x));
                // compare the first values
                if (rangeA[0] !== rangeB[0]) {
                    return rangeA[0] - rangeB[0];
                }
                // compare the second values
                if (rangeA[1] !== rangeB[1]) {
                    return rangeA[1] - rangeB[1];
                }
                return 0;
            });
        }
        return [{
                name: specialValueForAll,
                value: specialValueForAll
            },
            ...uniqueValues.map(x => {
                return {
                    name: x + (filter.suffix ? filter.suffix(x) : ''),
                    value: x
                };
            })];
    };
    const getAgeBandLabel = (ageBand) => {
        if (!ageBand.min_year) {
            return 'Typical for before ' + ageBand.max_year;
        }
        if (!ageBand.max_year) {
            return 'Typical for ' + ageBand.min_year + ' — now';
        }
        return 'Typical for ' + ageBand.min_year + ' — ' + ageBand.max_year;
    };
    const getListByAgeBand = (materials) => {
        // gather all age bands of the materials
        const ageBands = materials.map(material => material.age_bands.flat()).flat().sort((x, y) => (x.min_year || 0) - (y.min_year || 0));
        // keep only unique values (Set does not work because the values are objects)
        const uniqueABs = [];
        ageBands.forEach(element => {
            // group by years, not UUID, because the same age band years(!) can be used in different materials
            if (!uniqueABs.find(x => x.min_year === element.min_year && x.max_year === element.max_year)) {
                uniqueABs.push(element);
            }
        });
        // group materials by age band
        const list = [];
        uniqueABs.forEach(ageBand => {
            // show all age bands if no age band set
            // otherwise, show only the age band that fits the Property
            if (groupedProps.ageBand === undefined || (groupedProps.ageBand && isMaterialAgeBandIntersectsSurveyAgeBand(groupedProps.ageBand, ageBand))) {
                list.push({ type: 'age_band', object: ageBand });
                materials.forEach(material => {
                    // compare the age band by year, not by UUID
                    if (material.age_bands.find(x => x.min_year === ageBand.min_year && x.max_year === ageBand.max_year)) {
                        list.push({ type: 'material', object: material });
                    }
                });
            }
        });
        return list;
    };
    const getPillFilters = () => {
        if (buildTypeFilter === 'new-build') {
            return uiFiltersNewBuild;
        }
        if (buildTypeFilter === 'retrofit') {
            return (uiFiltersRetrofit[groupedProps.surfaceType] || []);
        }
        return [];
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-col gap-2 p-5' },
            React.createElement(TabGroup, { items: [
                    { name: 'Retrofit', onClick: () => setGroupedProps(prev => ({ ...prev, selectedMaterial: { ...prev.selectedMaterial, build_type: 'retrofit' } })), variant: buildTypeFilter === 'retrofit' ? 'ACTIVE' : 'DEFAULT' },
                    { name: 'New build', onClick: () => setGroupedProps(prev => ({ ...prev, selectedMaterial: { ...prev.selectedMaterial, build_type: 'new-build' } })), variant: buildTypeFilter === 'new-build' ? 'ACTIVE' : 'DEFAULT' },
                    { name: 'Custom', onClick: () => setGroupedProps(prev => ({ ...prev, selectedMaterial: { ...prev.selectedMaterial, build_type: undefined } })), variant: buildTypeFilter === undefined ? 'ACTIVE' : 'DEFAULT' }
                ] }),
            (buildTypeFilter !== undefined && materials.filter(m => m.build_type === buildTypeFilter).length > 1) &&
                React.createElement("div", { className: "gap-4 bg-white flex-col flex text-left" }, getPillFilters().map((filter, index) => (React.createElement("div", { key: index, className: "w-full flex-col gap-2 flex" },
                    React.createElement("div", { className: "text-gray-500 text-xs font-semibold uppercase tracking-wide" }, filter.uiName),
                    React.createElement("div", { className: "w-full gap-3 inline-flex overflow-x-auto relative no-scrollbar" },
                        React.createElement(RadioGroup, { items: getFilterValues(filter).map(x => ({
                                name: x.name,
                                onClick: () => applyFilter(index, filter.attributeName, x.value),
                                variant: isFilterSelected(index, filter.attributeName, x.value) ? 'ACTIVE' : 'DEFAULT'
                            })) }))))))),
        React.createElement("div", { className: "flex-col flex" },
            groupedProps.selectedMaterial && React.createElement(React.Fragment, null,
                React.createElement("div", { key: 'selected-material', className: "self-stretch px-5 pt-3 pb-2 bg-gray-50 border-t border-gray-200 justify-center items-center gap-3 inline-flex" },
                    React.createElement("div", { className: "grow shrink basis-0 text-gray-600 text-xs font-semibold uppercase tracking-wide" }, "Selected")),
                React.createElement("div", { key: 'material', className: "self-stretch px-5 bg-gray-200 justify-center items-center gap-2 inline-flex" },
                    React.createElement("div", { className: "grow shrink basis-0 py-3 justify-center items-center gap-3 flex" },
                        React.createElement("div", { className: "grow shrink basis-0 text-gray-900 text-sm font-semibold" }, groupedProps.selectedMaterial.name),
                        getMaterialUValue(groupedProps.selectedMaterial) !== undefined &&
                            React.createElement(Badge, { color: 'LIGHT', text: formatMaterialUValue(getMaterialUValue(groupedProps.selectedMaterial)) })))),
            getListByAgeBand(sortedMaterials).map((item, index) => (item.type === 'age_band' ? (React.createElement("div", { key: 'age-band-name-' + item.object.uuid + '-' + index, className: "shrink basis-0 px-5 pt-3 pb-2 bg-gray-50 w-full text-gray-600 text-xs font-semibold uppercase tracking-wide" }, getAgeBandLabel(item.object))) : item.type === 'material' ? (React.createElement(MaterialItemBlock, { selectedMaterial: groupedProps.selectedMaterial, key: 'age-band-' + item.object.uuid + '-' + index, material: item.object, onClick: () => {
                    if (groupedProps.onSelectCallback) {
                        groupedProps.onSelectCallback(item.object);
                    }
                    onBack();
                } })) : null)),
            React.createElement("div", { className: "shrink basis-0 px-5 pt-3 pb-2 bg-gray-50 w-full text-gray-600 text-xs font-semibold uppercase tracking-wide" }, "All materials"),
            sortedMaterials.map(material => (React.createElement(MaterialItemBlock, { key: 'all-' + material.uuid, selectedMaterial: groupedProps.selectedMaterial, material: material, onClick: () => {
                    if (groupedProps.onSelectCallback) {
                        groupedProps.onSelectCallback(material);
                    }
                    onBack();
                }, 
                // add DELETE button for custom materials
                onDelete: () => {
                    if (material.type !== 'generic') {
                        const updateMaterials = (materials) => materials.map(x => x.uuid === material.uuid
                            ? { ...x, deleted_at: new Date().getTime(), updated_at: new Date().getTime() }
                            : x);
                        setMaterialsCallback(prev => updateMaterials(prev));
                        setGroupedProps(prev => ({ ...prev, materials: updateMaterials(prev.materials) }));
                    }
                } })))),
        React.createElement("div", { className: 'p-4 flex' }, buildTypeFilter === undefined && React.createElement(Button, { block: true, colour: "LIGHT", size: 'MD', onClick: () => {
                setPage('CUSTOM_MATERIAL');
                setTempCustomMaterial({
                    uuid: crypto.randomUUID(),
                    name: MATERIAL_ELEMENT_NAMES[groupedProps.selectedMaterial.applicable_to] + ' 0 - W/m2K',
                    type: 'custom_simple',
                    applicable_to: groupedProps.selectedMaterial.applicable_to,
                    extra_data: {
                        u_value: 0
                    },
                    layers: [],
                    build_type: undefined,
                    age_bands: [],
                    created_at: 0,
                    updated_at: 0,
                    server_updated_at: 0,
                    deleted_at: undefined,
                    company_uuid: companyUUID
                });
            } }, "Add custom material"))));
};
