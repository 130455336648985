import React, { useState } from 'react';
const sizes = {
    SM: 'text-xs p-2.5',
    MD: 'text-sm px-4 py-2',
    LG: 'px-4 py-3'
};
export const TextArea = ({ validator, value, setValue, placeholder, size = 'MD', className, rows, disabled, readonly }) => {
    const validation = validator === null || validator === void 0 ? void 0 : validator(value);
    const [visited, hasVisited] = useState(false);
    const isInvalid = validator && visited && !(validation === null || validation === void 0 ? void 0 : validation.value);
    return (React.createElement("div", { className: 'flex flex-col space-y-1 w-full' },
        React.createElement("textarea", { "data-cy": "textarea", onBlur: () => { hasVisited(true); }, placeholder: placeholder, className: `outline-none w-full ${sizes[size]} {className} ${disabled ? 'bg-gray-100 border-gray-300' : 'bg-white'} placeholder:text-gray-500 text-gray-600 rounded-lg border ${isInvalid ? 'border-red-500 text-red-800' : 'border-gray-300 text-gray-600'}`, onChange: (e) => { setValue(e.currentTarget.value); }, value: value, disabled: disabled !== null && disabled !== void 0 ? disabled : false, readOnly: readonly !== null && readonly !== void 0 ? readonly : false, rows: rows !== null && rows !== void 0 ? rows : 4 }),
        isInvalid && React.createElement("div", { className: "text-red-700 text-sm" }, validation === null || validation === void 0 ? void 0 : validation.message)));
};
