import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthSDK } from '../code/utils/auth_provider';
import { getUser } from '../code/models/user';
import { useLocalStorage } from 'usehooks-ts';
import { Loader } from '../components/indicators_and_messaging/loader';
export const RootPage = () => {
    var _a;
    const [subdomain, setSubdomain] = useLocalStorage('subdomain', '');
    const [loading, setLoading] = useState(true);
    const userUUID = (_a = AuthSDK.getTokenPayload()) === null || _a === void 0 ? void 0 : _a.user_uuid;
    useEffect(() => {
        const fetchUser = async () => {
            if (userUUID && !subdomain) {
                const fetchedUser = await getUser();
                setSubdomain(fetchedUser === null || fetchedUser === void 0 ? void 0 : fetchedUser.companies[0].subdomain);
            }
            setLoading(false);
        };
        fetchUser();
    }, []);
    if (loading) {
        return React.createElement(Loader, null);
    }
    // if user is logged in
    if (userUUID) {
        return React.createElement(Navigate, { to: `/${subdomain}/admin`, replace: true });
    }
    // otherwise redirect to /login page
    return React.createElement(Navigate, { to: '/login', replace: true });
};
