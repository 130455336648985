import React, { useEffect, useState } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { useNavigate, useParams } from 'react-router-dom';
const TabbedDashboardLayout = ({ basePath, header, tabs, selectedTabId, selectedSecondaryTabId }) => {
    const navigate = useNavigate();
    const { tabId, secondaryTabId } = useParams();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedSecondaryIndex, setSelectedSecondaryIndex] = useState(0);
    useEffect(() => {
        var _a;
        const tabIndex = tabs.findIndex(x => x.id === (tabId || selectedTabId));
        setSelectedIndex(tabIndex !== -1 ? tabIndex : 0);
        if ((_a = tabs[tabIndex]) === null || _a === void 0 ? void 0 : _a.secondaryTabs) {
            const secondaryTabIndex = tabs[tabIndex].secondaryTabs.findIndex(x => x.id === (secondaryTabId || selectedSecondaryTabId));
            setSelectedSecondaryIndex(secondaryTabIndex !== -1 ? secondaryTabIndex : 0);
        }
    }, [tabId, secondaryTabId, selectedTabId, selectedSecondaryTabId, tabs]);
    const handleTabChange = (index) => {
        var _a;
        const newTabId = tabs[index].id;
        if (tabs[index].secondaryTabs) {
            navigate(`${basePath}/${newTabId}/${(_a = tabs[index].secondaryTabs) === null || _a === void 0 ? void 0 : _a[0].id}`);
        }
        else {
            navigate(`${basePath}/${newTabId}`);
        }
    };
    const handleSecondaryTabChange = (index) => {
        const currentTab = tabs[selectedIndex];
        if (currentTab.secondaryTabs) {
            const newSecondaryTabId = currentTab.secondaryTabs[index].id;
            navigate(`${basePath}/${currentTab.id}/${newSecondaryTabId}`);
        }
    };
    return (React.createElement(TabGroup, { selectedIndex: selectedIndex, onChange: handleTabChange, className: 'relative flex flex-col flex-1 bg-gray-100 md:flex-col' },
        React.createElement("div", { className: 'bg-white' },
            header,
            React.createElement("div", { className: 'overflow-x-auto w-full px-6 py-3' },
                React.createElement(TabList, { className: 'flex flex-row gap-1' }, tabs.map(tab => (React.createElement(Tab, { key: tab.id, className: `${tab.hidden && 'hidden'} p-3 rounded-md cursor-pointer text-default whitespace-nowrap text-center data-[selected]:bg-gray-200 data-[selected]:text-bold data-[selected]:font-semibold` }, tab.label)))))),
        React.createElement(TabPanels, { className: "overflow-y-auto flex-1 p-6" }, tabs.map(tab => (React.createElement(TabPanel, { key: tab.id }, tab.secondaryTabs ? (React.createElement(TabGroup, { vertical: true, selectedIndex: selectedSecondaryIndex, onChange: handleSecondaryTabChange, className: 'flex flex-col md:flex-row gap-6' },
            React.createElement(TabList, { className: 'flex flex-row md:flex-col md:min-w-fit md:w-60 overflow-x-auto' }, tab.secondaryTabs.map(secondaryTab => (React.createElement(Tab, { key: secondaryTab.id, className: 'p-3 md:py-2.5 md:px-2 rounded-md cursor-pointer text-sm text-default whitespace-nowrap text-left data-[selected]:bg-gray-200 data-[selected]:text-bold data-[selected]:font-semibold' }, secondaryTab.label)))),
            React.createElement(TabPanels, { className: 'w-full' }, tab.secondaryTabs.map(secondaryTab => (React.createElement(TabPanel, { key: secondaryTab.id }, secondaryTab.content)))))) : tab.content))))));
};
export default TabbedDashboardLayout;
