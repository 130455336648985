import { getCompanyBookingConfirmationActionEmailTemplate, getCompanyBookingConfirmationNoActionEmailTemplate, updateCompanySettings } from '../../../code/models/company';
import React, { useEffect, useState } from 'react';
import { Section } from '../../../components/containers/section';
import { Input } from '../../../components/inputs_and_selections/input';
import { Tiptap } from '../../../components/inputs_and_selections/tiptap';
import { TTPlaceholderCompanyName, TTPlaceholderCompanyPhone, TTPlaceholderCustomerName } from '../../../code/tiptap_placeholders';
import { Button } from '../../../components/buttons/button';
import { Toggle } from '../../../components/inputs_and_selections/toggle';
export const BookingConfirmationOptionsBlock = ({ company, setCompany }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isRedirectEnabled, setIsRedirectEnabled] = useState(company.booking_redirect_enabled);
    const [redirectUrl, setRedirectUrl] = useState(company.booking_redirect_url);
    const [noActionTemplate, setNoActionTemplate] = useState(getCompanyBookingConfirmationNoActionEmailTemplate(company.booking_confirmation_noaction_template));
    const [actionTemplate, setActionTemplate] = useState(getCompanyBookingConfirmationActionEmailTemplate(company.booking_confirmation_action_template));
    const [editorWithAction, setEditorWithAction] = useState();
    const [editorNoAction, setEditorNoAction] = useState();
    const [beforeEditValues, setBeforeEditValues] = useState({
        templateWithAction: undefined,
        templateNoAction: undefined,
        isRedirectEnabled: undefined,
        redirectUrl: undefined
    });
    useEffect(() => {
        setIsRedirectEnabled(company.booking_redirect_enabled);
        setRedirectUrl(company.booking_redirect_url);
    }, []);
    if (!company) {
        return;
    }
    const handleStartEditing = () => {
        // save original state to restore on "Cancel"
        setBeforeEditValues({
            templateWithAction: actionTemplate,
            templateNoAction: noActionTemplate,
            isRedirectEnabled,
            redirectUrl
        });
        setIsEditing(true);
    };
    const handleCancelEditing = () => {
        // restore original state
        setActionTemplate(beforeEditValues.templateWithAction);
        setNoActionTemplate(beforeEditValues.templateNoAction);
        setIsRedirectEnabled(beforeEditValues.isRedirectEnabled);
        setRedirectUrl(beforeEditValues.redirectUrl);
        // override editor content
        editorWithAction === null || editorWithAction === void 0 ? void 0 : editorWithAction.commands.setContent(beforeEditValues.templateWithAction);
        editorNoAction === null || editorNoAction === void 0 ? void 0 : editorNoAction.commands.setContent(beforeEditValues.templateNoAction);
        setBeforeEditValues({
            templateWithAction: undefined,
            templateNoAction: undefined,
            isRedirectEnabled: undefined,
            redirectUrl: undefined
        });
        setIsEditing(false);
    };
    const handleOnSave = async () => {
        if (isEditing) {
            const tmpCompany = {
                ...company,
                booking_confirmation_action_template: JSON.stringify(actionTemplate),
                booking_confirmation_noaction_template: JSON.stringify(noActionTemplate),
                booking_redirect_enabled: isRedirectEnabled,
                booking_redirect_url: redirectUrl
            };
            await updateCompanySettings(tmpCompany);
            setCompany(tmpCompany);
            setIsEditing(false);
        }
    };
    return React.createElement(Section, { border: true, title: "Booking survey options" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'space-y-2' },
                React.createElement("label", { className: 'flex flex-row gap-2 items-center' },
                    React.createElement(Toggle, { value: isRedirectEnabled, setValue: isEditing ? () => { setIsRedirectEnabled(!isRedirectEnabled); } : () => { }, size: 'MD' }),
                    React.createElement("span", null, "Take user straight to a booking link")),
                React.createElement("div", { className: 'space-y-8' }, isRedirectEnabled && React.createElement("div", { className: 'space-y-2' },
                    React.createElement("div", { className: 'font-bold' }, "URL to redirect the user to"),
                    React.createElement(Input, { validator: (value) => (value === null || value === void 0 ? void 0 : value.length) > 0 ? { value, message: '' } : {
                            value: undefined,
                            message: 'Please enter a valid URL'
                        }, disabled: !isEditing, placeholder: 'Enter url here', value: redirectUrl, setValue: (e) => setRedirectUrl(e) }))),
                React.createElement("div", { className: `${!isRedirectEnabled ? 'hidden' : ''}` }, isRedirectEnabled &&
                    React.createElement(Tiptap, { onEditorInit: (editor) => setEditorWithAction(editor), editable: isEditing, noPlaceholderButtons: !isEditing, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                            setActionTemplate(editor.getJSON());
                        }, placeholders: [
                            TTPlaceholderCustomerName,
                            TTPlaceholderCompanyName,
                            TTPlaceholderCompanyPhone
                        ], content: actionTemplate })),
                React.createElement("div", { className: `${isRedirectEnabled ? 'hidden' : ''}` }, !isRedirectEnabled &&
                    React.createElement(Tiptap, { onEditorInit: (editor) => setEditorNoAction(editor), editable: isEditing, noPlaceholderButtons: !isEditing, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                            setNoActionTemplate(editor.getJSON());
                        }, placeholders: [
                            TTPlaceholderCustomerName,
                            TTPlaceholderCompanyName,
                            TTPlaceholderCompanyPhone
                        ], content: noActionTemplate }))),
            React.createElement("div", { className: 'flex gap-x-2' },
                !isEditing && React.createElement(Button, { colour: 'LIGHT', onClick: handleStartEditing }, "Edit"),
                isEditing &&
                    React.createElement(React.Fragment, null,
                        React.createElement(Button, { onClick: handleOnSave }, "Save"),
                        React.createElement(Button, { colour: 'LIGHT', onClick: handleCancelEditing }, "Cancel")))));
};
