import React from 'react';
import { createBrowserRouter, redirect } from 'react-router-dom';
import { getCompanyPublicInfo } from './code/models/company';
import { AdminLayout } from './pages/admin/admin_layout';
import { LeadsListPage } from './pages/admin/leads_list_page';
import { LoginPage } from './pages/admin/login_page';
import { NewPasswordPage } from './pages/admin/new_password';
import { RemindPasswordPage } from './pages/admin/remind_password_page';
import { SettingsPage } from './pages/admin/settings_page/settings_page';
import { Components } from './pages/components/components';
import { Error404Page, Error500Page } from './pages/error_pages';
import { EstimatePage } from './pages/estimate_page';
import { SignUpPage } from './pages/sign_up_page';
import { SurveyPageWrapper } from './pages/survey/survey_page';
import { CostsPage } from './pages/admin/costs_page/costs_page';
import { SurveysListPage } from './pages/admin/surveys_list_page';
import { PropertyHome } from './pages/heat_loss/property_home';
import { RootPage } from './pages/root_page';
import { AcceptInvitationPage } from './pages/accept_invitation_page';
import { AuthSDK } from './code/utils/auth_provider';
import { InstallerAdminPage } from './pages/admin/manage_enquiry/manage_enquiry_page';
export const mainRouter = createBrowserRouter([
    {
        path: '/',
        element: React.createElement(RootPage, null),
        errorElement: React.createElement(Error500Page, null)
    },
    {
        path: '/login',
        element: React.createElement(LoginPage, null),
        errorElement: React.createElement(Error500Page, null)
    },
    {
        path: '/logout',
        loader: async (request) => {
            AuthSDK.signOut();
            return redirect('/login');
        }
    },
    {
        path: 'remind-password',
        element: React.createElement(RemindPasswordPage, null)
    },
    {
        path: '/sign_up',
        element: React.createElement(SignUpPage, null),
        errorElement: React.createElement(Error500Page, null)
    },
    {
        path: '/invitation/accept/:token',
        element: React.createElement(AcceptInvitationPage, null)
    },
    {
        path: '/components',
        element: React.createElement(Components, null)
    },
    {
        path: '/:companySubdomain',
        id: 'company_root',
        loader: async (request) => {
            return await getCompanyPublicInfo(request.params.companySubdomain);
        },
        errorElement: React.createElement(Error500Page, null),
        children: [
            {
                index: true,
                element: React.createElement(SurveyPageWrapper, { showLogo: true, isAdmin: false })
            },
            {
                path: 'embed',
                element: React.createElement(SurveyPageWrapper, { showLogo: false, isAdmin: false }),
                errorElement: React.createElement(Error500Page, null)
            },
            {
                // NB! The page is not protected by auth because it's used by the customer to view the estimate
                path: 'estimate/:estimateId',
                element: React.createElement(EstimatePage, null),
                errorElement: React.createElement(Error500Page, null)
            },
            {
                path: 'admin',
                element: React.createElement(AdminLayout, null),
                id: 'admin_root',
                children: [
                    // enquiries listing pages
                    {
                        path: 'enquiries',
                        children: [
                            {
                                index: true,
                                element: React.createElement(LeadsListPage, { filter: "All", hideFilterBar: false, title: 'Enquiries' })
                            },
                            {
                                path: 'inprogress',
                                element: React.createElement(LeadsListPage, { filter: "New", hideFilterBar: false, title: 'Enquiries' })
                            },
                            {
                                path: 'estimated',
                                element: React.createElement(LeadsListPage, { filter: "Quoted", hideFilterBar: false, title: 'Enquiries' })
                            },
                            {
                                path: 'accepted',
                                element: React.createElement(LeadsListPage, { filter: "Converted", hideFilterBar: false, title: 'Enquiries' })
                            },
                            {
                                path: 'rejected',
                                element: React.createElement(LeadsListPage, { filter: "Rejected", hideFilterBar: false, title: 'Enquiries' })
                            },
                            {
                                path: 'declined',
                                element: React.createElement(LeadsListPage, { filter: "Declined", hideFilterBar: false, title: 'Enquiries' })
                            },
                            {
                                path: 'survey_booked',
                                element: React.createElement(LeadsListPage, { filter: "SurveyBooked", hideFilterBar: false, title: 'Enquiries' })
                            },
                            {
                                path: 'archived',
                                element: React.createElement(LeadsListPage, { filter: "Archived", hideFilterBar: false, title: 'Enquiries' })
                            },
                            {
                                path: 'new',
                                element: React.createElement(SurveyPageWrapper, { showLogo: true, isAdmin: true })
                            }
                        ]
                    },
                    {
                        path: 'quotes/:leadUUID/:tab?',
                        element: React.createElement(InstallerAdminPage, null)
                    },
                    {
                        path: 'surveys',
                        children: [
                            {
                                index: true,
                                loader: async (request) => {
                                    return redirect(`/${request.params.companySubdomain}/admin/surveys/all`);
                                }
                            },
                            {
                                path: 'all',
                                element: React.createElement(SurveysListPage, null)
                            },
                            {
                                path: 'in_progress',
                                element: React.createElement(SurveysListPage, { stateFilter: 'IN_PROGRESS' })
                            },
                            {
                                path: 'completed',
                                element: React.createElement(SurveysListPage, { stateFilter: 'COMPLETED' })
                            }
                        ]
                    },
                    {
                        path: 'surveys/:lead_id',
                        element: React.createElement(PropertyHome, null)
                    },
                    {
                        path: 'settings/:tab?/:secondaryTab?',
                        element: React.createElement(SettingsPage, null)
                    },
                    {
                        path: 'costs',
                        element: React.createElement(CostsPage, null)
                    },
                    // backward compatibility: keep old URL working
                    {
                        path: 'login',
                        loader: async (request) => {
                            return redirect('/login');
                        }
                    },
                    {
                        path: 'new-password',
                        element: React.createElement(NewPasswordPage, null)
                    },
                    // backward compatibility: keep old URL working
                    {
                        path: 'newquote',
                        loader: async (request) => {
                            return redirect(`/${request.params.companySubdomain}/admin/enquiries/new`);
                        }
                    },
                    // backward compatibility: keep old URL working
                    {
                        path: 'inprogress',
                        loader: async (request) => {
                            return redirect(`/${request.params.companySubdomain}/admin/enquiries/inprogress`);
                        }
                    },
                    // backward compatibility: keep old URL working
                    {
                        path: 'rejected',
                        loader: async (request) => {
                            return redirect(`/${request.params.companySubdomain}/admin/enquiries/rejected`);
                        }
                    }
                ]
            }
        ]
    },
    // all other URLs are 404
    {
        path: '*',
        element: React.createElement(Error404Page, null)
    }
]);
