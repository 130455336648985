import React from 'react';
import { HLRContext } from './heatloss_report';
export const HLRHeader = () => {
    var _a, _b;
    const hlrContext = React.useContext(HLRContext);
    const logoImageData = hlrContext.company.public_info.logo;
    const preparedFor = [
        (_b = (_a = hlrContext.lead.customer) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '',
        hlrContext.lead.property.address,
        hlrContext.lead.property.postcode
    ];
    const preparedBy = [
        hlrContext.company.public_info.installer_first_name + ' ' + hlrContext.company.public_info.installer_last_name,
        hlrContext.company.public_info.name,
        hlrContext.company.public_info.notification_email,
        hlrContext.company.phone
    ];
    return React.createElement("div", { className: "w-full pt-2 pb-2 bg-white border-b border-gray-400 justify-between items-center gap-6 flex" },
        logoImageData && React.createElement("img", { src: logoImageData, className: "max-h-12", alt: "Installer logo" }),
        React.createElement("div", { className: "flex gap-12" },
            React.createElement("div", { className: "flex-col flex" },
                React.createElement("div", { className: "text-gray-900 text-xs font-bold" }, "Prepared for"),
                preparedFor.map((x, i) => React.createElement("div", { key: i, className: "text-gray-600 text-xs font-medium tracking-tight" }, x))),
            React.createElement("div", { className: "flex-col justify-start items-start flex" },
                React.createElement("div", { className: "text-gray-900 text-xs font-bold" }, "Prepared by"),
                preparedBy.map((x, i) => React.createElement("div", { key: i, className: "text-gray-600 text-xs font-medium tracking-tight" }, x)))));
};
