import React from 'react';
import { EstimateCoverNoteTemplateBlock } from '../estimate_cover_note_template_block';
import { updateCompanySettings } from '../../../../code/models/company';
import { SettingsLayout } from '../components/settings_layout';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
import { ToggleInputGroup } from '../../../../components/inputs_and_selections/toggle_input_group';
import { Section } from '../../../../components/containers/section';
export const EstimateResultsPage = ({ company, setCompany }) => {
    return React.createElement(SettingsLayout, { heading: 'Results page', description: 'Customise the content displayed on your estimates' },
        React.createElement(Section, { border: true, title: 'Configuration' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement("p", { className: 'pb-6' }, "Choose which sections you would like to appear in the homeowner-facing estimate."),
                React.createElement("div", { className: 'flex flex-col gap-6' },
                    React.createElement(ToggleInputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Line-by-line cost breakdown', helperText: 'Show the cost of each line item on the estimate' }), toggle: React.createElement(Toggle, { value: company.public_info.show_line_item_costs_on_estimate, setValue: async () => {
                                const newCompany = {
                                    ...company,
                                    public_info: {
                                        ...company.public_info,
                                        show_line_item_costs_on_estimate: !company.public_info.show_line_item_costs_on_estimate
                                    }
                                };
                                setCompany(newCompany);
                                await updateCompanySettings(newCompany);
                            } }) }),
                    React.createElement(ToggleInputGroup, { formLabel: React.createElement(FormLabel, { labelText: '\'Book a survey\' button', helperText: 'Display the \'Book a survey\' button on the estimate' }), toggle: React.createElement(Toggle, { value: !company.public_info.no_survey_booking, setValue: async () => {
                                const newCompany = {
                                    ...company,
                                    public_info: { ...company.public_info, no_survey_booking: !company.public_info.no_survey_booking }
                                };
                                setCompany(newCompany);
                                await updateCompanySettings(newCompany);
                            } }) })))),
        React.createElement(EstimateCoverNoteTemplateBlock, { company: company, setCompany: setCompany }));
};
