import React, { useContext } from 'react';
import { Map, Marker } from '@vis.gl/react-google-maps';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { Header } from '../components/header';
import { Button } from '../../../components/buttons/button';
import { ChevronRightIcon } from '@heroicons/react/16/solid';
import { SurveyActionType } from '../../../code/survey/survey';
export const ConfirmLocationStep = () => {
    var _a, _b;
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    const initialLatLng = { lat: ((_a = survey.lead.property.postcodeLocation) === null || _a === void 0 ? void 0 : _a[0]) || 0, lng: ((_b = survey.lead.property.postcodeLocation) === null || _b === void 0 ? void 0 : _b[1]) || 0 };
    const [latLng, setLatLng] = React.useState({ lat: initialLatLng.lat, lng: initialLatLng.lng });
    const handleOnDragEnd = (event) => {
        var _a, _b;
        setLatLng({ lat: ((_a = event.latLng) === null || _a === void 0 ? void 0 : _a.lat()) || initialLatLng.lat, lng: ((_b = event.latLng) === null || _b === void 0 ? void 0 : _b.lng()) || initialLatLng.lng });
    };
    const handleNavigateNextPage = () => {
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                lead: {
                    ...survey.lead,
                    property: {
                        ...survey.lead.property,
                        postcodeLocation: [latLng.lat, latLng.lng]
                    }
                }
            }
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-col gap-3" },
            React.createElement(Header, { content: "Confirm the location of your property" }),
            React.createElement("div", { className: "text-gray-600" }, "Drag the pin on the map below to the exact location of your property.")),
        React.createElement("div", { className: "space-y-3" }, initialLatLng &&
            React.createElement(Map, { zoom: 18, className: "min-h-[400px] w-full h-full rounded overflow-hidden", mapTypeId: "satellite", center: initialLatLng },
                React.createElement(Marker, { position: latLng.lat === 0 && latLng.lng === 0 ? initialLatLng : latLng, draggable: true, onDragEnd: handleOnDragEnd }))),
        React.createElement(Button, { size: 'LG', onClick: handleNavigateNextPage },
            React.createElement("div", { className: "flex items-center gap-x-2" },
                React.createElement("div", null, "Confirm location"),
                React.createElement(ChevronRightIcon, { className: "h-5 w-5" })))));
};
