import { apiUrl, axiosDelete, axiosPatch, axiosPost, client } from '../axios';
import { RangeHeatPumpSchema } from './range_heat_pump';
import { AuthSDK } from '../utils/auth_provider';
import { z } from 'zod';
export const HeatPumpSchema = z.object({
    uuid: z.string(),
    deleted_at: z.date().nullable(),
    name: z.string(),
    price: z.number(),
    parts: z.number(),
    kwatts: z.number(),
    scopAt50: z.number(),
    range_heat_pump_uuid: z.string().optional(),
    range_heat_pump: RangeHeatPumpSchema
});
export const getHeatPumps = async (companyUUID) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/heat-pumps`, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return result.data.data.map(x => ({ ...x.attributes, uuid: x.uuid, deleted_at: x.attributes.deleted_at ? new Date(x.attributes.deleted_at) : null }));
    }
    catch (e) {
        console.error('Error getting Heat Pumps', e);
        return undefined;
    }
};
export const updateHeatPump = async (heatPump, companyUUID) => {
    await axiosPatch(`teams/${companyUUID}/heat-pumps/${heatPump.uuid}`, {
        name: heatPump.name,
        price: heatPump.price,
        parts: heatPump.parts,
        kwatts: heatPump.kwatts,
        range_heat_pump_uuid: heatPump.range_heat_pump_uuid
    }, heatPump.uuid, 'heat-pumps');
};
export const insertHeatPump = async (heatPump, companyUUID) => {
    return await axiosPost(`teams/${companyUUID}/heat-pumps`, {
        uuid: heatPump.uuid,
        name: heatPump.name,
        price: heatPump.price,
        parts: heatPump.parts,
        kwatts: heatPump.kwatts,
        range_heat_pump_uuid: heatPump.range_heat_pump_uuid
    }, 'heat-pumps');
};
export const deleteHeatPump = async (heatPumpUUID, companyUUID) => {
    await axiosDelete(`teams/${companyUUID}/heat-pumps/${heatPumpUUID}`);
};
