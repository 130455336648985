import React from 'react';
import { SystemDesign } from '../sections/system_design';
import { SimpleLayout } from '../../../../../layouts/simple_layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Heading } from '../../../../../components/content_display/heading';
import { Icon } from '../../../../../components/buttons/icon';
import { CostBreakdown } from '../sections/cost_breakdown';
const CostEstimateEditorHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const handleReturn = () => {
        const baseUrl = location.pathname.split('/').slice(0, -1).join('/');
        navigate(`${baseUrl}/dashboard`);
    };
    return (React.createElement("div", { className: 'flex items-center justify-between' },
        React.createElement("div", { className: 'flex items-center gap-4' },
            React.createElement(Icon, { className: 'w-6 h-6', onClick: handleReturn, icon: faArrowLeft }),
            React.createElement(Heading, { size: 'xl' }, "Edit cost estimate"))));
};
export const CostEstimateEditor = ({ lead, setLead, estimate }) => {
    return (React.createElement("div", { className: 'absolute inset-0 h-screen z-10 overflow-auto' },
        React.createElement(SimpleLayout, { header: React.createElement(CostEstimateEditorHeader, null) },
            React.createElement("div", { className: "space-y-6 p-6" },
                React.createElement(SystemDesign, { lead: lead, setLead: setLead, estimate: estimate }),
                React.createElement(CostBreakdown, { lead: lead, setLead: setLead, estimate: estimate })))));
};
