import React, { useState } from 'react';
import { DesignPageHeader } from '../../design/components/design_page_header';
import { isPreFlowFeatureSurvey } from '../../../../code/models/property';
import { usePagesFlow } from '../../../../code/use_pages_flow';
import { CompleteButton } from '../complete_button';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FlowPageHeader } from '../../design/components/flow_page_header';
import { Button } from '../../../../components/buttons/button';
// is user exits the flow
const FLOW_CANCELLED_VALUE = -1;
// is user finishes the flow. It's a separate value to track stats
const FLOW_FINISHED_VALUE = -2;
export const FlowWrapperContext = React.createContext(undefined);
export function SurveySectionFlowWrapper({ completeSectionID, sectionUIName, pages, flags_attribute_name, onPageShow, onPageComplete, onClose, startPage, survey, setSurvey, setSurveyTab, completionChecks, hideCompleteButton }) {
    var _a;
    const isFinishedOrCancelledFlow = survey[flags_attribute_name] === FLOW_FINISHED_VALUE ||
        survey[flags_attribute_name] === FLOW_CANCELLED_VALUE;
    const [viewMode, setViewMode] = useState((isFinishedOrCancelledFlow || isPreFlowFeatureSurvey(survey)) ? 'FULL' : 'FLOW');
    const [currentPageBitFlag, next, prev] = usePagesFlow({
        flagsCompletedPages: survey[flags_attribute_name],
        startPage,
        onEndOfFlow: () => {
            handleOnFlowFinish();
        },
        onPageCompleted: onPageComplete,
        pages
    });
    const currentPage = pages.find(p => p.flagBit === currentPageBitFlag);
    onPageShow && onPageShow(currentPageBitFlag);
    const handleOnFlowFinish = async () => {
        await setSurvey(prev => ({ ...prev, [flags_attribute_name]: FLOW_FINISHED_VALUE }));
        setViewMode('FULL');
    };
    return React.createElement(FlowWrapperContext.Provider, { value: { viewMode } },
        React.createElement("div", { className: 'flex flex-col h-full' },
            viewMode === 'FULL' &&
                React.createElement(DesignPageHeader, { title: sectionUIName, onBack: () => setSurveyTab('') }),
            viewMode === 'FLOW' &&
                React.createElement(FlowPageHeader, { progress: (_a = currentPage === null || currentPage === void 0 ? void 0 : currentPage.pbValue) !== null && _a !== void 0 ? _a : 0, title: sectionUIName, onBack: () => {
                        // if the first page, exit the section
                        if (currentPageBitFlag === 0x001) {
                            setSurveyTab('');
                        }
                        else {
                            // otherwise, go to the previous page
                            prev();
                        }
                    }, onClose: async () => {
                        await setSurvey(prev => ({ ...prev, [flags_attribute_name]: FLOW_CANCELLED_VALUE }));
                        // if there is a custom onClose function, call it
                        if (onClose) {
                            onClose();
                        }
                        else {
                            setViewMode('FULL');
                        }
                    } }),
            React.createElement("div", { className: "p-5 bg-white flex-col gap-6 flex flex-grow overflow-y-auto" },
                viewMode === 'FLOW' && (currentPage === null || currentPage === void 0 ? void 0 : currentPage.page),
                viewMode === 'FULL' && React.createElement(React.Fragment, null, pages.map((p, idx) => {
                    const shouldHide = p.hideOrSkipPage && p.hideOrSkipPage();
                    return React.createElement(React.Fragment, { key: idx },
                        shouldHide && React.createElement(React.Fragment, null),
                        !shouldHide && React.createElement(React.Fragment, null,
                            idx !== 0 && React.createElement("div", { className: "border border-gray-200" }),
                            p.page));
                }))),
            viewMode === 'FLOW' && React.createElement(React.Fragment, null,
                currentPageBitFlag !== pages[pages.length - 1].flagBit &&
                    React.createElement("div", { className: "p-3 bg-white flex-col gap-3 flex justify-center items-center shadow-2xl" },
                        React.createElement(Button, { block: true, iconRight: faChevronRight, onClick: next, colour: 'DARK' }, "Continue")),
                !hideCompleteButton && completionChecks && completeSectionID && currentPageBitFlag === pages[pages.length - 1].flagBit &&
                    React.createElement(CompleteButton, { page: completeSectionID, survey: survey, setSurvey: setSurvey, pageChecks: completionChecks, onBack: async () => {
                            await handleOnFlowFinish();
                            setSurveyTab('');
                        }, secondaryButton: React.createElement(Button, { className: 'h-full', block: true, onClick: () => {
                                handleOnFlowFinish();
                                setSurveyTab('');
                            }, colour: 'LIGHT' }, "Close section") })),
            !hideCompleteButton && completeSectionID && completionChecks && viewMode === 'FULL' && React.createElement(React.Fragment, null,
                React.createElement(CompleteButton, { page: completeSectionID, survey: survey, setSurvey: setSurvey, pageChecks: completionChecks, onBack: async () => {
                        await handleOnFlowFinish();
                        setSurveyTab('');
                    } }))));
}
