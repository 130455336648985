import { Editor } from '@tiptap/core';
import { TiptapExtensions } from '../components/inputs_and_selections/tiptap';
export function renderJSONReplacingPlaceholders(content, mappings) {
    const html = renderHTMLReplacingPlaceholders(content, mappings);
    // a hack to convert HTML to JSON
    const editor = new Editor({
        extensions: TiptapExtensions,
        content: html
    });
    return editor.getJSON();
}
export function renderHTMLReplacingPlaceholders(content, mappings) {
    let html = '';
    if (mappings === undefined) {
        mappings = {};
    }
    for (const block of content.content) {
        switch (block.type) {
            case 'paragraph':
                if (block.content === undefined) {
                    break;
                }
                html += '<p>';
                for (const cnt of block.content) {
                    // open tags
                    if (cnt.marks) {
                        for (const mark of cnt.marks) {
                            if (mark.type === 'bold') {
                                html += '<strong>';
                            }
                            if (mark.type === 'italic') {
                                html += '<em>';
                            }
                            if (mark.type === 'link') {
                                html += `<a href="${mark.attrs.href}" target="_blank" rel="noopener noreferrer">`;
                            }
                        }
                    }
                    // place content
                    switch (cnt.type) {
                        case 'hardBreak':
                            html += '<br>';
                            break;
                        case 'text':
                            html += cnt.text;
                            break;
                        case 'inline-ph':
                            html += mappings[cnt.attrs.id] || '';
                            break;
                    }
                    // close tags
                    if (cnt.marks) {
                        for (const mark of cnt.marks) {
                            if (mark.type === 'bold') {
                                html += '</strong>';
                            }
                            if (mark.type === 'italic') {
                                html += '</em>';
                            }
                            if (mark.type === 'link') {
                                html += '</a>';
                            }
                        }
                    }
                }
                html += '</p>';
                break;
            case 'button-ph':
                // keep the button as is, because we'll replace it on the backend
                // only add data-href attribute from the mappings
                html += `<button-ph data-href="${mappings[block.attrs.id]}" id="${block.attrs.id}" label="${block.attrs.label}"></button-ph>`;
                break;
        }
    }
    return html;
}
export const TTPlaceholderViewEstimate = {
    id: 'btn-view-estimate',
    type: 'button-ph',
    label: 'View estimate',
    code: '<button-ph id="btn-view-estimate" label="View estimate"></button-ph>'
};
export const TTPlaceholderCompanyName = {
    id: 'ph-company-name',
    type: 'inline-ph',
    label: '{company name}',
    code: '<inline-ph id="ph-company-name" label="{company name}"></inline-ph>'
};
export const TTPlaceholderCompanyPhone = {
    id: 'ph-company-phone',
    type: 'inline-ph',
    label: '{company phone}',
    code: '<inline-ph id="ph-company-phone" label="{company phone}"></inline-ph>'
};
export const TTPlaceholderCustomerName = {
    id: 'ph-customer-name',
    type: 'inline-ph',
    label: '{customer name}',
    code: '<inline-ph id="ph-customer-name" label="{customer name}"></inline-ph>'
};
export const TTPlaceholderCustomerAddress = {
    id: 'ph-customer-address',
    type: 'inline-ph',
    label: '{customer address}',
    code: '<inline-ph id="ph-customer-address" label="{customer address}"></inline-ph>'
};
export const TTPlaceholderHeatPumpModel = {
    id: 'ph-heat-pump-model',
    type: 'inline-ph',
    label: '{heat pump model}',
    code: '<inline-ph id="ph-heat-pump-model" label="{heat pump model}"></inline-ph>'
};
export const TTPlaceholderCarbonSaving = {
    id: 'ph-carbon-saving',
    type: 'inline-ph',
    label: '{carbon saving}',
    code: '<inline-ph id="ph-carbon-saving" label="{carbon saving}"></inline-ph>'
};
export const TTPlaceholderInstallerName = {
    id: 'ph-installer-name',
    type: 'inline-ph',
    label: '{installer name}',
    code: '<inline-ph id="ph-installer-name" label="{installer name}"></inline-ph>'
};
