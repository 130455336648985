import { formatMaterialUValue, getMaterialUValue } from '../../../code/models/material';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import React from 'react';
export const MaterialItemBlock = ({ selectedMaterial, material, onClick, onDelete }) => {
    const uValue = getMaterialUValue(material);
    // TODO: should be able to refactor this to use navCard
    return React.createElement("div", { className: "cursor-pointer self-stretch px-5 justify-center items-center gap-2 inline-flex bg-white" },
        React.createElement("div", { className: "grow shrink basis-0 py-3 border-t border-gray-200 justify-center items-center gap-3 flex" },
            React.createElement("div", { className: "grow shrink basis-0 text-gray-600 text-sm font-semibold", onClick: onClick }, material.name),
            uValue !== undefined && React.createElement(Badge, { color: 'LIGHT', text: formatMaterialUValue(uValue) }),
            (onDelete && material.type !== 'generic' && (selectedMaterial === null || selectedMaterial === void 0 ? void 0 : selectedMaterial.uuid) !== material.uuid) && React.createElement(FontAwesomeIcon, { className: 'h-5 w-5 text-gray-400', icon: faTrashCan, onClick: onDelete })));
};
