import React from 'react';
import { FloorAttributesBlock } from './floor_attributes_block';
export const AddFloor = ({ setPage, onBack, floors, setCurrentFloorId, setSurvey }) => {
    // `setPages` also means that we are in the flow or not
    // if we are in the flow, `setPages` will be not set
    return React.createElement("div", { className: 'flex flex-col' },
        !setPage &&
            React.createElement("div", { className: "flex-col gap-3 flex" },
                React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Add a floor"),
                React.createElement("div", { className: "" }, "Set the properties of the starting floor of the property.")),
        React.createElement("div", { className: `${setPage ? 'p-5' : ''} bg-white flex-col gap-5 flex` },
            React.createElement(FloorAttributesBlock, { floors: floors, onSave: (newFloor) => {
                    setSurvey(prev => ({ ...prev, floors: [...prev.floors, newFloor] }));
                    setCurrentFloorId(newFloor.uuid);
                    if (setPage) {
                        setPage('FLOOR_DETAILS');
                    }
                } })));
};
