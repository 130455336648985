import React, { useState } from 'react';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { Icon } from '../../../../components/buttons/icon';
import { Section } from '../../../../components/containers/section';
import { Button } from '../../../../components/buttons/button';
import { Input } from '../../../../components/inputs_and_selections/input';
import { Select } from '../../../../components/inputs_and_selections/select';
import { getSimproAPICompaniesList, updateCompanySettings } from '../../../../code/models/company';
import { SettingsLayout } from '../components/settings_layout';
export const AccountIntegrations = ({ company, setCompany }) => {
    var _a, _b, _c;
    const [editingCompany, setEditingCompany] = useState();
    const [payacaHelpOpen, setPayacaHelpOpen] = useState(false);
    // const [commusoftHelpOpen, setCommusoftHelpOpen] = useState(false)
    const [simproHelpOpen, setSimproHelpOpen] = useState(false);
    const [simproCompanies, setSimproCompanies] = useState([]);
    const getSimproCompaniesList = async (apiKey, url) => {
        if (!apiKey || !url)
            return [];
        const list = await getSimproAPICompaniesList(company.public_info.uuid, apiKey, url);
        setSimproCompanies(list || []);
    };
    return React.createElement(SettingsLayout, { heading: 'Integrations', description: 'Connect Spruce to your other apps' },
        React.createElement(Section, { border: true, title: 'Your integrations' },
            React.createElement("div", { className: 'flex flex-col gap-4' },
                React.createElement("div", { className: 'space-y-2' },
                    React.createElement("div", { className: 'flex flex-col' },
                        React.createElement("div", { className: 'flex flex-row items-center gap-2' },
                            React.createElement("div", { className: 'font-bold' }, "Payaca Customer ID"),
                            React.createElement(Icon, { onClick: () => setPayacaHelpOpen(prev => { return !prev; }), icon: faCircleQuestion })),
                        React.createElement("div", { className: `${payacaHelpOpen ? '' : 'hidden'} text-sm bg-amber-50 text-gray-600` }, "If you have a Payaca CRM add your customer ID here and we will automatically add new leads to your Payaca system.")),
                    editingCompany &&
                        React.createElement(Input, { value: (_a = editingCompany.payaca_customer_id) !== null && _a !== void 0 ? _a : '', setValue: (e) => setEditingCompany(prev => ({ ...prev, payaca_customer_id: e })) }),
                    !editingCompany &&
                        React.createElement("div", { className: 'text-gray-600' }, !company.payaca_customer_id ? '(not set)' : company.payaca_customer_id)),
                React.createElement("div", { className: 'space-y-2' },
                    React.createElement("div", { className: 'flex flex-col' },
                        React.createElement("div", { className: 'flex flex-row items-center gap-2' },
                            React.createElement("div", { className: 'font-bold' }, "Simpro Integration"),
                            React.createElement(Icon, { onClick: () => setSimproHelpOpen(prev => { return !prev; }), icon: faCircleQuestion })),
                        React.createElement("div", { className: `${simproHelpOpen ? '' : 'hidden'} text-sm bg-amber-50 text-gray-600` }, "If you have a Simpro account add your Spruce integration API key here and select the company in your Simpro Account. We will automatically add new leads to your Simpro system.")),
                    editingCompany && React.createElement(React.Fragment, null,
                        React.createElement(Input, { placeholder: 'API Key', value: (_b = editingCompany.simpro_api_key) !== null && _b !== void 0 ? _b : '', setValue: async (e) => {
                                setEditingCompany(prev => ({
                                    ...prev,
                                    simpro_api_key: e,
                                    simpro_company_id: '',
                                    simpro_company_name: ''
                                }));
                                getSimproCompaniesList(e, editingCompany.simpro_url);
                            } }),
                        React.createElement(Input, { placeholder: 'URL', value: (_c = editingCompany.simpro_url) !== null && _c !== void 0 ? _c : '', setValue: async (e) => {
                                setEditingCompany(prev => ({
                                    ...prev,
                                    simpro_url: e,
                                    simpro_company_id: '',
                                    simpro_company_name: ''
                                }));
                                getSimproCompaniesList(editingCompany.simpro_api_key, e);
                            } }),
                        simproCompanies.length === 0 && React.createElement("div", { className: 'text-gray-600' }, "No companies found for the API key"),
                        simproCompanies.length !== 0 &&
                            React.createElement(Select, { options: simproCompanies, selectedKey: editingCompany.simpro_company_id, setSelectedKey: (e) => {
                                    setEditingCompany(prev => {
                                        var _a, _b;
                                        return ({
                                            ...prev,
                                            simpro_company_id: e,
                                            simpro_company_name: (_b = (_a = simproCompanies.find(x => x.key === e)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : ''
                                        });
                                    });
                                } })),
                    !editingCompany &&
                        React.createElement("div", { className: 'flex flex-col' },
                            React.createElement("div", { className: 'text-gray-600' }, !company.simpro_api_key ? 'API key: (not set)' : company.simpro_api_key),
                            React.createElement("div", { className: 'text-gray-600' }, !company.simpro_url ? 'URL: (not set)' : company.simpro_url),
                            React.createElement("div", { className: 'text-gray-600' }, !company.simpro_company_name ? 'Company ID: (not set)' : company.simpro_company_name + ' (ID=' + company.simpro_company_id + ')')))),
            React.createElement("div", { className: 'flex gap-x-2 pt-8' },
                !editingCompany && React.createElement(Button, { colour: 'LIGHT', size: 'MD', onClick: async () => {
                        setEditingCompany(company);
                    } }, "Edit"),
                editingCompany && React.createElement(React.Fragment, null,
                    React.createElement(Button, { onClick: async () => {
                            await updateCompanySettings(editingCompany);
                            setCompany(editingCompany);
                            setEditingCompany(undefined);
                        } }, "Save"),
                    React.createElement(Button, { colour: 'LIGHT', onClick: () => setEditingCompany(undefined) }, "Cancel")))));
};
