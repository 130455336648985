import React, { useEffect, useState } from 'react';
import { insertCalculatedEstimate } from '../../../../code/models/calculated_estimate';
import { getCompanyEstimateEmailTemplate } from '../../../../code/models/company';
import { renderHTMLReplacingPlaceholders, renderJSONReplacingPlaceholders, TTPlaceholderCarbonSaving, TTPlaceholderCompanyName, TTPlaceholderCompanyPhone, TTPlaceholderCustomerAddress, TTPlaceholderCustomerName, TTPlaceholderHeatPumpModel, TTPlaceholderViewEstimate } from '../../../../code/tiptap_placeholders';
import { numberFormat } from '../../../../code/number_format';
import { formatPrice } from '../../../../code/format_price';
import { Modal } from '../../../../components/containers/modal';
import { logEvent } from '../../../../code/log_event';
import { sendEstimate } from '../../../../code/models/lead';
import { Tiptap } from '../../../../components/inputs_and_selections/tiptap';
import { insertCalculatedEstimateLineItem } from '../../../../code/models/calculated_estimate_line_item';
export const SendEstimateModalBlock = ({ setEstimates, isVisible, setIsVisible, adminContext, calculatedEstimate, lead, setLead }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const [emailTemplateJSON, setEmailTemplateJSON] = useState();
    const [estimateToSend, setEstimateToSend] = useState();
    const insertEstimate = async (setEstimates, companyUUID, estimate, lead) => {
        var _a;
        await insertCalculatedEstimate(estimate, companyUUID);
        for (const lineItem of ((_a = lead.lead_line_items) !== null && _a !== void 0 ? _a : [])) {
            await insertCalculatedEstimateLineItem(({ uuid: crypto.randomUUID(), name: lineItem.name, value: lineItem.value, calculated_estimate_uuid: estimate.uuid }), companyUUID);
        }
        setEstimates(prev => [...prev, estimate]);
    };
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g;
        if (!((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company))
            return;
        const estimateToSend = {
            ...calculatedEstimate,
            uuid: crypto.randomUUID(),
            status: 'Sent',
            created_at: new Date().toString()
        };
        // the email template used to send the estimate
        const companyEstimateEmailTemplate = getCompanyEstimateEmailTemplate(adminContext.data.company.estimate_template);
        // render all placeholders EXCEPT the View Estimate button
        const emailJSON = renderJSONReplacingPlaceholders(companyEstimateEmailTemplate, {
            [TTPlaceholderViewEstimate.id]: '#',
            [TTPlaceholderCustomerName.id]: estimateToSend.name,
            [TTPlaceholderCustomerAddress.id]: `${estimateToSend.address}, ${estimateToSend.postcode}`,
            [TTPlaceholderHeatPumpModel.id]: estimateToSend.heat_pump_name,
            [TTPlaceholderCarbonSaving.id]: numberFormat(0).format(estimateToSend.co2_saved_kg),
            [TTPlaceholderCompanyName.id]: (_d = (_c = (_b = adminContext.data) === null || _b === void 0 ? void 0 : _b.company) === null || _c === void 0 ? void 0 : _c.public_info) === null || _d === void 0 ? void 0 : _d.name,
            [TTPlaceholderCompanyPhone.id]: (_g = (_f = (_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.company) === null || _f === void 0 ? void 0 : _f.public_info) === null || _g === void 0 ? void 0 : _g.phone
        });
        setEmailTemplateJSON(emailJSON);
        setEstimateToSend(estimateToSend);
    }, [adminContext, calculatedEstimate]);
    if (!((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company) || !estimateToSend)
        return;
    const companyUUID = adminContext.data.company.public_info.uuid;
    return React.createElement(Modal, { title: 'Send estimate', visible: isVisible, setVisible: setIsVisible, onConfirm: async () => {
            var _a, _b, _c, _d, _e, _f, _g;
            logEvent({
                name: 'Send Estimate Clicked',
                properties: {}
            }, (_d = (_c = (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.public_info) === null || _c === void 0 ? void 0 : _c.subdomain) !== null && _d !== void 0 ? _d : '');
            await insertEstimate(setEstimates, companyUUID, estimateToSend, lead);
            // render the email template with the View Estimate button
            const emailHTML = renderHTMLReplacingPlaceholders(emailTemplateJSON, {
                [TTPlaceholderViewEstimate.id]: process.env.BASE_URL + ((_g = (_f = (_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.company) === null || _f === void 0 ? void 0 : _f.public_info) === null || _g === void 0 ? void 0 : _g.subdomain) + '/estimate/' + estimateToSend.uuid
            });
            await sendEstimate(lead.uuid, companyUUID, emailHTML);
            setLead(prev => ({ ...prev, status: 'Quoted' }));
        }, confirmButtonLabel: "Send" },
        React.createElement("div", { className: "flex flex-col gap-4" },
            React.createElement("div", null,
                "This will send an estimate of ",
                React.createElement("span", { className: "font-bold" }, formatPrice(estimateToSend.total_price)),
                " to ",
                React.createElement("span", { className: "font-bold" }, estimateToSend.name),
                " at ",
                React.createElement("span", { className: "font-bold" }, (_b = lead === null || lead === void 0 ? void 0 : lead.customer) === null || _b === void 0 ? void 0 : _b.email),
                "."),
            React.createElement("div", { className: "flex flex-col gap-4" },
                React.createElement("div", { className: "text-xl font-medium" }, "Customer email"),
                React.createElement(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                        setEmailTemplateJSON(editor.getJSON());
                    }, placeholders: [
                        TTPlaceholderViewEstimate
                    ], content: emailTemplateJSON }),
                ((_e = (_d = (_c = adminContext.data) === null || _c === void 0 ? void 0 : _c.company) === null || _d === void 0 ? void 0 : _d.public_info) === null || _e === void 0 ? void 0 : _e.logo) && React.createElement("img", { src: (_h = (_g = (_f = adminContext.data) === null || _f === void 0 ? void 0 : _f.company) === null || _g === void 0 ? void 0 : _g.public_info) === null || _h === void 0 ? void 0 : _h.logo, className: "max-w-40", alt: "Installer logo" }))));
};
