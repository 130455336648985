import React from 'react';
import { Input } from '../../../components/inputs_and_selections/input';
import { PhotoScroll } from '../../../components/inputs_and_selections/photo_scroll';
import { validateNotNull, validateTemperatureRange } from '../../../code/validators';
import { TextArea } from '../../../components/inputs_and_selections/text_area';
import { ClickableCard } from '../../../components/content_display/card';
import { ToggleInputGroup } from '../../../components/inputs_and_selections/toggle_input_group';
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
import { Toggle } from '../../../components/inputs_and_selections/toggle';
import { InputGroup } from '../../../components/inputs_and_selections/input_group';
import { Info } from '../../../components/buttons/info';
export const ManifoldDetailPage = ({ manifold, setManifold, files, setFiles, companyUUID }) => {
    var _a, _b;
    const deleteImage = (imageUUID) => {
        setManifold(prev => ({ ...prev, photos: prev.photos.filter(x => x.image_uuid !== imageUUID) }));
        setFiles(prev => prev.map(x => x.uuid === imageUUID ? { ...x, deleted_at: new Date().getTime(), updated_at: new Date().getTime() } : x));
    };
    const images = manifold.photos.map(x => files.find(y => y.uuid === x.image_uuid));
    return React.createElement("div", { className: 'flex flex-col gap-5 p-5 overflow-y-auto' },
        React.createElement("div", { className: 'flex flex-col gap-1' },
            React.createElement("div", { className: 'font-bold text-gray-900' }, "Name"),
            React.createElement(Input, { value: manifold.name, validateImmediately: true, validator: validateNotNull, setValue: (e) => setManifold(prev => ({ ...prev, name: e })) })),
        React.createElement(ClickableCard, { variant: 'GREY' },
            React.createElement("div", { className: 'flex flex-col gap-5' },
                React.createElement(ToggleInputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'UFH temp is lower than the system temp', helperText: 'I.e. there is a mixing valve on the UFH', info: React.createElement(Info, { infoModalHeader: 'UFH temp is lower than the system temp', infoModalBody: 'If a mixing valve is present at the UFH manifold to limit the temperature of the water going through the UFH system,' +
                                ' enter that temperature limit here. If you modify the system temperature to fall below this limit we will then assume the mixing valve' +
                                ' no longer functions and the UFH is at the same temp as the rest of the system. ' }) }), toggle: React.createElement(Toggle, { value: manifold.ufh_temp_differs_from_system_temp, setValue: () => setManifold(prev => ({ ...prev, ufh_temp_differs_from_system_temp: !prev.ufh_temp_differs_from_system_temp })) }) }),
                manifold.ufh_temp_differs_from_system_temp &&
                    React.createElement(InputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Mean UFH temperature', helperText: 'The average temperature of the water in the UFH system' }), input: React.createElement(Input, { type: 'number', postfix: '\u00B0C', step: 5, value: (_a = manifold.max_mean_water_temp_c) !== null && _a !== void 0 ? _a : '', setValue: (e) => setManifold(prev => ({ ...prev, max_mean_water_temp_c: parseFloat(e) })), validator: (e) => validateTemperatureRange(e, 30, 55), validateImmediately: true }) }))),
        React.createElement("div", { className: 'flex flex-col gap-1' },
            React.createElement("div", { className: 'font-bold text-gray-900' }, "Number of loops"),
            React.createElement(Input, { type: 'number', value: (_b = manifold.number_of_loops) !== null && _b !== void 0 ? _b : '', setValue: (e) => setManifold(prev => ({ ...prev, number_of_loops: parseFloat(e) })) })),
        React.createElement("div", { className: 'flex flex-col gap-1' },
            React.createElement("div", { className: 'font-bold text-gray-900' }, "Location description"),
            React.createElement(TextArea, { value: manifold.location_description, setValue: (e) => setManifold(prev => ({ ...prev, location_description: e })) })),
        React.createElement("div", { className: 'flex flex-col gap-1' },
            React.createElement("div", { className: 'font-bold text-gray-900' }, "Photos"),
            React.createElement(PhotoScroll, { images: images, addImage: (fileWrapper) => {
                    fileWrapper && setFiles([...files, fileWrapper]);
                    fileWrapper && setManifold(prev => ({
                        ...prev,
                        photos: [...prev.photos, {
                                uuid: crypto.randomUUID(),
                                entity_uuid: prev.uuid,
                                image_uuid: fileWrapper.uuid
                            }]
                    }));
                }, deleteImage: deleteImage, companyUUID: companyUUID })));
};
