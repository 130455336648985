import React from 'react';
import { Section } from '../section';
import { Text } from '../../../../../components/content_display/text';
import { formatPrice } from '../../../../../code/format_price';
import { SummaryTable } from '../../../../../components/content_display/summary_table';
import { Button } from '../../../../../components/buttons/button';
import { faExclamationTriangle, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { Alert } from '../../../../../components/indicators_and_messaging/alert';
import { Badge } from '../../../../../components/indicators_and_messaging/badge';
export const CostEstimate = ({ lead, estimate }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const navigate = useNavigate();
    const companyPublicInfo = useRouteLoaderData('company_root');
    const systemDesignRows = [
        { key: 'Heat pump', value: ((_a = estimate.heatPump) === null || _a === void 0 ? void 0 : _a.name) || React.createElement(Badge, { color: "RED", text: "No heat pump selected", icon: faExclamationTriangle }) },
        { key: 'Cylinder', value: ((_b = estimate.hotWaterCylinder) === null || _b === void 0 ? void 0 : _b.name) || React.createElement(Badge, { color: "RED", text: "No hot water cylinder selected", icon: faExclamationTriangle }) },
        { key: 'Flow temperature (°C)', value: estimate.flowTempC },
        // { key: 'Radiator changes', value: estimate.radiator_changes },
        { key: `SCOP at ${estimate.flowTempC}°C`, value: estimate.sCOP }
    ];
    const costBreakdownRows = [
        { key: ((_c = estimate.heatPump) === null || _c === void 0 ? void 0 : _c.name) || React.createElement(Badge, { color: "RED", text: "No heat pump selected", icon: faExclamationTriangle }), value: formatPrice((_d = estimate.heatPump) === null || _d === void 0 ? void 0 : _d.price) },
        { key: ((_e = estimate.hotWaterCylinder) === null || _e === void 0 ? void 0 : _e.name) || React.createElement(Badge, { color: "RED", text: "No hot water cylinder selected", icon: faExclamationTriangle }), value: formatPrice((_f = estimate.hotWaterCylinder) === null || _f === void 0 ? void 0 : _f.price) },
        { key: 'Parts & fittings', value: formatPrice((_g = estimate.heatPump) === null || _g === void 0 ? void 0 : _g.parts) },
        { key: 'Labour', value: formatPrice(estimate.labour) },
        { key: 'Survey', value: formatPrice(estimate.survey) },
        { key: 'Additional costs', value: formatPrice(estimate.totalLineItems) },
        !estimate.isScottish && { key: 'Grant', value: formatPrice(estimate.grant) }
    ].filter(r => Boolean(r));
    const handleEditCostEstimate = () => {
        const url = `/${companyPublicInfo.subdomain}/admin/quotes/${lead.uuid}/cost-estimate`;
        navigate(url);
    };
    return (React.createElement(Section, { title: "Cost estimate", controls: React.createElement(Button, { size: "SM", iconLeft: faPenToSquare, colour: 'LIGHT', onClick: handleEditCostEstimate }, "Edit cost estimate") },
        React.createElement("div", { className: 'flex flex-col gap-5' },
            React.createElement("div", { className: 'flex items-center justify-between' },
                React.createElement(Text, { bold: true }, "Estimate total"),
                React.createElement(Text, { bold: true }, formatPrice(estimate.totalPrice))),
            React.createElement("div", null,
                React.createElement(Text, { bold: true, className: 'mb-1' }, "System design"),
                React.createElement(SummaryTable, { rows: systemDesignRows })),
            React.createElement("div", null,
                React.createElement(Text, { bold: true, className: 'mb-1' }, "Cost breakdown"),
                React.createElement(SummaryTable, { rows: costBreakdownRows }),
                (estimate.isScottish && lead.scottish_rural) && React.createElement(Alert, { className: "mt-3", type: 'SUCCESS' }, "This property is likely to be eligible for the HES rural uplift.")))));
};
