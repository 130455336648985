import React from 'react';
import { EstimatePageInner } from '../../../../estimate_page';
import { estimateAndLeadToCalculatedEstimate } from '../../../../../code/models/calculated_estimate';
import { Section } from '../section';
import { Alert } from '../../../../../components/indicators_and_messaging/alert';
import { useRouteLoaderData } from 'react-router-dom';
export const EstimatePreview = ({ estimate, lead }) => {
    var _a;
    const companyPublicInfo = useRouteLoaderData('company_root');
    const calculatedEstimate = estimateAndLeadToCalculatedEstimate(estimate, lead);
    const notGrantEligible = (!lead.epc_scotland && (!((_a = lead.epcData) === null || _a === void 0 ? void 0 : _a.lmkKey) || estimate.busGrantEligibleReasons.some(x => !x.hasPassed)));
    return (React.createElement(Section, { title: "Estimate preview" },
        React.createElement(Alert, { type: "INFO" }, "This is a preview of the estimate that the customer will receive by email."),
        React.createElement("div", { className: "border border-light rounded overflow-hidden" },
            React.createElement(EstimatePageInner, { estimate: calculatedEstimate, companyPublicInfo: companyPublicInfo, isScottish: estimate.isScottish, grantEligible: !notGrantEligible, isPreview: true }))));
};
