import { CAVITY_WALL } from './u_value';
export const SURVEY_AGE_BANDS = [
    {
        id: '-1990',
        name: 'before 1900',
        min_year: undefined,
        max_year: 1899,
        jurisdiction: 'England and Wales',
        ach_age_key: 'pre_2000'
    },
    {
        id: '1990-1929',
        name: '1900-1929',
        min_year: 1900,
        max_year: 1929,
        jurisdiction: 'England and Wales',
        ach_age_key: 'pre_2000'
    },
    {
        id: '1930-1949',
        name: '1930-1949',
        min_year: 1930,
        max_year: 1949,
        jurisdiction: 'England and Wales',
        ach_age_key: 'pre_2000'
    },
    {
        id: '1950-1966',
        name: '1950-1966',
        min_year: 1950,
        max_year: 1966,
        jurisdiction: 'England and Wales',
        ach_age_key: 'pre_2000'
    },
    {
        id: '1967-1975',
        name: '1967-1975',
        min_year: 1967,
        max_year: 1975,
        jurisdiction: 'England and Wales',
        ach_age_key: 'pre_2000'
    },
    {
        id: '1976-1982',
        name: '1976-1982',
        min_year: 1976,
        max_year: 1982,
        jurisdiction: 'England and Wales',
        ach_age_key: 'pre_2000'
    },
    {
        id: '1983-1990',
        name: '1983-1990',
        min_year: 1983,
        max_year: 1990,
        jurisdiction: 'England and Wales',
        ach_age_key: 'pre_2000'
    },
    {
        id: '1991-1995',
        name: '1991-1995',
        min_year: 1991,
        max_year: 1995,
        jurisdiction: 'England and Wales',
        ach_age_key: 'pre_2000'
    },
    {
        id: '1996-2002',
        name: '1996-2002',
        min_year: 1996,
        max_year: 2002,
        jurisdiction: 'England and Wales',
        ach_age_key: 'pre_2000'
    },
    {
        id: '2003-2006',
        name: '2003-2006',
        min_year: 2003,
        max_year: 2006,
        jurisdiction: 'England and Wales',
        ach_age_key: 'ach_2000_to_2006'
    },
    {
        id: '2007-2011',
        name: '2007-2011',
        min_year: 2007,
        max_year: 2011,
        jurisdiction: 'England and Wales',
        ach_age_key: 'ach_post_2006'
    },
    {
        id: '2012-2022',
        name: '2012-2022',
        min_year: 2012,
        max_year: 2022,
        jurisdiction: 'England and Wales',
        ach_age_key: 'ach_post_2006'
    },
    {
        id: '2023-',
        name: '2023 onwards',
        min_year: 2023,
        max_year: undefined,
        jurisdiction: 'England and Wales',
        ach_age_key: 'ach_post_2006'
    }
];
export const ESTIMATE_AGE_BANDS = [
    {
        uuid: '1',
        startYear: 0,
        endYear: 1899,
        name: 'Before 1900',
        wallGroup: 'Solid Stone',
        floorType: 'None',
        windowType: 'Single',
        loftInsulation: '100mm'
    },
    {
        uuid: '2',
        startYear: 1900,
        endYear: 1929,
        name: '1900-1929',
        wallGroup: 'Solid Brick',
        floorType: 'None',
        windowType: 'Single',
        loftInsulation: '100mm'
    },
    {
        uuid: '3',
        startYear: 1930,
        endYear: 1975,
        name: '1930-1975',
        wallGroup: CAVITY_WALL,
        wallType: 'Uninsulated',
        floorType: 'None',
        windowType: 'Double',
        loftInsulation: '100mm'
    },
    {
        uuid: '4',
        startYear: 1976,
        endYear: 1995,
        name: '1976-1995',
        wallGroup: CAVITY_WALL,
        wallType: 'Uninsulated',
        floorType: 'None',
        windowType: 'Double',
        loftInsulation: '100mm'
    },
    {
        uuid: '5',
        startYear: 1996,
        endYear: 2006,
        name: '1996-2006',
        wallGroup: CAVITY_WALL,
        wallType: 'Insulated',
        floorType: '100mm',
        windowType: 'Double',
        loftInsulation: '200mm'
    },
    {
        uuid: '6',
        startYear: 2007,
        endYear: 9999,
        name: '2007 or later',
        wallGroup: CAVITY_WALL,
        wallType: 'Insulated',
        floorType: '100mm',
        windowType: 'Double',
        loftInsulation: '250+mm'
    }
];
export const parseConstructionAgeBand = (constructionAge) => {
    var _a;
    if (!constructionAge)
        return undefined;
    const age = (_a = /\b\d{4}\b/.exec(constructionAge)) === null || _a === void 0 ? void 0 : _a[0];
    if (!age)
        return undefined;
    const ageAsNumber = Number(age);
    return ESTIMATE_AGE_BANDS.find(x => ageAsNumber >= x.startYear && ageAsNumber <= x.endYear);
};
export const estimateAgeBandToSurveyAgeBand = (lead) => {
    var _a, _b, _c;
    // if Lead has EPC
    // TODO later: don't think this will work for Scottish EPCs
    if (lead.epcData) {
        //  for RETROFIT builds: try constructionAgeBand
        if ((_a = lead.epcData) === null || _a === void 0 ? void 0 : _a.constructionAgeBand) {
            // the regex to test:  https://rubular.com/r/vfvDZPBqlQekWh
            const epcParse = /([a-z ]+): *([0-9]{4}|before)[- ]([0-9]{4}|onwards)/i.exec((_b = lead.epcData) === null || _b === void 0 ? void 0 : _b.constructionAgeBand);
            if (epcParse) {
                const jurisdiction = epcParse[1];
                const startYear = epcParse[2] === 'before' ? undefined : Number(epcParse[2]);
                const endYear = epcParse[3] === 'onwards' ? undefined : Number(epcParse[3]);
                // manual correction for "before 1900" cases:
                // the SURVEY_AGE_BANDS has 0-1899 only, so if min_year is undefined (i.e. == 'before'), make max_year=1899
                if (startYear === undefined && endYear === 1900) {
                    const ab = SURVEY_AGE_BANDS.find(x => x.min_year === undefined && x.max_year === 1899 && x.jurisdiction === jurisdiction);
                    if (ab)
                        return ab;
                }
                // manual correction for "XXXX-onwards" cases:
                // if max_year is undefined (i.e. == 'onwards'), lookup by min_year only
                if (typeof startYear === 'number' && endYear === undefined) {
                    const ab = SURVEY_AGE_BANDS.find(x => x.min_year === startYear && x.jurisdiction === jurisdiction);
                    if (ab)
                        return ab;
                }
                // all other cases: find the matching age band
                const ab = SURVEY_AGE_BANDS.find(x => x.min_year === startYear && x.max_year === endYear && x.jurisdiction === jurisdiction);
                if (ab)
                    return ab;
            }
        }
        // for NEW BUILDINGS: check the inspectionDate
        if (((_c = lead.epcData.transactionType) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === 'new dwelling' && lead.epcData.inspectionDate) {
            const inspectionDate = new Date(lead.epcData.inspectionDate).getFullYear();
            // iterate over the SURVEY_AGE_BANDS and find the one that matches the inspectionDate
            // FIXME: no jurisdiction used in this mapping
            const ab = SURVEY_AGE_BANDS.find(x => {
                if (x.min_year === undefined && x.max_year === 1899)
                    return inspectionDate < 1900;
                if (x.min_year && x.max_year && inspectionDate >= x.min_year && inspectionDate <= x.max_year)
                    return true;
                if (x.min_year && x.max_year === undefined && inspectionDate >= x.min_year)
                    return true;
                return false;
            });
            if (ab)
                return ab;
        }
    }
    else {
        // if no successful EPC age band found, map the user selected age band from the estimate age band
        // Note we are choosing from a set list of age band here so this code only works with them - it's not generic to any year
        if (lead.property.construction_age_band) {
            const estStartYear = lead.property.construction_age_band.startYear;
            const estEndYear = lead.property.construction_age_band.endYear;
            // the mapping used: https://share.cleanshot.com/NzXvv265
            // TODO: no jurisdiction used in this mapping
            if (estEndYear === 1899) {
                return SURVEY_AGE_BANDS.find(x => x.min_year === undefined && x.max_year === 1899);
            }
            if (estStartYear === 1900 && estEndYear === 1929) {
                return SURVEY_AGE_BANDS.find(x => x.min_year === 1900 && x.max_year === 1929);
            }
            if (estStartYear === 1930 && estEndYear === 1975) {
                return SURVEY_AGE_BANDS.find(x => x.min_year === 1950 && x.max_year === 1966);
            }
            if (estStartYear === 1976 && estEndYear === 1995) {
                return SURVEY_AGE_BANDS.find(x => x.min_year === 1983 && x.max_year === 1990);
            }
            if (estStartYear === 1996 && estEndYear === 2006) {
                return SURVEY_AGE_BANDS.find(x => x.min_year === 1996 && x.max_year === 2002);
            }
            if (estStartYear === 2007) {
                return SURVEY_AGE_BANDS.find(x => x.min_year === 2007 && x.max_year === 2011);
            }
        }
    }
    // TODO: this is probably should never happen, raise an error
    return undefined;
};
export const FLOOR_AREA_AGE_BANDS = [
    { id: 'pre_1919', min_year: 0, max_year: 1919, estimate_age_bands_uuids: ['1'], survey_age_bands_ids: ['-1990', '1990-1929'] },
    { id: '1919_1944', min_year: 1919, max_year: 1944, estimate_age_bands_uuids: ['2'], survey_age_bands_ids: ['1930-1949'] },
    { id: '1945_1964', min_year: 1945, max_year: 1964, estimate_age_bands_uuids: ['3'], survey_age_bands_ids: ['1950-1966'] },
    { id: '1965_1980', min_year: 1965, max_year: 1980, estimate_age_bands_uuids: [], survey_age_bands_ids: ['1967-1975', '1976-1982'] },
    { id: '1981_1990', min_year: 1981, max_year: 1990, estimate_age_bands_uuids: ['4'], survey_age_bands_ids: ['1983-1990'] },
    { id: '1991_2002', min_year: 1991, max_year: 2000, estimate_age_bands_uuids: ['5'], survey_age_bands_ids: ['1991-1995', '1996-2002'] },
    { id: 'post_2002', min_year: 2002, estimate_age_bands_uuids: ['6'], survey_age_bands_ids: ['2003-2006', '2007-2011', '2012-2022', '2023-'] }
];
export const estimateAgeBandToFloorAreaAgeBand = (estimateAgeBand) => {
    if (!estimateAgeBand)
        return undefined;
    return FLOOR_AREA_AGE_BANDS.find(x => { var _a; return (_a = x.estimate_age_bands_uuids) === null || _a === void 0 ? void 0 : _a.includes(estimateAgeBand.uuid); });
};
export const surveyAgeBandToFloorAreaAgeBand = (surveyAgeBand) => {
    if (!surveyAgeBand)
        return undefined;
    return FLOOR_AREA_AGE_BANDS.find(x => { var _a; return (_a = x.survey_age_bands_ids) === null || _a === void 0 ? void 0 : _a.includes(surveyAgeBand.id); });
};
