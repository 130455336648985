import { axiosDeleteV2, axiosGetV2, axiosPatchV2, axiosPostV2 } from '../axios';
export const estimateAndLeadToCalculatedEstimate = (estimate, lead) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    return ({
        name: lead.customer.name,
        address: lead.property.address,
        postcode: lead.property.postcode,
        total_watts: estimate.totalWatts,
        range_heat_pump_uuid: (_c = (_b = (_a = estimate.heatPump) === null || _a === void 0 ? void 0 : _a.range_heat_pump) === null || _b === void 0 ? void 0 : _b.uuid) !== null && _c !== void 0 ? _c : '',
        range_heat_pump: (_d = estimate.heatPump) === null || _d === void 0 ? void 0 : _d.range_heat_pump,
        heat_pump_name: (_f = (_e = estimate.heatPump) === null || _e === void 0 ? void 0 : _e.name) !== null && _f !== void 0 ? _f : '',
        heat_pump_price: (_h = (_g = estimate.heatPump) === null || _g === void 0 ? void 0 : _g.price) !== null && _h !== void 0 ? _h : 0,
        range_hot_water_cylinder_uuid: (_k = (_j = estimate.hotWaterCylinder) === null || _j === void 0 ? void 0 : _j.uuid) !== null && _k !== void 0 ? _k : '',
        range_hot_water_cylinder: estimate.hotWaterCylinder,
        hot_water_cylinder_name: (_m = (_l = estimate.hotWaterCylinder) === null || _l === void 0 ? void 0 : _l.name) !== null && _m !== void 0 ? _m : '',
        hot_water_cylinder_price: (_p = (_o = estimate.hotWaterCylinder) === null || _o === void 0 ? void 0 : _o.price) !== null && _p !== void 0 ? _p : 0,
        heat_pump_parts: (_r = (_q = estimate.heatPump) === null || _q === void 0 ? void 0 : _q.parts) !== null && _r !== void 0 ? _r : 0,
        calculated_estimate_line_items: (_s = lead.lead_line_items) === null || _s === void 0 ? void 0 : _s.map(x => ({
            uuid: '',
            name: x.name,
            value: x.value,
            calculated_estimate_uuid: ''
        })),
        labour: estimate.labour,
        survey: estimate.survey,
        grant: estimate.grant,
        total_price: estimate.totalPrice,
        co2_saved_kg: estimate.CO2SavedKg,
        scop: estimate.sCOP,
        flow_temperature_c: estimate.flowTempC,
        lead_uuid: lead.uuid,
        commutesSaved: estimate.commutesSaved,
        flightsSaved: estimate.flightsSaved,
        lmk_key: (_u = (_t = lead.epcData) === null || _t === void 0 ? void 0 : _t.lmkKey) !== null && _u !== void 0 ? _u : '',
        status: undefined
    });
};
export const getCalculatedEstimate = async (calculatedEstimateId, companyUUID) => await axiosGetV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimateId}`);
export const getCalculatedEstimates = async (leadUUID, companyUUID) => await axiosGetV2(`teams/${companyUUID}/leads/${leadUUID}/calculated_estimates`);
export const insertCalculatedEstimate = async (calculatedEstimate, companyUUID) => {
    await axiosPostV2(`teams/${companyUUID}/calculated_estimates`, calculatedEstimate);
};
export const updateCalculatedEstimate = async (calculatedEstimate, companyUUID) => {
    await axiosPatchV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimate.uuid}`, calculatedEstimate);
};
export const deleteCalculatedEstimate = async (calculatedEstimateId, companyUUID) => { await axiosDeleteV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimateId}`); };
export const sendCallback = async (calculatedEstimateId, companyUUID, discussionContent, callTime) => await axiosPostV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimateId}/callback`, { discussion_content: discussionContent, call_time: callTime });
export const sendSurvey = async (calculatedEstimate, companyUUID, emailHTML) => {
    await axiosPostV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimate.uuid}/survey`, { email_html: emailHTML });
    await updateCalculatedEstimate({ ...calculatedEstimate, status: 'Accepted' }, companyUUID);
};
export const sendRejected = async (calculatedEstimate, companyUUID, reason) => {
    await axiosPostV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimate.uuid}/not_interested`, { reason });
    await updateCalculatedEstimate({ ...calculatedEstimate, status: 'Rejected' }, companyUUID);
};
