import React, { useState } from 'react';
import { Section } from '../section';
import { InfoCard } from '../info_card';
import { convertLatLngListToLatLongLiteral } from '../../../../../code/geocoding';
import { Alert } from '../../../../../components/indicators_and_messaging/alert';
import { Map, Marker } from '@vis.gl/react-google-maps';
import { Heading } from '../../../../../components/content_display/heading';
import { SummaryTable } from '../../../../../components/content_display/summary_table';
import { ESTIMATE_AGE_BANDS } from '../../../../../code/models/age_bands';
import { contextSensitiveFormatDate, formatDate } from '../../../../../code/format_date';
import { Modal } from '../../../../../components/containers/modal';
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/16/solid';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../../../components/buttons/button';
export const EnquiryOverview = ({ lead, estimate: { isScottish, busGrantEligibleReasons, hesGrantEligibleReasons } }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
    const navigate = useNavigate();
    const companyPublicInfo = useRouteLoaderData('company_root');
    const latLng = convertLatLngListToLatLongLiteral(lead.property.postcodeLocation);
    const [hesModalVisible, setHesModalVisible] = useState(false);
    const [busModalVisible, setBusModalVisible] = useState(false);
    const notBusGrantEligible = (!lead.epc_scotland && (!((_a = lead.epcData) === null || _a === void 0 ? void 0 : _a.lmkKey) || busGrantEligibleReasons.some(x => !x.hasPassed)));
    const notHesGrantEligible = isScottish && hesGrantEligibleReasons.some(x => !x.hasPassed);
    const handleEditEnquiryData = () => {
        const url = `/${companyPublicInfo.subdomain}/admin/quotes/${lead.uuid}/enquiry-data`;
        navigate(url);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Section, { title: "Enquiry overview", controls: React.createElement(Button, { size: "SM", iconLeft: faPenToSquare, colour: 'LIGHT', onClick: handleEditEnquiryData }, "Edit enquiry data") },
            React.createElement("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-5" },
                React.createElement(InfoCard, { title: "Received", value: contextSensitiveFormatDate(new Date(lead.createdAt)) }),
                !isScottish && React.createElement(InfoCard, { title: "BUS grant eligibility", value: notBusGrantEligible ? 'Not eligible' : 'Eligible', onClick: () => setBusModalVisible(true) }),
                isScottish && React.createElement(InfoCard, { title: "HES grant eligibility", value: notHesGrantEligible ? 'Not eligible' : 'Eligible', onClick: () => setHesModalVisible(true) })),
            React.createElement("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-5" },
                React.createElement("div", { className: "flex flex-col cursor-pointer", onClick: handleEditEnquiryData },
                    React.createElement(Heading, { size: "md" }, "Contact details"),
                    React.createElement(SummaryTable, { rows: [
                            { key: 'Name', value: ((_b = lead.customer) === null || _b === void 0 ? void 0 : _b.name) || 'N/A' },
                            { key: 'Email', value: ((_c = lead.customer) === null || _c === void 0 ? void 0 : _c.email) || 'N/A' },
                            { key: 'Phone', value: ((_d = lead.customer) === null || _d === void 0 ? void 0 : _d.phone) || 'N/A' }
                        ] }),
                    React.createElement(Heading, { size: "md", className: "mt-5" }, "Property details"),
                    React.createElement(SummaryTable, { rows: [
                            { key: 'Property type', value: [(_f = (_e = lead.property.houseOverrides) === null || _e === void 0 ? void 0 : _e.propertyType) !== null && _f !== void 0 ? _f : lead.property.propertyType, (_h = (_g = lead.property.houseOverrides) === null || _g === void 0 ? void 0 : _g.builtForm) !== null && _h !== void 0 ? _h : lead.property.builtForm].join(', ') || 'N/A' },
                            { key: 'Built', value: ((_j = lead.property.houseOverrides) === null || _j === void 0 ? void 0 : _j.construction_age_band_uuid) ? (_k = ESTIMATE_AGE_BANDS.find(x => { var _a; return x.uuid === ((_a = lead.property.houseOverrides) === null || _a === void 0 ? void 0 : _a.construction_age_band_uuid); })) === null || _k === void 0 ? void 0 : _k.name : ((_l = ESTIMATE_AGE_BANDS.find(x => x.uuid === lead.property.construction_age_band_uuid)) === null || _l === void 0 ? void 0 : _l.name) || 'N/A' },
                            { key: 'Walls', value: [(_o = (_m = lead.property.houseOverrides) === null || _m === void 0 ? void 0 : _m.wallGroup) !== null && _o !== void 0 ? _o : lead.property.wallGroup, (_q = (_p = lead.property.houseOverrides) === null || _p === void 0 ? void 0 : _p.wallType) !== null && _q !== void 0 ? _q : lead.property.wallType].join(', ') || 'N/A' },
                            { key: 'Windows', value: (_s = (_r = lead.property.houseOverrides) === null || _r === void 0 ? void 0 : _r.windowType) !== null && _s !== void 0 ? _s : (lead.property.windowType || 'N/A') },
                            { key: 'Bedrooms', value: (_u = (_t = lead.property.houseOverrides) === null || _t === void 0 ? void 0 : _t.noBedrooms) !== null && _u !== void 0 ? _u : (lead.property.noBedrooms || 'N/A') },
                            { key: 'Bathrooms', value: (_w = (_v = lead.property.houseOverrides) === null || _v === void 0 ? void 0 : _v.noBathrooms) !== null && _w !== void 0 ? _w : (lead.property.noBathrooms || 'N/A') },
                            { key: 'Floor area', value: React.createElement("span", null, (_y = (_x = lead.property.houseOverrides) === null || _x === void 0 ? void 0 : _x.floorArea) !== null && _y !== void 0 ? _y : (lead.property.floorArea || 'N/A'),
                                    " m",
                                    React.createElement("sup", null, "2")) }
                        ] })),
                React.createElement("div", null, latLng
                    ? React.createElement(React.Fragment, null,
                        React.createElement(Map, { zoom: 18, className: "min-h-[420px] w-full h-full rounded overflow-hidden", mapTypeId: "satellite", center: latLng },
                            React.createElement(Marker, { position: latLng })))
                    : React.createElement(Alert, { type: "WARNING" }, "Sorry, Google Maps can't find this address.")))),
        React.createElement(Modal, { title: 'BUS grant eligibility', visible: busModalVisible, setVisible: setBusModalVisible },
            React.createElement("div", { className: 'flex flex-col gap-4' },
                React.createElement("div", { className: 'space-y-2' },
                    React.createElement("div", { className: 'text-gray-900 font-semibold' },
                        "This property is ",
                        notBusGrantEligible ? 'not currently' : 'likely to be',
                        " eligible for a BUS grant."),
                    React.createElement("div", { className: 'text-gray-600 text-sm' },
                        lead.epcData ? React.createElement(React.Fragment, null,
                            "The latest EPC we have access to was lodged on ",
                            React.createElement("span", { className: 'font-bold' }, formatDate(lead.epcData.inspectionDate.toString())),
                            ".") : '',
                        " Please note that EPC data only updates monthly, typically during the final working week of the month.")),
                ((_z = lead.epcData) === null || _z === void 0 ? void 0 : _z.lmkKey)
                    ? React.createElement("div", null,
                        React.createElement("div", { className: 'space-y-2 text-gray-600' }, busGrantEligibleReasons.filter(x => !['cavity_wall', 'loft_insulation'].includes(x.id)).map((x, i) => React.createElement("div", { key: i, className: 'flex gap-x-2' },
                            x.warning ? React.createElement(ExclamationCircleIcon, { className: 'w-6 h-6 fill-yellow-500' }) : x.hasPassed ? React.createElement(CheckCircleIcon, { className: 'w-6 h-6 fill-green-500' })
                                : React.createElement(XCircleIcon, { className: 'w-6 h-6 fill-red-500' }),
                            React.createElement("div", null, x.message)))),
                        React.createElement("div", { className: 'mt-5 flex flex-col gap-2' },
                            React.createElement("div", { className: 'text-gray-900 font-semibold mt-5' }, "Energy efficiency opportunities"),
                            React.createElement("div", { className: 'text-gray-600 text-sm' }, "On the 8th May 2024 the requirement to have no outstanding recommendations for loft or cavity wall insulation was removed. We continue to flag them here for your reference only."),
                            React.createElement("div", { className: 'space-y-2 text-gray-600 mt-2' }, busGrantEligibleReasons.filter(x => ['cavity_wall', 'loft_insulation'].includes(x.id)).map((x, i) => React.createElement("div", { key: i, className: 'flex gap-x-2' },
                                x.warning ? React.createElement(ExclamationCircleIcon, { className: 'w-6 h-6 fill-yellow-500' }) : x.hasPassed ? React.createElement(CheckCircleIcon, { className: 'w-6 h-6 fill-green-500' })
                                    : React.createElement(XCircleIcon, { className: 'w-6 h-6 fill-red-500' }),
                                React.createElement("div", null, x.message))))))
                    : React.createElement(Alert, { type: "WARNING" }, "No EPC found for this address. The property will need an EPC before it can be assessed for BUS grant eligibility."))),
        React.createElement(Modal, { title: 'HES grant eligibility', visible: hesModalVisible, setVisible: setHesModalVisible },
            React.createElement("div", { className: 'flex flex-col gap-4' },
                !(lead === null || lead === void 0 ? void 0 : lead.epc_scotland) &&
                    React.createElement("div", { className: 'flex flex-col gap-8' },
                        React.createElement("div", { className: 'space-y-1' },
                            React.createElement("div", { className: 'text-gray-900 font-semibold' }, "This property cannot be assessed for a HES grant."),
                            React.createElement("div", { className: 'text-gray-600 text-sm' }, "Please note that EPC data only updates quarterly.")),
                        React.createElement(Alert, { type: "WARNING" }, "No EPC found for this address so we cannot assess whether loft insulation or cavity wall insulation would be recommended in the post-install EPC.")),
                (lead === null || lead === void 0 ? void 0 : lead.epc_scotland) &&
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'space-y-1' },
                            React.createElement("div", { className: 'text-gray-900 font-semibold' },
                                "This property ",
                                notHesGrantEligible ? 'is unlikely to be' : 'may be',
                                " eligible for a HES grant."),
                            React.createElement("div", { className: 'text-gray-600 text-sm' },
                                lead.epc_scotland ? React.createElement(React.Fragment, null,
                                    "Data from the EPC lodged on ",
                                    React.createElement("span", { className: 'font-bold' }, formatDate(lead.epc_scotland.inspection_date.toString())),
                                    ".") : '',
                                " Please note that EPC data only updates quarterly.")),
                        ((_0 = lead === null || lead === void 0 ? void 0 : lead.epc_scotland) === null || _0 === void 0 ? void 0 : _0.improvements)
                            ? React.createElement("div", { className: 'space-y-2 text-gray-600' }, hesGrantEligibleReasons.map((x, i) => React.createElement("div", { key: i, className: 'flex gap-x-2' },
                                x.hasPassed ? React.createElement(CheckCircleIcon, { className: 'w-6 h-6 fill-green-500' })
                                    : React.createElement(XCircleIcon, { className: 'w-6 h-6 fill-red-500' }),
                                React.createElement("div", null, x.message))))
                            : React.createElement(Alert, { type: "WARNING" }, "No EPC found for this address. The property will need an EPC before it can be assessed for HES grant eligibility."))))));
};
