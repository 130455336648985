import React from 'react';
import { Text } from '../../../../components/content_display/text';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../../../../components/buttons/icon';
export const InfoCard = ({ title, value, onClick }) => {
    return (React.createElement("div", { className: `px-4 py-3 bg-gray-100 rounded-md ${onClick && 'cursor-pointer'}`, onClick: onClick },
        React.createElement(Text, { size: "XS", bold: true },
            title,
            " ",
            onClick && React.createElement(Icon, { icon: faInfoCircle })),
        React.createElement(Text, { size: "MD" }, value)));
};
