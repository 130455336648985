import React, { useEffect, useState } from 'react';
import { DEFAULT_SURVEY_FLOOR } from '../../../code/survey_defaults';
import { RadioGroup } from '../../../components/inputs_and_selections/radio';
import { Input } from '../../../components/inputs_and_selections/input';
import { Button } from '../../../components/buttons/button';
import { FLOOR_NAMES } from '../survey/survey';
import { isFlagSet } from '../../../code/helpers';
import { validateIsPositiveNumber } from '../../../code/validators';
export const FloorAttributesBlock = ({ floor, floors, onSave }) => {
    var _a, _b, _c, _d;
    const existingFloorNames = floors.filter(x => x.uuid !== (floor === null || floor === void 0 ? void 0 : floor.uuid)).map(x => x.name);
    const remainingFloorNames = FLOOR_NAMES.filter(x => !existingFloorNames.includes(x));
    // pre-select next available floor name
    const [floorName, setFloorName] = useState((_a = floor === null || floor === void 0 ? void 0 : floor.name) !== null && _a !== void 0 ? _a : (remainingFloorNames.length > 0 ? remainingFloorNames[0] : ''));
    const [roomHeight, setRoomHeight] = useState((_b = floor === null || floor === void 0 ? void 0 : floor.default_room_height) !== null && _b !== void 0 ? _b : DEFAULT_SURVEY_FLOOR.default_room_height);
    const [floorMaterialType, setFloorMaterialType] = useState((_c = floor === null || floor === void 0 ? void 0 : floor.default_floor_material_type) !== null && _c !== void 0 ? _c : DEFAULT_SURVEY_FLOOR.default_floor_material_type);
    const [ceilingMaterialType, setCeilingMaterialType] = useState((_d = floor === null || floor === void 0 ? void 0 : floor.default_ceiling_material_type) !== null && _d !== void 0 ? _d : DEFAULT_SURVEY_FLOOR.default_ceiling_material_type);
    // a flag to track which attributes user has changed
    const [userSelectedAttributesFlags, setUserSelectedAttributesFlags] = useState(0);
    // flag values
    const USER_SELECTED_FLOOR_TYPE = 0x001;
    const USER_SELECTED_CEILING_TYPE = 0x002;
    // do some magic based on the chosen name of the floor
    useEffect(() => {
        // skip magic if we're changing an existing floor, not creating a new one
        if (floor)
            return;
        if (floorName.toLowerCase().includes('ground')) {
            // if user hasn't selected floor type manually, set it to ground
            if (!isFlagSet(userSelectedAttributesFlags, USER_SELECTED_FLOOR_TYPE)) {
                // set the floor and ceiling types based on the name
                setFloorMaterialType('ground-floor');
            }
            // if user hasn't selected ceiling type manually, set it to intermediate
            if (!isFlagSet(userSelectedAttributesFlags, USER_SELECTED_CEILING_TYPE)) {
                setCeilingMaterialType('intermediate-floor-and-ceiling');
            }
        }
        else {
            // if user hasn't selected floor type manually, set it to intermediate
            if (!isFlagSet(userSelectedAttributesFlags, USER_SELECTED_FLOOR_TYPE)) {
                setFloorMaterialType('intermediate-floor-and-ceiling');
            }
            // if user hasn't selected ceiling type manually, set it to roof
            if (!isFlagSet(userSelectedAttributesFlags, USER_SELECTED_CEILING_TYPE)) {
                setCeilingMaterialType('roof');
            }
        }
    }, [floorName]);
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-col gap-6' },
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'text-gray-900 font-bold' }, "Floor name"),
                React.createElement(RadioGroup, { items: remainingFloorNames.map(x => ({
                        name: x,
                        onClick: () => setFloorName(x),
                        variant: x === floorName ? 'ACTIVE' : 'DEFAULT'
                    })) }),
                React.createElement(Input, { placeholder: 'Enter floor name', value: floorName, setValue: setFloorName })),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'text-gray-900 font-bold' }, "Ceiling height"),
                React.createElement(Input, { validator: validateIsPositiveNumber, type: 'number', value: roomHeight.toString(), setValue: (e) => setRoomHeight(Number(e)), postfix: 'm' })),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'text-gray-900 font-bold' }, "Floor type"),
                React.createElement(RadioGroup, { items: [
                        { id: 'ground-floor', name: 'Ground' },
                        { id: 'intermediate-floor-and-ceiling', name: 'Intermediate floor' }
                    ].map(x => ({
                        name: x.name,
                        onClick: () => {
                            // mark that user has selected this attribute
                            setUserSelectedAttributesFlags(prev => prev | USER_SELECTED_FLOOR_TYPE);
                            // set the value
                            setFloorMaterialType(x.id);
                        },
                        variant: x.id === floorMaterialType ? 'ACTIVE' : 'DEFAULT'
                    })) })),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'text-gray-900 font-bold' }, "Ceiling type"),
                React.createElement(RadioGroup, { items: [
                        { id: 'intermediate-floor-and-ceiling', name: 'Intermediate ceiling' },
                        { id: 'roof', name: 'Roof' }
                    ].map(x => ({
                        name: x.name,
                        onClick: () => {
                            // mark that user has selected this attribute
                            setUserSelectedAttributesFlags(prev => prev | USER_SELECTED_CEILING_TYPE);
                            // set the value
                            setCeilingMaterialType(x.id);
                        },
                        variant: x.id === ceilingMaterialType ? 'ACTIVE' : 'DEFAULT'
                    })) })),
            React.createElement(Button
            // consider disabling button if user inputs a name of existing floor: `|| existingFloorNames.includes(floorName)`
            , { 
                // consider disabling button if user inputs a name of existing floor: `|| existingFloorNames.includes(floorName)`
                disabled: !floorName || !validateIsPositiveNumber(roomHeight.toString()).value, onClick: () => {
                    var _a;
                    const newFloor = {
                        ...(floor !== null && floor !== void 0 ? floor : DEFAULT_SURVEY_FLOOR), // if floor is not provided, use default values
                        uuid: (_a = floor === null || floor === void 0 ? void 0 : floor.uuid) !== null && _a !== void 0 ? _a : crypto.randomUUID(),
                        name: floorName,
                        default_room_height: roomHeight,
                        default_floor_material_type: floorMaterialType,
                        default_ceiling_material_type: ceilingMaterialType
                    };
                    onSave(newFloor);
                } }, floor ? 'Update floor' : 'Create floor')));
};
