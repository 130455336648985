import React from 'react';
import { HLRContext } from './heatloss_report';
export const HLRTitlePage = () => {
    var _a, _b;
    const hlrContext = React.useContext(HLRContext);
    const reportGenerationDate = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' });
    const preparedFor = [
        (_b = (_a = hlrContext.lead.customer) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '',
        hlrContext.lead.property.address,
        hlrContext.lead.property.postcode
    ];
    const preparedBy = [
        hlrContext.company.public_info.installer_first_name + ' ' + hlrContext.company.public_info.installer_last_name,
        hlrContext.company.public_info.name,
        hlrContext.company.public_info.notification_email,
        hlrContext.company.phone
    ];
    const propertyPhotos = hlrContext.files.filter(x => hlrContext.survey.property_images.some(y => y.image_uuid === x.uuid));
    return (React.createElement("div", { className: "flex-col gap-16 flex" },
        React.createElement("div", { className: "flex-col gap-9 flex" },
            React.createElement("div", { className: "flex-col gap-1 flex" },
                React.createElement("div", { className: "" }, reportGenerationDate),
                React.createElement("div", { className: "self-stretch text-gray-900 text-6xl font-bold" },
                    "Heat Loss Report",
                    React.createElement("br", null),
                    "& System Design")),
            React.createElement("div", { className: "w-full flex" },
                React.createElement("div", { className: "w-1/2 flex-col gap-3 flex" },
                    React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Prepared for"),
                    React.createElement("div", { className: "flex-col gap-1 flex" }, preparedFor.map((x, i) => (React.createElement("div", { key: 'preparedFor-' + i, className: "" }, x))))),
                React.createElement("div", { className: "w-1/2 flex-col gap-3 flex" },
                    React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Prepared by"),
                    React.createElement("div", { className: "flex-col gap-1 flex" }, preparedBy.map((x, i) => (React.createElement("div", { key: 'preparedBy' + i, className: "" }, x))))))),
        React.createElement("div", { className: "text-xs tracking-tight" }, [hlrContext.company.public_info.name, hlrContext.company.address, hlrContext.company.public_info.phone]
            .filter(x => x.trim() !== '')
            .join(', ')),
        propertyPhotos.length > 0 &&
            React.createElement("img", { className: "max-h-96 max-w-full object-cover", src: propertyPhotos[0].file_base64 || `${process.env.S3_BUCKET_URL}/${propertyPhotos[0].file_url}`, alt: '' })));
};
