import { orderBy } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Stage, Layer, Circle, Line, Image } from 'react-konva';
import useImage from 'use-image';
import { GridLayer, canvasSize } from './entity_toolbar';
import { RoomDrawing } from './room_drawing';
import { indigo600, red500, white } from './code/constants';
import { removeUndefinedFromList } from '../../../code/helpers';
export const FloorStage = ({ onClick, onTouchEnd, onTouchMove, onWheelProp, onDragMove, stageScale, stagePosition, stageSize, roomsWithMetaData, currentRoomId, wallsWithoutDuplicates, survey, floor, setFloor, dragStopped, currentWall, setStageScale, page, setCurrentWallId, setPage, setWalls, currentRoom, setCurrentRoomId, fontSize, fontSizeSmall, defaultMaterials, isStatic = false, setStagePosition, addEvent, scalingPoints, mousePosition, tempImageAndScale, stageRef, setStageSize, stageStep, onMouseMove, isDrawing, intersectingShapes, scalingWindow }) => {
    var _a;
    const [image] = useImage((_a = (floor.floor_plan_image || `${process.env.S3_BUCKET_URL}/${floor.floor_plan_url}`)) !== null && _a !== void 0 ? _a : '');
    const [tempImage] = useImage(tempImageAndScale.image);
    const [touches, setTouches] = useState(0);
    useEffect(() => {
        var _a, _b, _c, _d;
        setStageSize({
            width: (_b = (_a = stageRef === null || stageRef === void 0 ? void 0 : stageRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth) !== null && _b !== void 0 ? _b : 0,
            height: (_d = (_c = stageRef === null || stageRef === void 0 ? void 0 : stageRef.current) === null || _c === void 0 ? void 0 : _c.clientHeight) !== null && _d !== void 0 ? _d : 0
        });
        const handleResize = () => {
            var _a, _b, _c, _d;
            setStageSize({
                width: (_b = (_a = stageRef === null || stageRef === void 0 ? void 0 : stageRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth) !== null && _b !== void 0 ? _b : 0,
                height: (_d = (_c = stageRef === null || stageRef === void 0 ? void 0 : stageRef.current) === null || _c === void 0 ? void 0 : _c.clientHeight) !== null && _d !== void 0 ? _d : 0
            });
        };
        const resizeObserver = new ResizeObserver(handleResize);
        if (stageRef === null || stageRef === void 0 ? void 0 : stageRef.current) {
            resizeObserver.observe(stageRef === null || stageRef === void 0 ? void 0 : stageRef.current);
        }
        handleResize();
        return () => {
            if (stageRef === null || stageRef === void 0 ? void 0 : stageRef.current) {
                resizeObserver.unobserve(stageRef === null || stageRef === void 0 ? void 0 : stageRef.current);
            }
        };
    }, []);
    const isCreatingPoints = stageStep === 1 || stageStep === 2;
    const cursorType = isCreatingPoints || isDrawing
        ? 'cursor-pointer'
        : 'cursor-move';
    // If we are scaling an image, once we have 2 points we don't want to show the onHover point or line anymore.
    const scalingLinesWithMouse = !stageStep || stageStep === 1 || stageStep === 2
        ? [...scalingPoints.map(sp => [sp.x, sp.y]).flat(), mousePosition === null || mousePosition === void 0 ? void 0 : mousePosition.x, mousePosition === null || mousePosition === void 0 ? void 0 : mousePosition.y]
        : scalingPoints.map(sp => [sp.x, sp.y]).flat();
    const scalingPointsWithMouse = !stageStep || stageStep === 1 || stageStep === 2
        ? [mousePosition, ...scalingPoints]
        : scalingPoints;
    return React.createElement(Stage, { hitOnDragEnabled: true, className: `bg-gray-100 ${isStatic ? '' : 'fixed'} ${cursorType} flex`, onClick: onClick, onTap: onClick, onTouchEnd: () => {
            onTouchEnd();
            setTouches(0);
        }, onTouchMove: onTouchMove, onMouseMove: onMouseMove, onTouchStart: (e) => { var _a; return setTouches((_a = e.evt.touches) === null || _a === void 0 ? void 0 : _a.length); }, scale: { x: stageScale, y: stageScale }, x: stagePosition.x, y: stagePosition.y, draggable: !isStatic, width: stageSize.width, height: stageSize.height, onWheel: onWheelProp, onDragMove: onDragMove },
        !isStatic && React.createElement(GridLayer, null),
        React.createElement(Layer, null,
            tempImage && React.createElement(Image, { x: (canvasSize / 2) - (tempImage.width * tempImageAndScale.scale) / 2, y: (canvasSize / 2) - (tempImage.height * tempImageAndScale.scale) / 2, opacity: 1, image: tempImage, scale: { x: tempImageAndScale.scale, y: tempImageAndScale.scale } }),
            image && !tempImage && floor.floor_plan_is_showing && React.createElement(Image, { listening: false, x: (canvasSize / 2) - (image.width * floor.floor_plan_scale) / 2, y: (canvasSize / 2) - (image.height * floor.floor_plan_scale) / 2, opacity: 0.2, image: image, scale: { x: floor.floor_plan_scale, y: floor.floor_plan_scale } }),
            !stageStep && !scalingWindow && orderBy(roomsWithMetaData, x => x.uuid === currentRoomId ? 1 : 0).map(x => {
                return React.createElement(RoomDrawing, { touches: touches, wallsWithoutDupes: wallsWithoutDuplicates, key: x.uuid, room: x, floor: floor, setFloor: setFloor, dragStopped: dragStopped, currentWall: currentWall, stageScale: stageScale, setStageScale: setStageScale, setCurrentWallId: setCurrentWallId, page: page, setPage: setPage, setWalls: setWalls, currentRoom: currentRoom, currentRoomId: currentRoomId, setCurrentRoomId: setCurrentRoomId, fontSize: fontSize, fontSizeSmall: fontSizeSmall, defaultMaterials: defaultMaterials, setStagePosition: setStagePosition, intersectingShapes: intersectingShapes, addEvent: addEvent, isDrawing: isDrawing });
            }),
            scalingPoints.length > 0 && React.createElement(Line, { points: removeUndefinedFromList(scalingLinesWithMouse), strokeWidth: 2, stroke: indigo600 }),
            removeUndefinedFromList(scalingPointsWithMouse).map((sp, i) => React.createElement(Circle, { key: i, x: sp.x, y: sp.y, radius: 5, stroke: indigo600, fill: i === scalingPoints.length ? indigo600 : white })),
            intersectingShapes.map((x, i) => React.createElement(Line, { key: i, points: x.flat(), opacity: 0.2, fill: red500, listening: false, closed: true }))));
};
