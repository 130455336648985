import { apiUrl, client } from '../axios';
import { getEpcScotlandByUPRN } from './epc_scotland';
import { getBuiltForm, getConstructionAge, getFloorInsulation, getLoftInsulation, getPropertyType, getUValue, getWallGroup, getWallType, getWindowType } from './u_value';
import { parseConstructionAgeBand } from './age_bands';
import { tryParseFloat } from '../helpers';
import { getFuelTypeFromEPC } from './fuel';
import { getNumberOfBedroomsFromHabitableRooms } from './estimated_rooms_and_radiators';
export const getEpcRecommendations = async (epcId) => {
    var _a;
    try {
        const result = await client.get(`${apiUrl}recommendations?id=${epcId}`);
        return result.data;
    }
    catch (e) {
        const axiosError = e;
        if (((_a = axiosError.response) === null || _a === void 0 ? void 0 : _a.status) === 404)
            return [];
        throw e;
    }
};
// Returns the latest EPC for a given English or Welsh UPRN
export const getEpcForUprn = async (uprn) => {
    var _a;
    try {
        // English EPCs are fetched from the EPC API
        const result = await client.get(`${apiUrl}epcs/${uprn}`);
        return result.data;
    }
    catch (e) {
        const axiosError = e;
        if (((_a = axiosError.response) === null || _a === void 0 ? void 0 : _a.status) === 404)
            return undefined;
        throw e;
    }
};
// Returns the latest EPC for a given Scottish UPRN
export const getEpcScottishForUprn = async (uprn) => {
    var _a;
    try {
        // Scottish EPCs are in the database
        return await getEpcScotlandByUPRN(uprn);
    }
    catch (e) {
        const axiosError = e;
        if (((_a = axiosError.response) === null || _a === void 0 ? void 0 : _a.status) === 404)
            return undefined;
        throw e;
    }
};
export const epcToBackgroundColour = (rating) => {
    if (rating === 'A')
        return 'bg-green-600';
    if (rating === 'B')
        return 'bg-green-500';
    if (rating === 'C')
        return 'bg-green-400';
    if (rating === 'D')
        return 'bg-yellow-500';
    if (rating === 'E')
        return 'bg-orange-400';
    if (rating === 'F')
        return 'bg-orange-500';
    if (rating === 'G')
        return 'bg-red-500';
};
export const parseEnglishEpc = async (epc, lead) => {
    const newLead = { ...lead };
    newLead.epcId = epc.lmkKey;
    newLead.epcData = epc;
    newLead.property = parseEnglishEpcInner(epc, newLead.property) || newLead.property;
    newLead.epc_recommendations = epc ? await getEpcRecommendations(epc.lmkKey) : [];
    return newLead;
};
export const parseEnglishEpcInner = (epc, property) => {
    var _a, _b;
    if (!epc)
        return;
    const wallGroup = getWallGroup(epc.wallsDescription);
    const constructionAge = getConstructionAge(epc);
    const parsedConstructionAgeBand = parseConstructionAgeBand(constructionAge);
    const noBedrooms = getNumberOfBedroomsFromHabitableRooms(parseInt(epc.numberHabitableRooms));
    return {
        ...property,
        yearBuilt: constructionAge,
        construction_age_band_uuid: parsedConstructionAgeBand === null || parsedConstructionAgeBand === void 0 ? void 0 : parsedConstructionAgeBand.uuid,
        construction_age_band: parsedConstructionAgeBand,
        epcRating: epc.currentEnergyRating,
        floorArea: (_a = tryParseFloat(epc.totalFloorArea, property.floorArea)) !== null && _a !== void 0 ? _a : 0,
        roomHeight: (_b = tryParseFloat(epc.floorHeight, property.roomHeight)) !== null && _b !== void 0 ? _b : 0,
        propertyType: getPropertyType(epc),
        builtForm: getBuiltForm(epc),
        windowType: getWindowType(epc),
        fuelType: getFuelTypeFromEPC(epc.mainFuel),
        wallGroup,
        wallType: (wallGroup && getWallType(epc.wallsDescription, wallGroup)),
        floorType: (getFloorInsulation(epc.floorDescription)),
        loftInsulation: getLoftInsulation(epc.roofDescription),
        windowUValue: getUValue(epc.windowsDescription),
        floorUValue: getUValue(epc.floorDescription),
        roofUValue: getUValue(epc.roofDescription),
        wallUValue: getUValue(epc.wallsDescription),
        noBedrooms,
        noBathrooms: getNumberOfBedroomsFromHabitableRooms(noBedrooms)
    };
};
