import { axiosPostV2 } from '../../code/axios';
import { db } from '../heat_loss/db';
const removeKeyProperty = (data) => data.map(({ key, ...rest }) => rest);
export const syncTick = async (lastSynced, setLastSynced, isSyncing, setIsSyncing) => {
    var _a, _b, _c;
    // On slow internet, prevent calls tripping over each other.
    if (isSyncing)
        return;
    setIsSyncing(true);
    try {
        const surveys = (_a = await db.surveys.filter(x => x.updated_at > lastSynced).toArray()) !== null && _a !== void 0 ? _a : [];
        const files = (_b = await db.files.filter(x => x.updated_at > lastSynced).toArray()) !== null && _b !== void 0 ? _b : [];
        const customMaterials = (_c = await db.custom_materials.filter(x => x.updated_at > lastSynced).toArray()) !== null && _c !== void 0 ? _c : [];
        const sync = {
            last_synced: lastSynced,
            tables: [
                { name: 'surveys', data: removeKeyProperty(surveys) },
                { name: 'custom_materials', data: removeKeyProperty(customMaterials) },
                { name: 'files', data: removeKeyProperty(files) }
            ]
        };
        const result = await axiosPostV2('sync', sync);
        for (const table of result.tables) {
            const existingRecords = await db[table.name].where('uuid').anyOf(table.data.map(x => x.uuid)).toArray();
            const existingRecordsMap = new Map(existingRecords.map(record => [record.uuid, record]));
            const filteredData = table.data.filter(incomingRow => {
                const existingRow = existingRecordsMap.get(incomingRow.uuid);
                return !existingRow || incomingRow.updated_at >= existingRow.updated_at;
            });
            if (filteredData.length > 0)
                await db[table.name].bulkPut(filteredData.map(x => ({ key: x.uuid, ...x })));
        }
        setLastSynced(result.last_synced);
    }
    catch (e) {
        console.error('Sync failed: ', e);
    }
    setIsSyncing(false);
};
