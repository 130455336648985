import React, { useState } from 'react';
import { DesignPageHeader } from '../design/components/design_page_header';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../../../components/buttons/icon';
import { Button } from '../../../components/buttons/button';
import { chain, sum } from 'lodash';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { combineHeatLossesForProgressChart, combineSortConductionHeatLosses, combineVentilationHeatLosses, getConductionHeatLossAllElements, getFloorAreaM2, getRoomTemp, getRoomWatts, getVentilationHeatLoss } from '../../../code/models/heat_loss';
import { ClickableCard } from '../../../components/content_display/card';
import { TabGroup } from '../../../components/content_display/tab';
import { TableLite } from '../../../components/content_display/table_lite';
import { ProgressChart } from '../../../components/indicators_and_messaging/progress_chart';
import { CompleteButton } from '../survey/complete_button';
import { ListItem } from '../../../components/content_display/list_item';
import { getEmitterWatts } from '../../../code/models/radiator_model';
import { area, getEmitterOutputVsDemandText } from './code/utils';
import { pluralise } from '../../../code/helpers';
import { FlowTempSlider } from '../design/pages/emitter_design_page';
const pages = ['Rooms', 'Heat loss'];
export const FloorList = ({ setFlowTemp, floors, setSurveyTab, setFloorId, stepSize, minFlowTemp, maxFlowTemp, designTempC, groundTempC, survey, design, setCurrentRoomId, setSurvey }) => {
    const [currentPage, setPage] = useState('Rooms');
    const roomRows = survey.floors.flatMap(x => x.rooms.flatMap(y => {
        return {
            name: y.name,
            designTemp: getRoomTemp(y, survey),
            area: area(stepSize, y.walls.map(w => ({ x: w.x, y: w.y }))),
            heatLoss: getRoomWatts(y, x.rooms, designTempC, groundTempC, stepSize, survey)
        };
    }));
    const roomColumns = [
        { key: 'name', name: '' },
        { key: 'designTemp', name: 'Design Temp', render: (row) => React.createElement("div", null,
                row.designTemp,
                " \u00B0C") },
        { key: 'area', name: 'Area', render: (row) => React.createElement("div", null,
                row.area.toFixed(1),
                " m\u00B2") },
        { key: 'heatLoss', name: 'Heat loss', render: (row) => React.createElement("div", null,
                row.heatLoss.toFixed(0),
                " W") }
    ];
    const conductionHeatLossColumns = [
        { key: 'elementName', name: '' },
        { key: 'uValueWPerM2K', name: 'U-value (W/m²K)', render: (row) => React.createElement("div", null, row.uValueWPerM2K.toFixed(2)) },
        { key: 'areaM2', name: 'Area', render: (row) => React.createElement("div", null,
                row.areaM2.toFixed(1),
                " m\u00B2") },
        { key: 'watts', name: 'Heat loss', render: (row) => React.createElement("div", null,
                row.watts.toFixed(0),
                " W") }
    ];
    const ventilationHeatLossColumns = [
        { key: 'elementName', name: '' },
        { key: 'ACH', name: 'ACH', render: (row) => React.createElement("div", null, row.ACH) },
        { key: 'volumeM3', name: 'Volume', render: (row) => React.createElement("div", null,
                row.volumeM3.toFixed(1),
                " m\u00B3") },
        { key: 'watts', name: 'Heat loss', render: (row) => React.createElement("div", null,
                row.watts.toFixed(0),
                " W") }
    ];
    const totalFloorAreaM2 = sum(survey.floors.flatMap(floor => floor.rooms.map(room => getFloorAreaM2(room.walls, stepSize))));
    const conductionHeatLossRows = survey.floors.flatMap(floor => floor.rooms.flatMap(room => getConductionHeatLossAllElements(room, floor.rooms, designTempC, groundTempC, stepSize, survey)));
    const ventilationHeatLosses = survey.floors.flatMap(floor => floor.rooms.map(room => getVentilationHeatLoss(room, designTempC, stepSize, survey)));
    // For chart
    const heatLossForChart = combineHeatLossesForProgressChart(conductionHeatLossRows, ventilationHeatLosses);
    // For tables
    const conductionHeatLossRowsCombinedForTable = combineSortConductionHeatLosses(conductionHeatLossRows, false);
    const ventilationHeatLossCombinedForTable = combineVentilationHeatLosses(ventilationHeatLosses);
    const totalHeatLossWFromElements = Math.round(sum([...conductionHeatLossRowsCombinedForTable, ...ventilationHeatLossCombinedForTable].map(x => x.watts)));
    const totalHeatLossWFromRooms = Math.round(sum(roomRows.map(x => x.heatLoss)));
    const page = 'FLOORPLAN';
    return React.createElement("div", { className: 'h-full flex flex-col' },
        React.createElement(DesignPageHeader, { title: 'Floorplan', onBack: () => setSurveyTab(''), completed: survey.completed_sections.includes(page) },
            React.createElement(TabGroup, { items: pages.map(x => ({
                    name: x,
                    secondaryText: x === 'Heat loss'
                        ? `${totalHeatLossWFromElements} W`
                        : `${totalFloorAreaM2.toFixed(1)} m²`,
                    onClick: () => setPage(x),
                    variant: x === currentPage ? 'ACTIVE' : 'DEFAULT'
                })) })),
        currentPage === 'Rooms' && React.createElement("div", { className: "p-5 bg-gray-100 flex-col gap-6 flex flex-grow overflow-y-auto" },
            React.createElement("div", { className: "flex-col gap-3 flex" },
                React.createElement("div", { className: "justify-between items-center flex" },
                    React.createElement("div", { className: " text-gray-900 text-xl font-bold" }, "Floors"),
                    React.createElement(Button, { onClick: () => setSurveyTab('NEW_FLOOR'), colour: 'LOZENGE_DARK' }, "Add floor")),
                floors.length === 0 && React.createElement(ClickableCard, { onClick: () => setSurveyTab('NEW_FLOOR'), variant: 'PLACEHOLDER' },
                    React.createElement("div", { className: 'flex flex-col items-center gap-2' },
                        React.createElement(Icon, { className: 'text-2xl', icon: faLayerGroup }),
                        React.createElement("div", null, "No floors found"))),
                React.createElement("div", { className: "bg-white rounded-md flex-col flex divide-y divide-gray-200" }, floors.map(x => React.createElement(ListItem, { onClick: () => setFloorId(x.uuid), key: x.uuid, primaryText: x.name, secondaryText: `${x.rooms.length} room${x.rooms.length > 1 && x.rooms.length !== 0 ? 's' : ''} • ${Math.round(sum(x.rooms.map(y => area(stepSize, y.walls.map(x => ({ x: x.x, y: x.y }))))))} m²` })))),
            floors.length > 0 && React.createElement("div", { className: 'flex flex-col gap-3' },
                React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Rooms"),
                React.createElement(ClickableCard, { border: false, variant: 'WHITE' },
                    React.createElement("div", { className: 'flex flex-col gap-4 justify-between flex-grow' },
                        React.createElement(FlowTempSlider, { flowTemp: design.flow_temp, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp }))),
                floors.map(x => {
                    // If we have a group, group them otherwise group by individual rooms e.g. just list the rooms as normal.
                    const roomGroups = chain(x.rooms)
                        .groupBy(x => x.room_group_uuid ? x.room_group_uuid : x.uuid)
                        .map((values, key) => ({ key, values }))
                        .value();
                    return React.createElement("div", { key: x.uuid },
                        React.createElement("div", { className: "text-gray-500 font-semibold" }, x.name),
                        x.rooms.length === 0 && React.createElement(ClickableCard, { variant: 'PLACEHOLDER' },
                            React.createElement("div", { className: 'flex flex-col items-center gap-2' },
                                React.createElement("div", null, "No rooms found"))),
                        roomGroups.map(({ key, values }) => {
                            const wattsLost = sum(values.map(y => Math.round(getRoomWatts(y, x.rooms, designTempC, groundTempC, stepSize, survey))));
                            const wattsEmitted = sum(values.map(y => Math.round(sum(y.radiators.map(r => getEmitterWatts(r, y, design, survey, designTempC, groundTempC))))));
                            const windows = sum(values.flatMap(y => y.walls.map(x => x.windows.length)));
                            const doors = sum(values.flatMap(y => y.walls.map(x => x.doors.length)));
                            const roomArea = area(stepSize, values.flatMap(y => y.walls.map(x => ({ x: x.x, y: x.y }))));
                            const wattsPerM2 = Math.round(wattsLost / roomArea);
                            return React.createElement("div", { className: 'flex flex-col gap-3', key: key },
                                values.length > 1 && React.createElement("div", { className: 'flex justify-between' },
                                    React.createElement("div", { className: 'font-bold text-gray-900' }, values.map(x => x.name).join(', ')),
                                    React.createElement(Badge, { color: wattsEmitted >= wattsLost ? 'GREEN' : 'RED', text: getEmitterOutputVsDemandText(wattsEmitted, wattsLost) })),
                                React.createElement("div", { className: 'bg-white rounded-md flex flex-col divide-y divide-gray-200' }, values.map((y, i) => React.createElement(ListItem, { onClick: () => {
                                        setCurrentRoomId(y.uuid);
                                        setFloorId(x.uuid);
                                    }, key: i, primaryText: y.name, secondaryText: `${wattsPerM2} W/m² • ${y.radiators.length} ${pluralise('emitter', y.radiators.length)}, ${windows} ${pluralise('win', windows)}, ${doors} ${pluralise('door', doors)}`, rightBadge: values.length === 1 ? React.createElement(Badge, { color: wattsEmitted >= wattsLost ? 'GREEN' : 'RED', text: getEmitterOutputVsDemandText(wattsEmitted, wattsLost) }) : React.createElement("div", null) }))));
                        }));
                }))),
        currentPage === 'Rooms' && React.createElement(CompleteButton, { page: page, pageChecks: [], survey: survey, setSurvey: setSurvey, onBack: async () => setSurveyTab('') }),
        currentPage === 'Heat loss' && React.createElement("div", { className: 'flex flex-col p-5' },
            React.createElement("div", { className: 'flex flex-col gap-4' },
                React.createElement(ClickableCard, { variant: 'GREY' },
                    React.createElement("div", { className: 'flex flex-col gap-2' },
                        React.createElement("div", { className: 'flex justify-between text-lg' },
                            React.createElement("div", { className: 'text-gray-900 font-bold' }, "Heat loss by room"),
                            React.createElement("div", null,
                                totalHeatLossWFromRooms,
                                " W")),
                        React.createElement(ProgressChart, { total: totalHeatLossWFromRooms, items: roomRows.map(x => ({ value: x.heatLoss, name: x.name })) }))),
                React.createElement(TableLite, { columns: roomColumns, rows: roomRows }),
                React.createElement("div", { className: 'flex flex-col gap-1' },
                    React.createElement("div", { className: 'flex justify-between text-gray-900 font-bold px-2' },
                        React.createElement("div", null, "Total"),
                        React.createElement("div", null,
                            totalHeatLossWFromRooms,
                            " W")),
                    React.createElement("div", { className: 'flex justify-between px-2 text-xs font-semibold' },
                        React.createElement("div", null, "Heat loss per m\u00B2"),
                        React.createElement("div", null,
                            Math.round(sum(roomRows.map(x => x.heatLoss)) / sum(roomRows.map(x => x.area))),
                            " W/m\u00B2")),
                    React.createElement("div", { className: 'flex justify-between px-2 text-xs font-semibold' },
                        React.createElement("div", null, "Floor area"),
                        React.createElement("div", null,
                            Math.round(sum(roomRows.map(x => x.area))),
                            " m\u00B2"))),
                React.createElement(ClickableCard, { variant: 'GREY' },
                    React.createElement("div", { className: 'flex flex-col gap-2' },
                        React.createElement("div", { className: 'flex justify-between text-lg' },
                            React.createElement("div", { className: 'text-gray-900 font-bold' }, "Heat loss by element"),
                            React.createElement("div", null,
                                totalHeatLossWFromElements,
                                " W")),
                        React.createElement(ProgressChart, { total: totalHeatLossWFromElements, items: heatLossForChart }))),
                React.createElement(TableLite, { columns: conductionHeatLossColumns, rows: conductionHeatLossRowsCombinedForTable }),
                React.createElement(TableLite, { columns: ventilationHeatLossColumns, rows: ventilationHeatLossCombinedForTable }),
                React.createElement("div", { className: 'flex flex-col gap-4' },
                    React.createElement("div", { className: 'flex justify-between text-gray-900 font-bold px-2' },
                        React.createElement("div", null, "Total"),
                        React.createElement("div", null,
                            totalHeatLossWFromElements,
                            " W")),
                    survey.intermittent_heating && React.createElement("div", { className: 'text-xs text-gray-500 italic font-light' }, `* Intermittent heating correction factor of ${survey.intermittent_heating_oversize_factor_percentage}% applied`)))));
};
