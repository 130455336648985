import React, { useEffect, useRef, useState } from 'react';
import { updateCompanySettings } from '../../../../code/models/company';
import { Section } from '../../../../components/containers/section';
import { Input } from '../../../../components/inputs_and_selections/input';
import { noop } from 'lodash';
import { Map, Marker, useAutocomplete } from '@vis.gl/react-google-maps';
import { Button } from '../../../../components/buttons/button';
import { SettingsLayout } from '../components/settings_layout';
export const AccountProfile = ({ company, setCompany }) => {
    const [editingCompany, setEditingCompany] = useState();
    const [addressLocation, setAddressLocation] = useState();
    const [mapCenter, setMapCenter] = useState();
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState('');
    useEffect(() => {
        var _a, _b, _c, _d;
        setAddressLocation({ lat: (_a = company.lat) !== null && _a !== void 0 ? _a : 0, lng: (_b = company.lng) !== null && _b !== void 0 ? _b : 0 });
        setMapCenter({ lat: (_c = company.lat) !== null && _c !== void 0 ? _c : 0, lng: (_d = company.lng) !== null && _d !== void 0 ? _d : 0 });
    }, []);
    const onPlaceChanged = (place) => {
        var _a, _b, _c, _d, _e, _f;
        if (place) {
            setInputValue((_b = (_a = place.formatted_address) !== null && _a !== void 0 ? _a : place.name) !== null && _b !== void 0 ? _b : '');
            setAddressLocation((_d = (_c = place.geometry) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.toJSON());
            setMapCenter((_f = (_e = place.geometry) === null || _e === void 0 ? void 0 : _e.location) === null || _f === void 0 ? void 0 : _f.toJSON());
        }
    };
    useAutocomplete({
        inputField: inputRef === null || inputRef === void 0 ? void 0 : inputRef.current,
        onPlaceChanged
    });
    return React.createElement(SettingsLayout, { heading: 'Profile', description: 'Manage your Spruce profile' },
        React.createElement(Section, { border: true, title: "Account details" },
            React.createElement("div", { className: 'flex flex-col gap-4' },
                React.createElement("div", { className: 'flex flex-wrap gap-4' },
                    React.createElement("div", { className: 'flex-grow space-y-2' },
                        React.createElement("div", { className: 'font-bold' }, "First name"),
                        editingCompany ? React.createElement(Input, { value: editingCompany.public_info.installer_first_name, setValue: (e) => setEditingCompany(prev => ({
                                ...prev,
                                public_info: {
                                    ...prev.public_info,
                                    installer_first_name: e
                                }
                            })) }) : React.createElement("div", { className: 'text-gray-600' }, company.public_info.installer_first_name)),
                    React.createElement("div", { className: 'flex-grow space-y-2' },
                        React.createElement("div", { className: 'font-bold' }, "Last name"),
                        editingCompany ? React.createElement(Input, { value: editingCompany.public_info.installer_last_name, setValue: (e) => setEditingCompany(prev => ({
                                ...prev,
                                public_info: {
                                    ...prev.public_info,
                                    installer_last_name: e
                                }
                            })) }) : React.createElement("div", { className: 'text-gray-600' }, company.public_info.installer_last_name))),
                React.createElement("div", { className: 'space-y-2' },
                    React.createElement("div", { className: 'font-bold' }, "Phone number"),
                    editingCompany ? React.createElement(Input, { value: editingCompany.phone, setValue: (e) => setEditingCompany(prev => ({ ...prev, phone: e })) })
                        : React.createElement("div", { className: 'text-gray-600' }, company.phone)),
                React.createElement("div", { className: 'space-y-2' },
                    React.createElement("div", { className: 'font-bold' }, "Company name"),
                    editingCompany &&
                        React.createElement(Input, { value: editingCompany.public_info.name, setValue: (e) => setEditingCompany(prev => ({ ...prev, public_info: { ...prev.public_info, name: e } })) }),
                    !editingCompany &&
                        React.createElement("div", { className: 'text-gray-600' }, company.public_info.name)),
                React.createElement("div", { className: 'flex flex-col gap-2' },
                    React.createElement("div", { className: 'font-bold' }, "Address"),
                    editingCompany &&
                        React.createElement(Input, { ref: inputRef, value: inputValue, setValue: setInputValue }),
                    !editingCompany &&
                        React.createElement("div", { className: 'text-gray-600' }, company.address),
                    ((addressLocation === null || addressLocation === void 0 ? void 0 : addressLocation.lat) && (addressLocation === null || addressLocation === void 0 ? void 0 : addressLocation.lng))
                        ? React.createElement(Map, { onClick: editingCompany ? (e) => { var _a; return setAddressLocation((_a = e.detail.latLng) !== null && _a !== void 0 ? _a : undefined); } : noop, streetViewControl: false, zoom: 18, className: "min-h-[200px] w-full h-full rounded overflow-hidden", mapTypeId: "satellite", center: mapCenter },
                            React.createElement(Marker, { position: addressLocation })) : React.createElement(React.Fragment, null))),
            React.createElement("div", { className: 'flex gap-x-2 pt-8' },
                !editingCompany && React.createElement(Button, { colour: 'LIGHT', size: 'MD', onClick: async () => {
                        setEditingCompany(company);
                        setInputValue(company.address);
                        setAddressLocation({ lat: company.lat, lng: company.lng });
                        setMapCenter({ lat: company.lat, lng: company.lng });
                    } }, "Edit"),
                editingCompany && React.createElement(React.Fragment, null,
                    React.createElement(Button, { onClick: async () => {
                            const newCompany = {
                                ...editingCompany,
                                address: inputValue,
                                lat: addressLocation === null || addressLocation === void 0 ? void 0 : addressLocation.lat,
                                lng: addressLocation === null || addressLocation === void 0 ? void 0 : addressLocation.lng
                            };
                            await updateCompanySettings(newCompany);
                            setCompany(newCompany);
                            setEditingCompany(undefined);
                        } }, "Save"),
                    React.createElement(Button, { colour: 'LIGHT', onClick: () => setEditingCompany(undefined) }, "Cancel")))));
};
