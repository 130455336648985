import React, { useContext } from 'react';
import { AdminContext } from '../admin_layout';
import { OfflinePage } from '../offline_page';
import { useParams, useRouteLoaderData } from 'react-router-dom';
import { RequireRole } from '../../../require_role';
import { USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SIMPLE } from '../../../code/models/user';
import TabbedDashboardLayout from '../../../layouts/tabbed_dashboard_layout';
import { Heading } from '../../../components/content_display/heading';
import { Bars3Icon } from '@heroicons/react/16/solid';
import { AccountProfile } from './account_settings/account_profile';
import { EmailTemplatesSendEstimate } from './email_templates_settings/email_templates_send_estimate';
import { EstimateEnquiryForm } from './estimate_settings/estimate_enquiry_form';
import { AccountIntegrations } from './account_settings/account_integrations';
import { AccountBranding } from './account_settings/account_branding';
import { EstimateResultsPage } from './estimate_settings/estimate_results_page';
import { EmailTemplatesNudgeFollowup } from './email_templates_settings/email_templates_nudge_follow-up';
import { EmailTemplatesBookSurvey } from './email_templates_settings/email_templates_book_survey';
import { EmailTemplatesDeclineEnquiry } from './email_templates_settings/email_templates_decline_enquiry';
function SettingsHeader({ adminContext }) {
    return React.createElement("div", { className: 'flex gap-4 px-5 py-3 items-center' },
        React.createElement(Bars3Icon, { "data-cy": 'nav_open', className: "lg:hidden size-6 cursor-pointer", onClick: () => {
                adminContext.showSidebar();
            } }),
        React.createElement(Heading, { size: 'xl' }, "Settings"));
}
export const SettingsPage = () => {
    const { tab, secondaryTab } = useParams();
    const adminContext = useContext(AdminContext);
    const companyPublicInfo = useRouteLoaderData('company_root');
    if (!adminContext.data.company)
        return;
    if (adminContext.isOffline) {
        return React.createElement(OfflinePage, { backURL: `/${companyPublicInfo.subdomain}/admin/surveys/all` });
    }
    return (React.createElement(RequireRole, { roles: [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS] },
        React.createElement(TabbedDashboardLayout, { basePath: `/${companyPublicInfo.subdomain}/admin/settings`, selectedTabId: tab, selectedSecondaryTabId: secondaryTab, header: React.createElement(SettingsHeader, { adminContext: adminContext }), tabs: [
                {
                    id: 'account',
                    label: 'Account',
                    content: React.createElement(React.Fragment, null),
                    secondaryTabs: [
                        {
                            id: 'profile',
                            label: 'Profile',
                            content: React.createElement(AccountProfile, { company: adminContext.data.company, setCompany: adminContext.setCompany })
                        },
                        {
                            id: 'integrations',
                            label: 'Integrations',
                            content: React.createElement(AccountIntegrations, { company: adminContext.data.company, setCompany: adminContext.setCompany })
                        },
                        {
                            id: 'branding',
                            label: 'Branding',
                            content: React.createElement(AccountBranding, { company: adminContext.data.company, setCompany: adminContext.setCompany })
                        }
                    ]
                },
                {
                    id: 'estimate',
                    label: 'Estimate',
                    content: React.createElement(React.Fragment, null),
                    secondaryTabs: [
                        {
                            id: 'enquiry-form',
                            label: 'Enquiry form',
                            content: React.createElement(EstimateEnquiryForm, { company: adminContext.data.company, setCompany: adminContext.setCompany })
                        },
                        {
                            id: 'results-page',
                            label: 'Results page',
                            content: React.createElement(EstimateResultsPage, { company: adminContext.data.company, setCompany: adminContext.setCompany })
                        }
                    ]
                },
                {
                    id: 'email-templates',
                    label: 'Email Templates',
                    content: React.createElement(React.Fragment, null),
                    secondaryTabs: [
                        // {
                        //   id: 'enquiry-received',
                        //   label: 'Enquiry received',
                        //   content: <EmailTemplatesEnquiryReceived
                        //     company={adminContext.data.company}
                        //     setCompany={adminContext.setCompany}
                        //   />
                        // },
                        {
                            id: 'send-estimate',
                            label: 'Send estimate',
                            content: React.createElement(EmailTemplatesSendEstimate, { company: adminContext.data.company, setCompany: adminContext.setCompany })
                        },
                        {
                            id: 'nudge-follow-up',
                            label: 'Nudge follow-up',
                            content: React.createElement(EmailTemplatesNudgeFollowup, { company: adminContext.data.company, setCompany: adminContext.setCompany })
                        },
                        {
                            id: 'book-survey',
                            label: 'Book survey',
                            content: React.createElement(EmailTemplatesBookSurvey, { company: adminContext.data.company, setCompany: adminContext.setCompany })
                        },
                        {
                            id: 'decline-enquiry',
                            label: 'Decline enquiry',
                            content: React.createElement(EmailTemplatesDeclineEnquiry, { company: adminContext.data.company, setCompany: adminContext.setCompany })
                        }
                    ]
                }
            ] })));
};
