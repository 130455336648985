import React from 'react';
import { Emoji } from './emoji';
export const SummaryTable = ({ rows }) => {
    if (!rows)
        return;
    return React.createElement("div", { className: 'w-full divide divide-y border-light' }, rows.map(({ key, value, size = 'md', emoji, infoButton }, i) => React.createElement("div", { key: i, className: 'flex flex-col gap-1 md:flex-row justify-between py-2 last:pb-0' },
        React.createElement("div", { className: `flex gap-2 items-center ${size === 'md' ? 'text-sm text-default' : 'text-md text-bold font-bold'}` },
            emoji && React.createElement(Emoji, { emoji: emoji }),
            React.createElement("p", null,
                key,
                " ",
                infoButton)),
        React.createElement("p", { className: 'text-default text-sm' }, value))));
};
