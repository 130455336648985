import React, { useState } from 'react';
import { RadioGroup } from '../../../components/inputs_and_selections/radio';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../../../components/buttons/icon';
export const RadioDemo = () => {
    const [selectedRadio, setSelectedRadio] = useState();
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement("div", null, "Default"),
            React.createElement("div", null,
                React.createElement(RadioGroup, { items: [
                        { name: 'One', description: undefined, icon: undefined, onClick: () => setSelectedRadio('One'), variant: selectedRadio === 'One' ? 'ACTIVE' : 'DEFAULT' },
                        { name: 'Two', description: undefined, icon: undefined, onClick: () => setSelectedRadio('Two'), variant: selectedRadio === 'Two' ? 'ACTIVE' : 'DEFAULT' },
                        { name: 'Three', description: undefined, icon: undefined, onClick: () => setSelectedRadio('Three'), variant: selectedRadio === 'Three' ? 'ACTIVE' : 'DEFAULT' }
                    ], isVertical: false })),
            React.createElement("div", null, "Vertical"),
            React.createElement(RadioGroup, { items: [
                    { name: 'One', description: 'Mini description', icon: undefined, onClick: () => setSelectedRadio('One'), variant: selectedRadio === 'One' ? 'ACTIVE' : 'DEFAULT' },
                    { name: 'Two', description: undefined, icon: undefined, onClick: () => setSelectedRadio('Two'), variant: selectedRadio === 'Two' ? 'ACTIVE' : 'DEFAULT' },
                    { name: 'Three', description: undefined, icon: undefined, onClick: () => setSelectedRadio('Three'), variant: selectedRadio === 'Three' ? 'ACTIVE' : 'DEFAULT' }
                ], isVertical: true }),
            React.createElement("div", null, "With icon"),
            React.createElement(RadioGroup, { items: [
                    { name: 'One', icon: React.createElement(Icon, { icon: faSquare }), onClick: () => setSelectedRadio('One'), variant: selectedRadio === 'One' ? 'ACTIVE' : 'DEFAULT' },
                    { name: 'Two', icon: React.createElement(Icon, { icon: faSquare }), onClick: () => setSelectedRadio('Two'), variant: selectedRadio === 'Two' ? 'ACTIVE' : 'DEFAULT' },
                    { name: 'Three', icon: React.createElement(Icon, { icon: faSquare }), onClick: () => setSelectedRadio('Three'), variant: selectedRadio === 'Three' ? 'ACTIVE' : 'DEFAULT' }
                ] })));
};
const componentProps = [
    { name: 'isVertical', description: 'Defaults to horizontal, will display each item vertically if true' },
    { name: 'items->name', description: 'The name of the radio item' },
    { name: 'items->info', description: 'Show an info icon next to the name, this should be an <Info /> component' },
    { name: 'items->description', description: 'The secondary text showing below the name if required' },
    { name: 'items->icon', description: 'Should we show an icon to the left of the primary text, should be <Icon /> component' },
    { name: 'items->onClick', description: 'What should happen when the radio is clicked' },
    { name: 'items->variant', description: 'ACTIVE | DEFAULT' },
    { name: 'items->rightContent', description: 'Should anything show in the right section of the radio item, just before the checkmark' }
];
const codeBlock = `<RadioGroup
  items={[
    { name: 'One', icon: <Icon icon={faSquare} />, onClick: () => setSelectedRadio('One'), variant: selectedRadio === 'One' ? 'ACTIVE' : 'DEFAULT' },
    { name: 'Two', icon: <Icon icon={faSquare} />, onClick: () => setSelectedRadio('Two'), variant: selectedRadio === 'Two' ? 'ACTIVE' : 'DEFAULT' },
    { name: 'Three', icon: <Icon icon={faSquare} />, onClick: () => setSelectedRadio('Three'), variant: selectedRadio === 'Three' ? 'ACTIVE' : 'DEFAULT' }
  ]}
/>`;
