import React from 'react';
import { EnquiryOverview } from '../sections/enquiry_overview';
import { HeatLossEstimate } from '../sections/heat_loss_estimate';
import { CostEstimate } from '../sections/cost_estimate';
export const InstallerAdminDashboard = ({ lead, setLead, estimate }) => {
    return (React.createElement("div", { className: "space-y-6" },
        React.createElement(EnquiryOverview, { lead: lead, estimate: estimate }),
        React.createElement(HeatLossEstimate, { lead: lead, setLead: setLead, estimate: estimate }),
        React.createElement(CostEstimate, { estimate: estimate, lead: lead })));
};
