import React from 'react';
import { Badge } from '../../../components/indicators_and_messaging/badge';
export const BUSGrantBadge = ({ isScottish, notHesGrantEligible, notGrantEligible, lead }) => {
    if (isScottish) {
        if (!lead.epc_scotland)
            return React.createElement(Badge, { color: 'YELLOW', text: "Cannot assess HES eligibility" });
        return React.createElement(Badge, { color: notHesGrantEligible ? 'RED' : 'GREEN', text: notHesGrantEligible ? 'Unlikely to be eligible for HES Grant' : 'May be eligible for HES Grant' });
    }
    return React.createElement(Badge, { color: notGrantEligible ? 'RED' : 'GREEN', text: notGrantEligible ? 'Not currently eligible for BUS Grant' : 'Eligible for BUS Grant' });
};
