import React, { useState } from 'react';
import { Section } from '../section';
import { SummaryTable } from '../../../../../components/content_display/summary_table';
import { Text } from '../../../../../components/content_display/text';
import { formatPrice } from '../../../../../code/format_price';
import { Heading } from '../../../../../components/content_display/heading';
import { Select } from '../../../../../components/inputs_and_selections/select';
import { useRouteLoaderData } from 'react-router-dom';
import { Button } from '../../../../../components/buttons/button';
import { deleteLeadLineItem, insertLeadLineItem } from '../../../../../code/models/lead_line_item';
import { faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../../../../../components/buttons/icon';
import { Alert } from '../../../../../components/indicators_and_messaging/alert';
import { Badge } from '../../../../../components/indicators_and_messaging/badge';
export const CostBreakdown = ({ lead, setLead, estimate }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const companyPublicInfo = useRouteLoaderData('company_root');
    const [selectedLineItemUUID, setSelectedLineItemUUID] = useState(undefined);
    const handleDeleteLineItem = async (leadLineItem) => {
        await deleteLeadLineItem(leadLineItem.uuid, companyPublicInfo.uuid);
        setLead({ ...lead, lead_line_items: lead.lead_line_items.filter(y => y.uuid !== leadLineItem.uuid) });
    };
    const leadLineRows = (_b = (_a = lead.lead_line_items) === null || _a === void 0 ? void 0 : _a.map(x => ({ key: x.name, value: React.createElement("div", { className: "flex items-center gap-2" },
            React.createElement(Text, { size: "SM" }, formatPrice(x.value)),
            React.createElement(Icon, { icon: faTimesCircle, onClick: () => handleDeleteLineItem(x) })) }))) !== null && _b !== void 0 ? _b : [];
    const costBreakdownRows = [
        { key: ((_c = estimate.heatPump) === null || _c === void 0 ? void 0 : _c.name) || React.createElement(Badge, { color: "RED", text: "No heat pump selected", icon: faExclamationTriangle }), value: formatPrice((_d = estimate.heatPump) === null || _d === void 0 ? void 0 : _d.price) },
        { key: ((_e = estimate.hotWaterCylinder) === null || _e === void 0 ? void 0 : _e.name) || React.createElement(Badge, { color: "RED", text: "No hot water cylinder selected", icon: faExclamationTriangle }), value: formatPrice((_f = estimate.hotWaterCylinder) === null || _f === void 0 ? void 0 : _f.price) },
        { key: 'Parts & fittings', value: formatPrice((_g = estimate.heatPump) === null || _g === void 0 ? void 0 : _g.parts) },
        { key: 'Labour', value: formatPrice(estimate.labour) },
        { key: 'Survey', value: formatPrice(estimate.survey) },
        ...leadLineRows,
        !estimate.isScottish && { key: 'Grant', value: formatPrice(estimate.grant) },
        { key: React.createElement(Text, { bold: true }, "Total"), value: React.createElement(Text, { bold: true }, formatPrice(estimate.totalPrice)) }
    ].filter(r => Boolean(r));
    return (React.createElement(React.Fragment, null,
        React.createElement(Section, { title: 'Cost breakdown' },
            React.createElement(SummaryTable, { rows: costBreakdownRows }),
            (estimate.isScottish && lead.scottish_rural) && React.createElement(Alert, { className: "mt-3", type: 'SUCCESS' }, "This property is likely to be eligible for the HES rural uplift."),
            React.createElement("div", null,
                React.createElement(Heading, { size: "md", className: 'mb-2' }, "Add additional cost item"),
                React.createElement("div", { className: 'flex items-center gap-4' },
                    React.createElement(Select, { dataCy: "cost_item_dropdown", setSelectedKey: setSelectedLineItemUUID, selectedKey: selectedLineItemUUID, options: ((_h = companyPublicInfo.company_line_items) !== null && _h !== void 0 ? _h : []).map(x => ({ key: x.uuid, value: `${x.name} - ${formatPrice(x.value, 2)}` })) }),
                    React.createElement(Button, { disabled: !selectedLineItemUUID, onClick: async () => {
                            var _a, _b;
                            const companyLineItem = (_a = companyPublicInfo.company_line_items) === null || _a === void 0 ? void 0 : _a.find(x => x.uuid === selectedLineItemUUID);
                            const newItem = { uuid: crypto.randomUUID(), name: companyLineItem.name, value: companyLineItem.value, lead_uuid: lead.uuid };
                            await insertLeadLineItem(newItem, companyPublicInfo.uuid);
                            setLead({
                                ...lead,
                                lead_line_items: [...(_b = lead.lead_line_items) !== null && _b !== void 0 ? _b : [], newItem]
                            });
                            setSelectedLineItemUUID(undefined);
                        } }, "Add"))))));
};
